import { ApiError } from "services/apis/types/ApiError";
import { OrderApi } from "services/apis/OrderApi";
import { OrderNumberActionType } from "./types/OrderNumberActionType";
import { OrderAutoActivationDto } from "services/apis/types/order/OrderAutoActivationDto";
import { CancelAutoActivationDto } from "services/apis/types/order/CancelAutoActivationDto";
import { HttpClient } from "services/httpClient/HttpClient";
import { ApiVersion } from "services/httpClient/ApiVersion";

export const OrderService = {
  executeAction: (
    orderId: string,
    tenant: string,
    action: OrderNumberActionType
  ): Promise<ApiError> => {
    const methods: Record<string, (orderId: string, tenant: string) => Promise<ApiError>> = {
      [OrderNumberActionType.Activate]: OrderApi.activate,
      [OrderNumberActionType.Cancel]: OrderApi.cancel,
      [OrderNumberActionType.RemoveFromConflict]: OrderApi.removeFromConflict,
      [OrderNumberActionType.Archive]: OrderApi.archive,
      [OrderNumberActionType.UndoCancel]: OrderApi.undoCancel,
      [OrderNumberActionType.Restore]: OrderApi.drRestore
    };

    return methods[action](orderId, tenant);
  },
  scheduleActivation: (scheduleActivationModels: OrderAutoActivationDto[]): Promise<OrderAutoActivationDto[]> => {
    return HttpClient.post<OrderAutoActivationDto[]>('port/update-orders', scheduleActivationModels, true, true,"",ApiVersion.V2);
  },
  cancelActivation: (cancelActivationModel: CancelAutoActivationDto[]): Promise<CancelAutoActivationDto[]> => {
    return HttpClient.post<CancelAutoActivationDto[]>('port/update-orders', cancelActivationModel, true, true,"",ApiVersion.V2);
  }
};
