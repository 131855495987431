import { PortProposalDto } from "./types/port/PortProposalDto";
import { HttpClient } from "services/httpClient/HttpClient";
import { ApiError } from "./types/ApiError";
import { NewOutportModel } from "./types/port/NewOutportModel";
import { ModifyModel } from "./types/port/ModifyModel";
import { PortOrderProposal } from "./types/port/PortOrderProposal";
import { ApiVersion } from "services/httpClient/ApiVersion";
import { PortInSuccessModel } from "./types/port/PortInSuccessModel";
import { PortInModel, WholeSalePortInModel } from "./types/port/PortInModel";
import queryString from "query-string";
import { ExportActiveTnsDto } from "./types/reports/ExportDto";
import { EnvironmentUtil } from "services/util/EnvironmentUtil";
import DisasterRecovery from "components/orders/portCreate/disasterRecovery/DisasterRecovery";

const isMixNetworkUrl = EnvironmentUtil.isMixNetwork;

export const PortApi = {
  getProposal: (portOrderProposal: PortOrderProposal): Promise<PortProposalDto> => {
    return HttpClient.post<PortProposalDto>(
      "port/proposal",
      portOrderProposal,
      true,
      true,
      "",
      ApiVersion.V2
    );
  },

  getMixProposal: (portOrderProposal: PortOrderProposal): Promise<PortProposalDto> => {
    return HttpClient.post<PortProposalDto>(
      "wholesale/proposal",
      portOrderProposal,
      true,
      true,
      "",
      ApiVersion.V3
    );
  },

  getDisasterRecoveryProposal: (portOrderProposal: PortOrderProposal): Promise<PortProposalDto> => {
    return HttpClient.post<PortProposalDto>(
      `disasterrecovery/proposal?isWholesale=${isMixNetworkUrl}`,
      portOrderProposal,
      true,
      true,
      "",
      ApiVersion.V3
    );
  },

  disconnect: (
    phoneNumbers: string[],
    disconnectDate: Date,
    releaseDate: Date
  ): Promise<ApiError> => {
    return HttpClient.post(
      `port/disconnect`,
      { phoneNumbers, disconnectDate, releaseDate },
      true,
      true
    );
  },

  portIn: (portIn: PortInModel): Promise<ApiError | PortInSuccessModel> => {
    return HttpClient.post<ApiError>("port/in", portIn, true, true, "", ApiVersion.V2);
  },

  wholeSalePortIn: (wholeSalePortIn: WholeSalePortInModel) : Promise<ApiError | PortInSuccessModel> => {
    return HttpClient.post<ApiError>("wholesale/port/in", wholeSalePortIn, true, true, "", ApiVersion.V3 )
  },

  DisasterRecoveryPort: (disasterRecoveryPort) : Promise<ApiError | PortInSuccessModel> => {
    return HttpClient.post<ApiError>(`disasterrecovery/disasterrecovery?isWholesale=${isMixNetworkUrl}`, disasterRecoveryPort, true, true, "", ApiVersion.V3 )
  },

  portOut: (newOutports: NewOutportModel[]): Promise<ApiError> => {
    return HttpClient.post<ApiError>("port/out", newOutports, true, true);
  },

  modify: (tnRange: string, modify: ModifyModel): Promise<ApiError> => {
    return HttpClient.put(`port/${tnRange}`, modify, true, true);
  },

  cancel: (phoneNumber: string): Promise<ApiError> => {
    return HttpClient.post<ApiError>(`port/${phoneNumber}/cancel`, {}, true, true);
  },

  removeFromConflict: (phoneNumber: string): Promise<ApiError> => {
    return HttpClient.post<ApiError>(`port/${phoneNumber}/conflict`, {}, true, true);
  },

  activate: (phoneNumber: string): Promise<ApiError> => {
    return HttpClient.post<ApiError>(`port/${phoneNumber}/activate`, {}, true, true);
  },

  undoCancel: (tnRange: string): Promise<ApiError> => {
    return HttpClient.post<ApiError>(`port/${tnRange}/undocancel`, undefined, true, true);
  },

  getpooledblocktnsbyreturnstospid: (from: Date, to: Date): Promise<ExportActiveTnsDto[]> => {
    let url = queryString.stringifyUrl({
      url: "port/getpooledblocktnsbyreturnstospid",
      query: {
        from: from.toISOString(),
        to: to.toISOString()
      }
    });

    return HttpClient.get<ExportActiveTnsDto[]>(url, true, true);
  }
};
