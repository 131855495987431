import React, { FormEvent, useState, useContext, useCallback, useEffect } from "react";
import { Modal, Form, Button } from "reactstrap";
import { FormattedMessage, useIntl } from "react-intl";
import CloseButton from "components/framework/modals/CloseButton";
import { useIsMounted } from "services/customHooks/useIsMounted";
import { ManageContext } from "../Manage";
import { ConfiguredParameter } from "services/apis/types/settings/ConfiguredParameter";
import { ServiceProviderSettingsApi } from "services/apis/ServiceProviderSettingsApi";
import { handleError, handleFieldErrors } from "services/util/ApiUtil";
import { UpdateSettingModel } from "services/apis/types/settings/UpdateSettingModel";
import ConfiguredParameterInput from "components/common/ConfiguredParameterInput";
import { ServiceProviderConfiguredParameterConfigurations } from "./ServiceProviderConfiguredParameterConfigurations";
import { ServiceProviderConfiguredParameter } from "../types/ServiceProviderConfiguredParamter";
import { ApiError } from "services/apis/types/ApiError";
import { showInfoNotification } from "components/framework/notification/NotificationUtil";
import { getTranslation } from "translations/TranslationService";
import { LoadingIndicator } from "components/framework/loadingIndicator/LoadingIndicator";
import { AppContext } from "services/appContext/AppContext";

type Props = {
  spId: string;
  closeModal: () => void;
};

export default function ModifyServiceProviderParametersModal(props: Props) {
  const [parameters, setParameters] = useState<ConfiguredParameter[]>([]);
  const [parametersLoaded, setParametersLoaded] = useState(false);
  const [showLoadingIndicator, setShowLoadingIndicator] = useState(false);
  const intl = useIntl();
  const isMounted = useIsMounted();
  const manageContext = useContext(ManageContext);
  const { appContext } = useContext(AppContext);
  const theme = appContext.theme;

  const [updateSettingsModels, setUpdateSettingsModels] = useState<UpdateSettingModel[]>([]);

  useEffect(() => {
    ServiceProviderSettingsApi.get(props.spId)
      .then((result) => {
        setParameters(result);
        setUpdateSettingsModels(
          result.map((x) => ({
            parameterId: x.parameterId,
            processName: x.processName,
            value: x.value
          }))
        );
        setParametersLoaded(true);
      })
      .catch((apiError) => handleError(apiError));
  }, [props.spId]);

  const closeModal = useCallback(props.closeModal, [props.closeModal]);

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    const changedModels = updateSettingsModels.filter(
      (x) => x.value !== parameters.find((p) => p.parameterId === x.parameterId)?.value
    );
    setShowLoadingIndicator(true);
    ServiceProviderSettingsApi.update(props.spId, changedModels)
      .then(() => {
        showInfoNotification(
          getTranslation(intl, "manage.serviceProvider.modifyParameters.successNotificationMessage")
        );
        manageContext.serviceProviderCallback();
        props.closeModal();
      })
      .catch((apiError: ApiError) => handleFieldErrors(apiError))
      .finally(() => {
        if (isMounted.current) {
          setShowLoadingIndicator(false);
        }
      });
  };

  return (
    <Modal className="modal-dialog-centered modal-sm" isOpen={true}>
      <div className="modal-header">
        <h5 className="modal-title">
          <FormattedMessage
            id="manage.serviceProvider.modifyParameters.modifyParametersModalTitle"
            values={{ spid: props.spId }}
          />
        </h5>
        <CloseButton close={closeModal} />
      </div>
      <Form onSubmit={(e) => handleSubmit(e)}>
        <div className="modal-body">
          {!parametersLoaded && <LoadingIndicator />}
          {parameters
            .filter((x) =>
              Object.keys(ServiceProviderConfiguredParameterConfigurations).includes(x.parameterId)
            )
            .map((parameterInfo) => {
              const inputConfiguration =
                ServiceProviderConfiguredParameterConfigurations[
                  parameterInfo.parameterId as ServiceProviderConfiguredParameter
                ];

              return (
                <ConfiguredParameterInput
                  key={parameterInfo.parameterId}
                  parameterInfo={parameterInfo}
                  inputConfiguration={inputConfiguration}
                  value={
                    updateSettingsModels.find((x) => x.parameterId === parameterInfo.parameterId)
                      ?.value
                  }
                  handleInputChange={(value) => {
                    setUpdateSettingsModels((prev) => {
                      const updateModel = prev.find(
                        (x) => x.parameterId === parameterInfo.parameterId
                      );
                      if (updateModel) {
                        updateModel.value = value;
                      }
                      return [...prev];
                    });
                  }}
                />
              );
            })}
        </div>
        <div className="modal-footer">
          <Button color="link" type="button" className={`${theme === "light" ? "lblue-color" : ""}`} onClick={() => props.closeModal()}>
            <FormattedMessage id="manage.serviceProvider.modifyParameters.cancelButton" />
          </Button>
          <Button color="primary" type="submit" className={`ml-auto ${theme === "light" ? "bg-lblue no-border" : ""}`} disabled={showLoadingIndicator}>
            {showLoadingIndicator && <i className="fas fa-spinner fa-spin mr-2" />}
            <FormattedMessage id="manage.serviceProvider.modifyParameters.submitButton" />
          </Button>
        </div>
      </Form>
    </Modal>
  );
}
