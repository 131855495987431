import React, { useContext } from "react";
import { CSVLink } from "react-csv";
import { Button, ButtonGroup } from "reactstrap";
import { FormattedMessage, useIntl } from "react-intl";
import ReactToPrint from "react-to-print";
import copy from "copy-to-clipboard";
import { showInfoNotification } from "components/framework/notification/NotificationUtil";
import { utils, writeFile } from "xlsx";
import { AppContext } from "services/appContext/AppContext";

export default function ExportButtons(props) {
  const intl = useIntl();
  const table = dataToTable(props.data, props.columns, props.hasExpandedData);
  const { appContext } = useContext(AppContext);
  const theme = appContext.theme;

  return (
    <ButtonGroup>
      <CSVLink
        data={table}
        target="_blank"
        className={`btn btn-default btn-sm text-white rounded mr-2 border-0 ${theme === "light" ? "bg-lblue" : "bg-primary"}`}
        filename={"result.csv"}>
        <FormattedMessage id="table.exportToCsv" />
      </CSVLink>
      <Button color="default" type="button" size="sm" className={`text-white rounded mr-2 border-0 ${theme === "light" ? "bg-lblue" : "bg-primary"}`} onClick={() => exportToXlsxHandler(table)}>
        <FormattedMessage id="table.exportToXlsx" />
      </Button>
      {props.showCopy &&
        <Button color="default" type="button" size="sm" className={`text-white rounded mr-2 border-0 ${theme === "light" ? "bg-lblue" : "bg-primary"}`} onClick={() => copyHandler(table, intl)}>
          <FormattedMessage id="table.copy" />
        </Button>}
      {props.showPrint &&
        <ReactToPrint
          onBeforeGetContent={props.onBeforePrint}
          onAfterPrint={props.onAfterPrint}
          trigger={() => (
            <Button color="default" type="button" size="sm" className={`text-white rounded border-0 ${theme === "light" ? "bg-lblue" : "bg-primary"}`} >
              <FormattedMessage id="table.print" />
            </Button>
          )}
          content={() => props.printRef.current}
        />}
    </ButtonGroup>
  );
}

function exportToXlsxHandler(matrix) {
  let workbook = { SheetNames: ["Sheet1"], Sheets: { Sheet1: utils.aoa_to_sheet(matrix) } };

  writeFile(workbook, "result.xlsx");
}

function copyHandler(matrix, intl) {
  let string = matrix.map((x) => x.join(", ")).join("\n");

  copy(string);

  showInfoNotification(intl.formatMessage({ id: "table.copyNotification" }));
}

function dataToTable(data, columns, hasExpandedData) {
  let table = [columns.map((column) => column.Header)];

  const rowsData = []
  if (data) {
    data.forEach((row) => {
      rowsData.push(columns.map((column) => (row[column.accessor] ? `${row[column.accessor]}` : "")))
      if (hasExpandedData && row.subRows && row.subRows.length > 0) {
        (row.subRows.forEach(row => rowsData.push(columns.map((column) => (row[column.accessor] ? `${row[column.accessor]}` : "")))))
      }
    })
  }

  rowsData.forEach((x) => table.push(x));
  return table;
}
