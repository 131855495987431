import React, { useState, FormEvent, useRef, useEffect, useContext } from "react";
import { CardBody, Card, Col, Row, CardHeader, Button, Form, CardFooter } from "reactstrap";
import { FormattedMessage, useIntl } from "react-intl";
import { ChangePassword, ChangePasswordDefault } from "components/myProfile/types/ChangePassword";
import { useErrors } from "services/customHooks/useErrors";
import { nameOf } from "services/util/ObjectUtil";
import { Errors, AddError, HasErrors } from "components/framework/errorHandling/ErrorUtil";
import { showInfoNotification } from "components/framework/notification/NotificationUtil";
import { getFieldErrors, handleError } from "services/util/ApiUtil";
import { IdentityApi } from "services/apis/IdentityApi";
import PasswordFormInput from "components/framework/forms/PasswordFormInput";
import { AppContext } from "services/appContext/AppContext";

export default function ChangePasswordCard() {
  const { setErrors, getErrorHandler } = useErrors();
  const [showLoadingIndicator, setShowLoadingIndicator] = useState(false);
  const [changePassword, setChangePassword] = useState<ChangePassword>(ChangePasswordDefault);
  const { appContext } = useContext(AppContext);
  const theme = appContext.theme;
  const isMounted = useRef(true);
  const intl = useIntl();

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    setShowLoadingIndicator(true);

    const errors = validateModel(changePassword);

    if (HasErrors(errors)) {
      setErrors(errors);
      if (isMounted.current) {
        setShowLoadingIndicator(false);
      }
    } else {
      IdentityApi.changePassword({
        currentPassword: changePassword.currentPassword,
        newPassword: changePassword.newPassword
      })
        .then(() => {
          showInfoNotification(intl.formatMessage({ id: "myProfile.changePassword.successMessage" }));
        })
        .catch(error => {
          handleError(error);
          if (isMounted.current) {
            const errorsResult = getFieldErrors(error.fieldErrors);
            setErrors(errorsResult);
          }
        })
        .finally(() => {
          if (isMounted.current) {
            setShowLoadingIndicator(false);
          }
        });
    }
  };

  return (
    <Card>
      <CardHeader>
        <Row className="align-items-center">
          <Col xs="12">
            <h3 className="mb-0">
              <FormattedMessage id="myProfile.changePassword.changePassword" />
            </h3>
          </Col>
        </Row>
      </CardHeader>
      <Form onSubmit={e => handleSubmit(e)}>
        <CardBody>
          <Row>
            <PasswordFormInput
              className="col-lg-12"
              labelTranslationId="myProfile.changePassword.currentPassword"
              value={changePassword.currentPassword}
              handleInputChange={(value: string) => setChangePassword({ ...changePassword, currentPassword: value })}
              errorHandler={getErrorHandler(nameOf<ChangePassword>("currentPassword"))}
            />
          </Row>
          <Row>
            <PasswordFormInput
              className="col-lg-12"
              labelTranslationId="myProfile.changePassword.newPassword"
              value={changePassword.newPassword}
              handleInputChange={(value: string) => setChangePassword({ ...changePassword, newPassword: value })}
              errorHandler={getErrorHandler(nameOf<ChangePassword>("newPassword"))}
            />
          </Row>
          <Row>
            <PasswordFormInput
              className="col-lg-12"
              labelTranslationId="myProfile.changePassword.confirmNewPassword"
              value={changePassword.confirmPassword}
              handleInputChange={(value: string) => setChangePassword({ ...changePassword, confirmPassword: value })}
              errorHandler={getErrorHandler(nameOf<ChangePassword>("confirmPassword"))}
            />
          </Row>
        </CardBody>
        <CardFooter className="text-right">
          <Button color="primary" type="submit" className={`ml-auto ${theme === "light" ? "bg-lblue no-border" : ""}`} disabled={showLoadingIndicator}>
            {showLoadingIndicator && <i className="fas fa-spinner fa-spin mr-2" />}
            <FormattedMessage id="myProfile.changePassword.confirm" />
          </Button>
        </CardFooter>
      </Form>
    </Card>
  );
}

const validateModel = (changePassword: ChangePassword) => {
  const errors: Errors = {};

  if (changePassword.currentPassword.length === 0) {
    AddError(errors, nameOf<ChangePassword>("currentPassword"), "myProfile.changePassword.emptyCurrentPassword");
  }

  if (changePassword.newPassword.length === 0) {
    AddError(errors, nameOf<ChangePassword>("newPassword"), "myProfile.changePassword.emptyNewPassword");
  }

  if (changePassword.confirmPassword.length === 0) {
    AddError(errors, nameOf<ChangePassword>("confirmPassword"), "myProfile.changePassword.emptyNewPassword");
  }

  if (
    changePassword.newPassword &&
    changePassword.confirmPassword &&
    changePassword.newPassword !== changePassword.confirmPassword
  ) {
    AddError(errors, nameOf<ChangePassword>("confirmPassword"), "myProfile.changePassword.passwordsDoNotMatch");
  } else if (
    changePassword.currentPassword &&
    changePassword.newPassword &&
    changePassword.currentPassword === changePassword.newPassword
  ) {
    AddError(errors, nameOf<ChangePassword>("newPassword"), "myProfile.changePassword.differentPasswords");
  }

  return errors;
};
