import React, { useContext } from "react";
import DropdownInput from "../forms/DropdownInput";
import { TppStatus } from "services/util/TppFiltersUtil";
import { AppContext } from "services/appContext/AppContext";

type Props = {
    column: {
        filterValue: string;
        preFilteredRows: Array<any>;
        setFilter: (value: string | undefined) => void;
        id: string;
    },
};

export default function TppStatusColumnFilter(props: Props) {
    const { appContext } = useContext(AppContext);
    const theme = appContext.theme;
    const options = React.useMemo(() => TppStatus, []);

    return (
        <DropdownInput
            sm
            onChange={(e) => {
                props.column.setFilter(e.target.value || undefined);
            }}
            value={props.column.filterValue}
            options={options}
            className={`text-center filter-align ${theme === "light" ? "table-text-dark" : ""}`}
        />
    );
}