import CloseButton from "components/framework/modals/CloseButton";
import React, { useContext, useEffect, useState } from "react";
import { CSVLink } from "react-csv";
import { FormattedMessage } from "react-intl";
import { Button, Form, Modal } from "reactstrap";
import bsCustomFileInput from 'bs-custom-file-input';
import Papa from "papaparse";
import { DnoApi } from "services/apis/DnoApi";
import { showErrorNotificationNonAuthenticated, showInfoNotification } from "components/framework/notification/NotificationUtil";
import { handleError } from "services/util/ApiUtil";
import { useIntl } from "react-intl";
import { AppContext } from "services/appContext/AppContext";
type Props = {
  closeModal: () => void;
  loadTable: () => void;
}
export default function InventoryModal(props: Props) {
  const [showLoadingIndicator, setShowLoadingIndicator] = useState(false);
  const [selectedFile, setSelectedFile] = useState(String);
  const { appContext } = useContext(AppContext);
  const theme = appContext.theme;
  const intl = useIntl();
  const handleSubmit = (event) => {
    const trueNumbers: string[] = [];
    const falseNumbers: string[] = [];
    Papa.parse(selectedFile, {
      header: true,
      skipEmptyLines: true,
      complete: function (results) {
        const fileData = results.data;
        for (var data of fileData) {
          (data.Dno === 'Y') ? trueNumbers.push(data.Number) : falseNumbers.push(data.Number);
        }
        showInfoNotification(
          intl.formatMessage({ id: "somos.number.bulkupdate.dno" })
        );
        (trueNumbers.length !== 0) && setBulkDnoFlag(trueNumbers, true);
        (falseNumbers.length !== 0) && setBulkDnoFlag(falseNumbers, false);
        props.closeModal();
      }
    })
  };
  function setBulkDnoFlag(trueNumbers: string[], dnoFlag: boolean) {
    setShowLoadingIndicator(true);
    DnoApi.bulkDnoFlag(trueNumbers, dnoFlag)
      .then((response) => {
        let errorCount = response[0].errorCount;
        if (errorCount === 0) {
          props.loadTable();
          showInfoNotification(
            intl.formatMessage({ id: response[0].operation.dno === true ? "somos.number.bulkupdate.update.true.successfully" : "somos.number.bulkupdate.update.false.successfully" })
          );
        } else {
          let errorList = response[0].entries.filter((x: any) => x.status === "ERROR");
          let errorListNumbers = errorList.map((x: any) => x.number).toString();
          let errorDNOStatus = errorList[0].operation.dno.toString().toUpperCase();
          showErrorNotificationNonAuthenticated(
            intl.formatMessage({ id: "somos.number.bulkupdate.failed" }, { numbers: errorListNumbers, status: errorDNOStatus })
          );
        }
      })
      .catch((error) => {
        handleError(error);
      })
      .finally(() => {
        setShowLoadingIndicator(false);
      });
  }
  const onFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  }
  const headers = [
    { label: "Number", key: "number" },
    { label: "Dno", key: "dnoFlag" },
  ];
  const data = [
    { number: "8775940651", dnoFlag: "Y" },
    { number: "8775940652", dnoFlag: "N" }
  ];
  useEffect(() => {
    bsCustomFileInput.init()
  })
  return (
    <Modal className="modal-dialog-centered modal-sm" isOpen={true} zIndex={2147483648}>
      <div className="modal-header">
        <h5 className="modal-title">
          <FormattedMessage id={"dno.create.createModalTitle"} />
        </h5>
        <CloseButton close={props.closeModal} />
      </div>
      <Form onSubmit={(e) => handleSubmit(e)}>
        <div className="modal-body">
          <div className="custom-file">
            <label htmlFor="inputGroupFile02" className="custom-file-label"> Choose file</label>
            <input id="inputGroupFile02" type="file" onChange={onFileChange} className="custom-file-input" />
          </div>
          <CSVLink
            data={data}
            headers={headers}
            target="_blank"
            className="a"
            filename={"sample_DNO.csv"}>
            <FormattedMessage id="dno.create.sample" />
          </CSVLink>
        </div>
        <div className="modal-footer justify-content-end">
          <Button color="primary" className={`btn ${theme === "light" ? "btn-outline-lblue" : "btn-outline-default"}`} type="button" onClick={() => props.closeModal()}>
            <FormattedMessage id="dno.create.cancelButton" />
          </Button>
          <Button color="primary" className={`${theme === "light" ? "bg-lblue no-border" : ""}`} type="submit" disabled={showLoadingIndicator}>
            {showLoadingIndicator && <i className="fas fa-spinner fa-spin mr-2" />}
            <FormattedMessage id="dno.create.submitButton" />
          </Button>
        </div>
      </Form>
    </Modal>
  )
}
