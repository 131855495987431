import React, { useMemo } from "react";
import { useIntl, IntlShape } from "react-intl";
import ReactTable from "components/framework/table/ReactTable";
import { getValueOrEmpty } from "services/util/StringUtil";
import NetworkProfileActionsCell from "./NetworkProfileActionsCell";
import { NetworkProfileDto } from "services/apis/types/networkProfile/NetworkProfileDto";

type Props = {
  networkProfiles: NetworkProfileDto[];
};

export default function NetworkProfilesCard(props: Props) {
  const intl = useIntl();
  const columns = useMemo(() => getColumns(intl), [intl]);

  return (
    <ReactTable
      title={intl.formatMessage({ id: "network.networkProfile.table.title" })}
      data={props.networkProfiles}
      columns={columns}
    />
  );
}

const getColumns = (intl: IntlShape) => [
  {
    Header: intl.formatMessage({ id: "network.networkProfile.table.spId" }),
    accessor: "spId",
    Cell: (cell: any) => getValueOrEmpty(cell.cell.value)
  },
  {
    Header: intl.formatMessage({ id: "network.networkProfile.table.lrn" }),
    accessor: "lrn",
    Cell: (cell: any) => getValueOrEmpty(cell.cell.value)
  },
  {
    Header: intl.formatMessage({ id: "network.networkProfile.table.lata" }),
    accessor: "lata",
    Cell: (cell: any) => getValueOrEmpty(cell.cell.value)
  },
  {
    Header: intl.formatMessage({ id: "network.networkProfile.table.description" }),
    accessor: "description"
  },
  {
    Header: intl.formatMessage({ id: "network.networkProfile.table.carrier" }),
    accessor: "carrier",
    Cell: (cell: any) => getValueOrEmpty(cell.cell.value)
  },
  {
    Header: intl.formatMessage({ id: "network.networkProfile.table.type" }),
    accessor: "isPrimary",
    Cell: (cell: any) => getValueOrEmpty(cell.cell.value === null ? "" : cell.cell.value === true ? "Primary" : "Secondary" )
  },
  {
    Header: intl.formatMessage({ id: "network.networkProfile.table.actions" }),
    accessor: "actions",
    Cell: (cell: any) => <NetworkProfileActionsCell networkProfile={cell.cell.row.original} />
  }
];
