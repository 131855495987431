import React, { useMemo, useEffect, useState, useContext } from "react";
import { NumberExplorerSearchResult } from "services/apis/types/number/NumberExplorerSearchResult";
import { useIntl, IntlShape } from "react-intl";
import PhoneNumberCell from "./PhoneNumberCell";
import { NumberApi } from "services/apis/NumberApi";
import { handleError } from "services/util/ApiUtil";
import SpIdCell from "../../common/SpIdCell";
import { getValueOrEmpty } from "services/util/StringUtil";
import { LoadingIndicator } from "components/framework/loadingIndicator/LoadingIndicator";
import { NumberDetailsCell } from "../numberDetailsModal/NumberDetailsCell";
import ReactTable from "components/framework/table/ReactTable";
import FormatDateTime from "components/framework/date/FormatDateTime";
import { getFormattedUtcDate } from "services/util/DateUtil";
import { AppContext } from "services/appContext/AppContext";

type Props = {
  telephoneNumber: string;
};

export default function NumberTable(props: Props) {
  const [showLoadingIndicator, setShowLoadingIndicator] = useState(false);
  const [dataTableData, setDataTableData] = useState<Array<NumberExplorerSearchResult>>();
  const { appContext } = useContext(AppContext);
  const theme = appContext.theme;
  const intl = useIntl();
  const columns = useMemo(() => getColumns(intl, theme), [intl, theme]);

  useEffect(() => {
    if (props.telephoneNumber) {
      let isMounted = true;
      setShowLoadingIndicator(true);

      NumberApi.search(props.telephoneNumber)
        .then((result) => {
          if (isMounted) {
            setDataTableData(result);
          }
        })
        .catch((error) => handleError(error))
        .finally(() => {
          if (isMounted) {
            setShowLoadingIndicator(false);
          }
        });

      return () => {
        isMounted = false;
      };
    }
  }, [props.telephoneNumber]);

  return (
    <>
      {showLoadingIndicator ? (
        <LoadingIndicator white />
      ) : (
        <>
          {dataTableData && (
            <ReactTable
              title={intl.formatMessage({ id: "explore.table.title" })}
              data={dataTableData.map((x) => {
                return { ...x, activityDate: getFormattedUtcDate(x.activityDate) };
              })}
              columns={columns}
              showExport={true}
            />
          )}
        </>
      )}
    </>
  );
}

const getColumns = (intl: IntlShape, theme: string) => [
  {
    Header: intl.formatMessage({ id: "explore.table.telephoneNumber" }),
    accessor: "phoneNumber",
    Cell: (cell: any) => (
      <PhoneNumberCell
        phoneNumber={cell.cell.value}
        phoneNumberType={cell.cell.row.original.type}
      />
    )
  },
  {
    Header: intl.formatMessage({ id: "explore.table.spid" }),
    accessor: "spId",
    Cell: (cell: any) => <SpIdCell spId={cell.cell.row.original.spId} labelColor={`${theme === "light" ? "bg-lblue" : ""}`} />
  },
  {
    Header: intl.formatMessage({ id: "explore.table.status" }),
    accessor: "status",
    Cell: (cell: any) => getValueOrEmpty(cell.cell.value)
  },
  {
    Header: intl.formatMessage({ id: "explore.table.serviceProvider" }),
    accessor: "providerName",
    Cell: (cell: any) => getValueOrEmpty(cell.cell.value)
  },
  {
    Header: intl.formatMessage({ id: "explore.table.ocn" }),
    accessor: "ocn",
    Cell: (cell: any) => getValueOrEmpty(cell.cell.value)
  },
  {
    Header: intl.formatMessage({ id: "explore.table.region" }),
    accessor: "npacRegion",
    Cell: (cell: any) => getValueOrEmpty(cell.cell.value)
  },
  {
    Header: intl.formatMessage({ id: "explore.table.lata" }),
    accessor: "lata",
    Cell: (cell: any) => getValueOrEmpty(cell.cell.value)
  },
  {
    Header: intl.formatMessage({ id: "explore.table.lataName" }),
    accessor: "lataName",
    Cell: (cell: any) => getValueOrEmpty(cell.cell.value)
  },
  {
    Header: intl.formatMessage({ id: "explore.table.state" }),
    accessor: "state",
    Cell: (cell: any) => getValueOrEmpty(cell.cell.value)
  },
  {
    Header: intl.formatMessage({ id: "explore.table.rateCenter" }),
    accessor: "rateCenter",
    Cell: (cell: any) => getValueOrEmpty(cell.cell.value)
  },
  {
    Header: intl.formatMessage({ id: "explore.table.lrn" }),
    accessor: "lrn",
    Cell: (cell: any) => getValueOrEmpty(cell.cell.value)
  },
  {
    Header: intl.formatMessage({ id: "explore.table.activityDate" }),
    accessor: "activityDate",
    Cell: (cell: any) => <FormatDateTime utcDate={cell.cell.value} />
  },
  {
    Header: intl.formatMessage({ id: "explore.table.actions" }),
    accessor: "actions",
    Cell: (cell: any) => (
      <NumberDetailsCell
        phoneNumber={cell.cell.row.original.phoneNumber}
        phoneNumberType={cell.cell.row.original.type}
      />
    )
  }
];
