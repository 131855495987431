import React, { useState, FormEvent, useCallback, useContext, useEffect } from "react";
import { Modal, Form, FormGroup, Button, Row } from "reactstrap";
import { FormattedMessage, useIntl } from "react-intl";
import { showInfoNotification } from "components/framework/notification/NotificationUtil";
import { handleError, getFieldErrors } from "services/util/ApiUtil";
import { useErrors } from "services/customHooks/useErrors";
import TextFormInput from "components/framework/forms/TextFormInput";
import { nameOf } from "services/util/ObjectUtil";
import DropdownFormInput from "components/framework/forms/DropdownFormInput";
import { useIsMounted } from "services/customHooks/useIsMounted";
import { ManageContext } from "../Manage";
import { SpInfo } from "services/apis/types/serviceProvider/SpInfo";
import { SpIdConnectionType } from "components/manage/types/SpIdConnectionType";
import { CompanyInfo } from "services/apis/types/company/CompanyInfo";
import { DefaultServiceProvider, ServiceProvider } from "components/manage/types/ServiceProvider";
import { CompanyApi } from "services/apis/CompanyApi";
import CloseButton from "components/framework/modals/CloseButton";
import CheckboxFormInput from "components/framework/forms/CheckboxFormInput";
import { getTranslation } from "translations/TranslationService";
import { EnvironmentUtil } from "services/util/EnvironmentUtil";
import { AppContext } from "services/appContext/AppContext";
 
const isMixNetworkUrl = EnvironmentUtil.isMixNetwork;

type Props = {
  closeModal: () => void;
  availableCompanies: CompanyInfo[];
};

export default function ServiceProviderCreateModal(props: Props) {
  const { setErrors, getErrorHandler } = useErrors();
  const [serviceProvider, setServiceProvider] = useState<ServiceProvider>(DefaultServiceProvider);
  const [showLoadingIndicator, setShowLoadingIndicator] = useState(false);
  const intl = useIntl();
  const isMounted = useIsMounted();
  const manageContext = useContext(ManageContext);
  const { appContext } = useContext(AppContext);
  const theme = appContext.theme;

  const closeModal = useCallback(props.closeModal, []);

  useEffect(() => {
    if (props.availableCompanies.length > 0) {
      setServiceProvider((prevState) => {
        return { ...prevState, companyId: props.availableCompanies[0].id };
      });
    }
  }, [props.availableCompanies]);

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    setShowLoadingIndicator(true);

    CompanyApi.addSpId(serviceProvider)
      .then(() => {
        showInfoNotification(
          intl.formatMessage({ id: "manage.serviceProvider.create.successNotificationMessage" })
        );
        manageContext.serviceProviderCallback();
        closeModal();
      })
      .catch((error) => {
        handleError(error);
        if (isMounted.current) {
          const errorsResult = getFieldErrors(error.fieldErrors);
          setErrors(errorsResult);
        }
      })
      .finally(() => {
        if (isMounted.current) {
          setShowLoadingIndicator(false);
        }
      });
  };

  return (
    <Modal className="modal-dialog-centered modal-sm" isOpen={true}>
      <div className="modal-header">
        <h5 className="modal-title">
          <FormattedMessage id="manage.serviceProvider.create.createModalTitle" />
        </h5>
        <CloseButton close={props.closeModal} />
      </div>
      <Form onSubmit={(e) => handleSubmit(e)}>
        <div className="modal-body">
          <Row>
            <TextFormInput
              formGroupClassName="col-lg-12"
              labelTranslationId="manage.serviceProvider.create.spId"
              value={serviceProvider.spId}
              handleInputChange={(value: string) =>
                setServiceProvider({ ...serviceProvider, spId: value })
              }
              errorHandler={getErrorHandler(nameOf<SpInfo>("spId"))}
            />
          </Row>
          <Row>
            <DropdownFormInput
              className="col-lg-12"
              required
              labelTranslationId="manage.serviceProvider.create.companyId"
              value={serviceProvider.companyId}
              handleInputChange={(value: string) =>
                setServiceProvider({ ...serviceProvider, companyId: value })
              }
              errorHandler={getErrorHandler(nameOf<SpInfo>("companyId"))}
              options={props.availableCompanies.map((x) => {
                return { key: x.name, value: x.id };
              })}
            />
          </Row>
          <Row>
            <DropdownFormInput
              className="col-lg-12"
              required
              labelTranslationId="manage.serviceProvider.create.type"
              value={serviceProvider.type}
              handleInputChange={(value: string) =>
                setServiceProvider({ ...serviceProvider, type: value })
              }
              errorHandler={getErrorHandler(nameOf<SpInfo>("type"))}
              options={Object.keys(SpIdConnectionType).map((x) => {
                return { key: x, value: SpIdConnectionType[x].toString() };
              })}
            />
          </Row>
          <Row>
          <FormGroup className="mb-2 col-lg-12 mt-4">
            <CheckboxFormInput
              className="col-lg-12"
              label={getTranslation(intl, "manage.serviceProvider.create.mediumTimer")}
              value={"MediumTimer"}
              checked={serviceProvider.mediumTimer}
              handleInputChange={(value: boolean) => {
                setServiceProvider({...serviceProvider, mediumTimer: value})
              }}
              />
            </FormGroup>
          </Row>
        </div>
        <div className="modal-footer">
          <Button color="link" type="button" className={`${theme === "light" ? "lblue-color" : ""}`} onClick={() => props.closeModal()}>
            <FormattedMessage id="manage.serviceProvider.create.cancelButton" />
          </Button>
          <Button color="primary" type="submit" className={`ml-auto ${theme === "light" ? "bg-lblue no-border" : ""}`} disabled={showLoadingIndicator}>
            {showLoadingIndicator && <i className="fas fa-spinner fa-spin mr-2" />}
            <FormattedMessage id="manage.serviceProvider.create.submitButton" />
          </Button>
        </div>
      </Form>
    </Modal>
  );
}
