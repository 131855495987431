import { HttpClient } from "../httpClient/HttpClient";
import { ApiError } from "./types/ApiError";
import { NetworkProfileDto } from "./types/networkProfile/NetworkProfileDto";
import { ApiVersion } from "services/httpClient/ApiVersion";

export const NetworkProfileApi = {
  // TODO change endpoint name when API is changed
  list: (): Promise<NetworkProfileDto[]> => {
    return HttpClient.get<NetworkProfileDto[]>("networkprofile/", true, true, '', ApiVersion.V2);
  },

  dualCarrierList: (isWholeSale: boolean): Promise<NetworkProfileDto[]> => {
    return HttpClient.get<NetworkProfileDto[]>(`dualcarrier?isWholesale=${isWholeSale}`, true, true, '', ApiVersion.V3);
  },

  add: (networkProfile: NetworkProfileDto): Promise<ApiError> => {
    return HttpClient.post<ApiError>("networkprofile/", networkProfile, true, true, '', ApiVersion.V2);
  },

  dualCarrierAdd: (networkProfile: NetworkProfileDto): Promise<ApiError> => {
    return HttpClient.post<ApiError>("dualcarrier/", networkProfile, true, true, '', ApiVersion.V3);
  },

  modify: (networkProfile: NetworkProfileDto): Promise<ApiError> => {
    return HttpClient.put<ApiError>(`networkprofile/${networkProfile.profileId}`, networkProfile, true, true, '', ApiVersion.V2);
  },

  dualCarrierModify: (networkProfile: NetworkProfileDto): Promise<ApiError> => {
    return HttpClient.put<ApiError>(`dualcarrier/${networkProfile.profileId}`, networkProfile, true, true, '', ApiVersion.V3);
  },

  delete: (networkProfileId: string): Promise<ApiError> => {
    return HttpClient.delete<ApiError>("networkprofile/" + networkProfileId, {}, true, true, '', ApiVersion.V2);
  },

  dualCarrierDelete: (networkProfileId: string, lata: string, lrn: string): Promise<ApiError> => {
    return HttpClient.delete<ApiError>(`dualcarrier/${networkProfileId}?lrn=${lrn}&lata=${lata}`, {}, true, true, '', ApiVersion.V3);
  }
};
