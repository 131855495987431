import React, { useState, FormEvent, useCallback, useContext } from "react";
import { Modal, Form, Button } from "reactstrap";
import { FormattedMessage, useIntl } from "react-intl";
import { isNumber } from "services/util/StringUtil";
import { LrnApi } from "services/apis/LrnApi";
import { Errors, AddError, HasErrors } from "components/framework/errorHandling/ErrorUtil";
import { NpacRegion, npacRegionDropdownOptions } from "services/apis/types/number/NpacRegion";
import { LrnModel, DefaultLrnModel } from "components/networkExplorer/types/LrnModel";
import { useErrors } from "services/customHooks/useErrors";
import DropdownFormInput from "components/framework/forms/DropdownFormInput";
import { nameOf } from "services/util/ObjectUtil";
import CloseButton from "components/framework/modals/CloseButton";
import { useIsMounted } from "services/customHooks/useIsMounted";
import { showInfoNotification } from "components/framework/notification/NotificationUtil";
import { handleError, getFieldErrors } from "services/util/ApiUtil";
import { ApiError } from "services/apis/types/ApiError";
import TextFormInput from "components/framework/forms/TextFormInput";
import { AppContext } from "services/appContext/AppContext";

type Props = {
  closeModal: () => void;
};

export default function CreateLrnModal(props: Props) {
  const { setErrors, getErrorHandler } = useErrors();
  const [lrnModel, setLrnModel] = useState<LrnModel>(DefaultLrnModel);
  const [showLoadingIndicator, setShowLoadingIndicator] = useState(false);
  const intl = useIntl();
  const isMounted = useIsMounted();
  const { appContext } = useContext(AppContext);
  const theme = appContext.theme;

  const closeModal = useCallback(props.closeModal, []);

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    setShowLoadingIndicator(true);

    const lrn = lrnModel.lrn.trim();
    const errors = validateLrn(lrn, lrnModel.npacRegion);

    if (HasErrors(errors)) {
      setErrors(errors);
      if (isMounted.current) {
        setShowLoadingIndicator(false);
      }
    } else {
      LrnApi.create(lrn, lrnModel.npacRegion)
        .then(() => {
          showInfoNotification(
            intl.formatMessage({ id: "network.lrn.create.successNotificationMessage" })
          );
          closeModal();
        })
        .catch((error: ApiError) => {
          handleError(error);
          if (isMounted.current) {
            const errorsResult = getFieldErrors(error.fieldErrors);
            setErrors(errorsResult);
          }
        })
        .finally(() => {
          if (isMounted.current) {
            setShowLoadingIndicator(false);
          }
        });
    }
  };

  return (
    <Modal className="modal-dialog-centered modal-sm" isOpen={true}>
      <div className="modal-header pb-0">
        <h5 className="modal-title">
          <FormattedMessage id="network.lrn.create.modalTitle" />
        </h5>
        <CloseButton close={props.closeModal} />
      </div>
      <Form onSubmit={(e) => handleSubmit(e)}>
        <div className="modal-body pb-0">
          <TextFormInput
            labelTranslationId="network.lrn.create.lrn"
            value={lrnModel.lrn}
            handleInputChange={(value: string) => setLrnModel({ ...lrnModel, lrn: value })}
            errorHandler={getErrorHandler(nameOf<LrnModel>("lrn"))}
          />
          <DropdownFormInput
            labelTranslationId="network.lrn.create.npacRegionLabel"
            required
            value={lrnModel.npacRegion}
            handleInputChange={(value: string) => setLrnModel({ ...lrnModel, npacRegion: value })}
            errorHandler={getErrorHandler(nameOf<LrnModel>("npacRegion"))}
            options={npacRegionDropdownOptions}
          />
        </div>
        <div className="modal-footer">
          <Button color="link" type="button" className={`${theme === "light" ? "lblue-color" : ""}`} onClick={() => props.closeModal()}>
            <FormattedMessage id="network.lrn.create.cancelButton" />
          </Button>
          <Button color="primary" type="submit" className={`ml-auto ${theme === "light" ? "bg-lblue no-border" : ""}`} disabled={showLoadingIndicator}>
            {showLoadingIndicator && <i className="fas fa-spinner fa-spin mr-2" />}
            <FormattedMessage id="network.lrn.create.submitButton" />
          </Button>
        </div>
      </Form>
    </Modal>
  );
}

const validateLrn = (lrn: string, npacRegion: string) => {
  const errors: Errors = {};

  if (!lrn || lrn.length !== 10 || !isNumber(lrn)) {
    AddError(errors, nameOf<LrnModel>("lrn"), "network.lrn.create.invalidLrn");
  }

  if (!npacRegion || !NpacRegion[npacRegion]) {
    AddError(errors, nameOf<LrnModel>("npacRegion"), "network.lrn.create.invalidNpacRegion");
  }

  return errors;
};
