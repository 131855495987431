import React, { useState, FormEvent, useContext } from "react";
import {
  Row,
  Col,
  Container,
  Button,
  Form,
  Input,
  FormGroup,
  InputGroup,
  InputGroupAddon,
  InputGroupText
} from "reactstrap";
import { FormattedMessage, useIntl } from "react-intl";
import classNames from "classnames";
import BreadcrumbHeader from "components/common/BreadcrumbHeader";
import WorkQueueTable from "./WorkQueueTable";
import StatisticsCards from "./StatisticsCards";
import { AppContext } from "services/appContext/AppContext";

export default function WorkQueueList() {
  const [, setErrorMessageId] = useState("");
  const [searchInputValue, setSearchInputValue] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const [focusedSearchInput, setFocusedSearchInput] = useState(false);
  const [toggleRefreshValue, setToggleRefreshValue] = useState(true);
  const intl = useIntl();
  const { appContext } = useContext(AppContext);
  const theme = appContext.theme;

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    const inputValue = searchInputValue.trim();
    const errorMessageIdResult = validateSearchValue();
    setErrorMessageId(errorMessageIdResult);

    if (!errorMessageIdResult) {
      setSearchValue(inputValue);
    }
  };

  const handleSearchChange = (searchValue: string) => {
    setErrorMessageId("");
    setSearchInputValue(searchValue);
  };

  const handleToggleRefresh = (value: boolean) => {
    setToggleRefreshValue(value);
};

  return (
    <>
      <BreadcrumbHeader>
        <FormattedMessage id="workQueue.title" />
      </BreadcrumbHeader>
      <Container fluid>
        <StatisticsCards toggleRefreshValue={toggleRefreshValue} />
      </Container>
      <Container fluid className="mb-5">
        <Row className="justify-content-center">
          <Col md="12" className="">
            <h1 className={`display-3 ${theme === "light" ? "lblue-color" : "pc-soa-order-bg"}`}>
              <FormattedMessage id="workQueue.title" />
            </h1>
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col md="12" className="mb-1">
            <Form onSubmit={handleSubmit}>
              <FormGroup
                className={classNames("input-group-merge pc-search-container mb-0", {
                  focused: focusedSearchInput
                })}>
                <InputGroup className="input-group-merge">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="fas fa-search" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    type="text"
                    className="pc-search"
                    placeholder={intl.formatMessage({ id: "orders.search.placeholder" })}
                    value={searchInputValue}
                    onChange={(e) => handleSearchChange(e.currentTarget.value)}
                    onFocus={() => setFocusedSearchInput(true)}
                    onBlur={() => setFocusedSearchInput(false)}
                  />
                </InputGroup>
                <Button color={`${theme === "dark" ? "danger" : ""}`} type="submit" className={`pc-btn ml-4 ${theme === "light" ? "bg-lblue" : ""}`}>
                  <FormattedMessage id="orders.search" />
                </Button>
              </FormGroup>
            </Form>
          </Col>
        </Row>
      </Container>
      <Container fluid>
        <Row className="justify-content-center">
          <Col md="12">
            <WorkQueueTable search={searchValue} onToggleRefresh={handleToggleRefresh} />
          </Col>
        </Row>
      </Container>
    </>
  );
}

const validateSearchValue = (): string => {
  return "";
};
