import { OrderNumberActionConfiguration } from "components/orders/types/OrderNumberActionConfiguration";
import { OrderNumberActionType } from "services/portControl/types/OrderNumberActionType";
import { ApplyActionOnType } from "../../types/ApplyActionOnType";

export const OrderNumberActionConfigurations: Record<
  OrderNumberActionType,
  Record<ApplyActionOnType, OrderNumberActionConfiguration>
> = {
  Activate: {
    Number: {
      iconClass: "fa fa-check text-success",
      confirmMessageId: "orders.orderDetails.portingNumber.table.activateNumberQuestion",
      skipConfirmation: true
    },
    Order: {
      background: "bg-green",
      text: "orders.order.actions.activate",
      confirmMessageId: "orders.order.actions.activateQuestion",
      iconClass: "fa fa-check"
    }
  },
  Cancel: {
    Number: {
      iconClass: "far fa-ban text-danger",
      confirmMessageId: "orders.orderDetails.portingNumber.table.cancelNumberQuestion"
    },
    Order: {
      background: "bg-red",
      iconClass: "far fa-ban",
      text: "orders.order.actions.cancel",
      confirmMessageId: "orders.order.actions.cancelQuestion"
    }
  },
  RemoveFromConflict: {
    Number: {
      iconClass: "fas fa-minus-circle text-danger",
      confirmMessageId: "orders.orderDetails.portingNumber.table.removeFromConflictNumberQuestion"
    },
    Order: {
      background: "bg-gradient-orange",
      iconClass: "fas fa-minus-circle",
      text: "orders.order.actions.removeConflict",
      confirmMessageId: "orders.order.actions.removeFromConflictQuestion"
    }
  },
  Modify: {
    Number: {
      iconClass: "fa fa-edit"
    },
    Order: {
      background: "bg-gradient-red",
      text: "orders.order.actions.modify",
      iconClass: "fa fa-edit"
    }
  },
  Archive: {
    Number: { iconClass: "fas fa-archive text-crow", inactive: true },
    Order: {
      iconClass: "fas fa-archive",
      background: "bg-gradient-red",
      text: "orders.order.actions.archive",
      confirmMessageId: "orders.order.actions.archiveQuestion"
    }
  },
  UndoCancel: {
    Number: {
      iconClass: "fa fa-undo",
      skipConfirmation: true
    },
    Order: {
      iconClass: "fa fa-undo",
      background: "bg-gradient-gray",
      text: "orders.order.actions.undoCancel",
      skipConfirmation: true
    }
  },
  PreOrderSupplement: {
    Number: {
      iconClass: "fas fa-plus text-success"
    },
    Order: {
      background: "bg-green",
      text: "orders.order.actions.addSupplement",
      iconClass: "fas fa-sort-alt"
    }
  },
  PreOrderReject: {
    Number: {
      iconClass: "far fa-ban text-danger"
    },
    Order: {
      background: "bg-red",
      iconClass: "far fa-ban",
      text: "orders.order.actions.rejectSupplement",
      confirmMessageId: "orders.order.actions.rejectSupplementQuestion"
    }
  },
  PreOrderApprove: {
    Number: {
      iconClass: "fa fa-check text-success"
    },
    Order: {
      background: "bg-green",
      iconClass: "fa fa-check",
      text: "orders.order.actions.approveSupplement",
      confirmMessageId: "orders.order.actions.approveSupplementQuestion"
    }
  },
  PreOrderSubmit: {
    Number: {
      iconClass: "fa fa-check text-success"
    },
    Order: {
      background: "bg-green",
      iconClass: "fal fa-file-check",
      text: "orders.order.actions.preOrderSubmit",
      confirmMessageId: "orders.order.actions.preOrderSubmit"
    }
  },
  CsrRequested: {
    Number: { iconClass: "", inactive: true },
    Order: {
      background: "bg-green",
      iconClass: "fal fa-file-check",
      text: "orders.order.actions.csrRequested",
      confirmMessageId: "orders.order.actions.csrRequestedQuestion"
    }
  },
  CsrValid: {
    Number: { iconClass: "", inactive: true },
    Order: {
      background: "bg-green",
      iconClass: "fa fa-check",
      text: "orders.order.actions.csrValid",
      confirmMessageId: "orders.order.actions.csrValidQuestion"
    }
  },
  CsrInvalid: {
    Number: { iconClass: "", inactive: true },
    Order: {
      background: "bg-red",
      iconClass: "far fa-ban",
      text: "orders.order.actions.csrInvalid",
      confirmMessageId: "orders.order.actions.csrInvalidQuestion"
    }
  },
  CsrCancel: {
    Number: { iconClass: "", inactive: true },
    Order: {
      background: "bg-red",
      iconClass: "far fa-ban",
      text: "orders.order.actions.cancel",
      confirmMessageId: "orders.order.actions.cancelQuestion"
    }
  },
  CsrModify: {
    Number: { iconClass: "", inactive: true },
    Order: {
      background: "bg-green",
      iconClass: "fa fa-edit",
      text: "orders.order.actions.csrModify",
      confirmMessageId: "orders.order.actions.csrModify"
    }
  },
  CsrEscalate: {
    Number: { iconClass: "", inactive: true },
    Order: {
      background: "bg-green",
      iconClass: "fal fa-file-check",
      text: "orders.order.actions.csrEscalate",
      confirmMessageId: "orders.order.actions.csrEscalateQuestion"
    }
  },
  LsrSubmit: {
    Number: { iconClass: "", inactive: true },
    Order: {
      background: "bg-green",
      iconClass: "fal fa-check",
      text: "orders.order.actions.submitLsr",
      confirmMessageId: "orders.order.actions.submitLsrQuestion"
    }
  },
  CsrReceived: {
    Number: { iconClass: "", inactive: true },
    Order: {
      background: "bg-green",
      iconClass: "fal fa-file-check",
      text: "orders.order.actions.csrReceived",
      confirmMessageId: "orders.order.actions.csrReceived"
   }
  },
  CsrToLsr: {
    Number: { iconClass: "", inactive: true },
    Order: {
      background: "bg-green",
      iconClass: "fal fa-arrow-right",
      text: "orders.order.actions.csrToLsr",
      confirmMessageId: "orders.order.actions.csrToLsr"
   }
  },
  ScheduleActivation: {
    Number: { iconClass: "fa fa-clock text-green", inactive: true },
    Order: {
      background: "bg-green",
      iconClass: "fa fa-clock",
      text: "orders.order.actions.scheduleActivation",
      confirmMessageId: "orders.order.actions.scheduleActivation"
   },
  },
  ModifyActivation: {
    Number: { iconClass: "fa fa-clock text-orange", inactive: true },
    Order: {
      background: "bg-orange",
      iconClass: "fa fa-clock",
      text: "orders.order.actions.modifyActivation",
      confirmMessageId: "orders.order.actions.modifyActivation"
   },
  },
  CancelActivation: {
    Number: { iconClass: "fa fa-clock text-red", inactive: true },
    Order: {
      background: "bg-red",
      iconClass: "fa fa-clock",
      text: "orders.order.actions.cancelActivation",
      confirmMessageId: "orders.order.actions.cancelActivation"
   },
  },
  Restore: {
    Number: { iconClass: "fa fa-sync"},
    Order: {
      background: "bg-red",
      iconClass: "fa fa-sync",
      confirmMessageId: "orders.disasterRecovery.actions.restore",
      text: "orders.disasterRecovery.actions.restore",
    }
  }
};
