import React, { useEffect, useState } from "react";
import { Modal, Row, Col } from "reactstrap";
import { NumberApi } from "services/apis/NumberApi";
import { AppContext } from "services/appContext/AppContext";
import { handleError } from "services/util/ApiUtil";
import { NumberDetails, DefaultNumberDetails } from "services/apis/types/number/NumberDetails";
import NetworkDataTable from "./NetworkDataTable";
import OptionalDataTable from "./OptionalDataTable";
import CloseButton from "components/framework/modals/CloseButton";

type Props = {
  showModal: boolean;
  setShowModal: (isOpen: boolean) => void;
  phoneNumber: string;
};

export default function NumberDetailsModal(props: Props) {
  const [numberDetails, setNumberDetails] = useState<NumberDetails>(DefaultNumberDetails);
  const { appContext } = React.useContext(AppContext);
  const theme = appContext.theme;

  useEffect(() => {
    let isMounted = true;

    NumberApi.details(props.phoneNumber)
      .then(result => {
        if (isMounted) {
          setNumberDetails(result);
        }
      })
      .catch(error => handleError(error));

    return () => {
      isMounted = false;
    };
  }, [props.phoneNumber, appContext]);

  return (
    <Modal
      className="modal-dialog-centered pc-modal-xlg"
      isOpen={props.showModal}
      toggle={() => props.setShowModal(!props.showModal)}>
      <div className="modal-header">
        <Row className="align-items-center col-lg-12">
          <Col className="pl-0" lg="1" md="2">
            <div className={`avatar rounded-circle ${theme === "light" ? "bg-lblue" : "bg-primary"}`}>
              <i className="fas fa-phone fa-lg" />
            </div>
          </Col>
          <Col className="pl-0" lg="2" md="2">
            <h3 className="mb-0">{props.phoneNumber}</h3>
          </Col>
        </Row>
        <CloseButton close={() => props.setShowModal(false)} />
      </div>
      <div>
        <NetworkDataTable networkData={numberDetails.networkData} />
        <OptionalDataTable optionalData={numberDetails.optionalData} />
      </div>
    </Modal>
  );
}
