import React, { useContext, useState } from "react";
import DropdownInput from "components/framework/forms/DropdownInput";
import { NetworkProfileDto } from "services/apis/types/networkProfile/NetworkProfileDto";
import { Button, Label } from "reactstrap";
import NetworkProfileModal from "components/networkExplorer/networkProfile/NetworkProfileModal";
import { FormattedMessage } from "react-intl";
import { OrderCardModel } from "components/orders/types/OrderCardModel";
import { AppContext } from "services/appContext/AppContext";

type Props = {
  orderCard: OrderCardModel;
  setSelectedProfile: (newProfileId: string) => void;
  handleSelectedProfileEdit: (editedProfile: NetworkProfileDto) => void;
  disabled: boolean;
};

export default function PortInCardLrnInput(props: Props) {
  const [showNetworkProfileModal, setShowNetworkProfileModal] = useState(false);
  const { appContext } = useContext(AppContext);
  const theme = appContext.theme;

  const dropdownOptions = props.orderCard.numbers[0].availableProfiles.map((x) => ({
    key: x.description ? `${x.description} - ${x.lrn}` : x.lrn,
    value: x.profileId
  }));

  return (
    <>
      <Label className="form-control-label">
        <FormattedMessage id="orders.portIn.stepTwo.lrn" values={{ lata: props.orderCard.lata }} />
      </Label>
      <div className="d-flex mb-2">
        <DropdownInput
          sm
          className="form-control form-control-sm p-1"
          onChange={(e) => props.setSelectedProfile(e.target.value)}
          value={props.orderCard.numbers[0].selectedProfile?.profileId}
          options={dropdownOptions}
          disabled={props.disabled || props.orderCard.portToOrginal}
        />
        <div className="pl-3">
          <Button
            disabled={props.disabled || props.orderCard.portToOrginal}
            className={`btn-neutral ${theme === "light" ? "lblue-color" : ""}`}
            color="default"
            size="sm"
            onClick={(e) => {
              e.preventDefault();
              setShowNetworkProfileModal(true);
            }}>
            <i className="fa fa-pencil-alt" />
          </Button>
          {showNetworkProfileModal && (
            <NetworkProfileModal
              networkProfile={props.orderCard.numbers[0].selectedProfile}
              closeModal={() => setShowNetworkProfileModal(false)}
              submitCallback={(networkProfile) => {
                props.handleSelectedProfileEdit(networkProfile);
                setShowNetworkProfileModal(false);
              }}
            />
          )}
        </div>
      </div>
    </>
  );
}
