import React, { useContext } from "react";
import { NavLink } from "react-router-dom";
import "./NavBar.css";
import { AppContext } from "services/appContext/AppContext";

const Navbar = (props) => {
  const { appContext } = useContext(AppContext);
  const theme = appContext.theme;
  if (!props.navTitlesRoutes || props.navTitlesRoutes.length === 0) {
    return null; // or display a fallback UI, depending on your use case
  }

  return (
    <>
      <div className={`inventory_navbar ${theme === "light" ? "thead-light-blue" : "inventory_navbar_dbg"}`}>
        {props.navTitlesRoutes.map((x) => {
          const checkActive = (match, location) => {
            if (!location) return false;
            const { pathname } = location;
            return pathname === x.routes; // Compare with the current element's route
          };
          return (
            <div key={x.routes}>
              <ul className={theme === "light" ? "navbar_items_light" : "navbar_items"}>
                <li>
                  <NavLink className={theme === "light" ? "navbar_items_light_link" : "navbar_items_link"} isActive={checkActive} to={x.routes}>
                    {x.titles}
                  </NavLink>
                </li>
              </ul>
            </div>
          );
        })}
      </div>
    </>
  );
};

export default Navbar;
