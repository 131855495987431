import React, { useCallback, useContext, useEffect, useState } from "react";
import { Row, Col, Container, Modal, Button } from "reactstrap";
import { FormattedMessage, useIntl } from "react-intl";
import BreadcrumbHeader from "components/common/BreadcrumbHeader";
import { LoadingIndicator } from "components/framework/loadingIndicator/LoadingIndicator";
import PortInHandlers from "../portIn/PortInHandlers";
import { OrderRequestType } from "services/apis/types/port/OrderRequestType";
import { Link, useHistory, useLocation } from "react-router-dom";
import PortInValidationSection from "../portIn/PortInValidationSection";
import { PortApi } from "services/apis/PortApi";
import { showInfoNotification } from "components/framework/notification/NotificationUtil";
import { ApiErrorType } from "components/common/types/ApiErrorType";
import ConfirmationModal from "components/framework/modals/ConfirmationModal";
import { PortProposalDto } from "services/apis/types/port/PortProposalDto";
import CloseButton from "components/framework/modals/CloseButton";
import { AppContext } from "services/appContext/AppContext";
import { EnvironmentUtil } from "services/util/EnvironmentUtil";

export default function DisasterRecovery() {
  const history = useHistory();
  const location = useLocation();
  const intl = useIntl();
  const {
    // TODO : the following state assessors could be moved to a 'PortInContext' -
    // as these variables are shared between components
    orderRequestType,
    setOrderRequestType,
    setExcelTemplateAccounts,
    excelTemplateAccounts,
    accountModels,
    showLoadingIndicator,
    portProposals,
    spidLogosRetrieved,
    setValidationErrorMessages,
    validationErrorMessages,
    validateNumbers,
    fetchSpidLogos,
    checkAndImportAccountFromPreviousOrder,
    discardPreviousData
  } = PortInHandlers();

  const [showLoadingIndicatorForDR, setShowLoadingIndicatorForDR] = useState(false);
  const [isSuppressDuplicateTnValidation, setIsSuppressDuplicateTnValidation] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [confirmed, setConfirmed] = useState(false);
  const [storedProposalResponse, setStoredProposalResponse] = useState<PortProposalDto | undefined>(
    undefined
  ); // Store the proposalResponse
  const [storedProfiles, setStoredProfiles] = useState(null); // Store profiles
  const [storedAdminProjectId, setStoredAdminProjectId] = useState(null);
  const { appContext } = useContext(AppContext);
  const theme = appContext.theme;
  const companyId = appContext.localStorageInfo.selectedProfile?.companyId;
  const isMixNetworkUrl = EnvironmentUtil.isMixNetwork;

  const createRequestForOrder = (order, numbers, profiles, adminProjectId) => {
    const processedNumbers = Array.isArray(numbers) ? numbers : [numbers];

    return {
      projectId: "",
      adminProjectId: adminProjectId,
      companyId: `${isMixNetworkUrl ? companyId : ""}`,
      loosingProvider: order.currentSpId,
      numbers: processedNumbers.map((number) => {
        const profileId = number.availableProfiles[0];
        const profile = profiles[profileId];

        return {
          number: number.number,
          lrn: profile?.lrn,
          svType: profile?.svType,
          billingId: profile?.billingId || null,
          userLocation: profile?.endUserLocation || null,
          userLocationType: profile?.endUserLocationType || null,
          dpcSsnInfo: {
            classDpc: profile?.networkData?.classDpc,
            classSsn: profile?.networkData?.classSsn,
            lidbDpc: profile?.networkData?.lidbDpc,
            lidbSsn: profile?.networkData?.lidbSsn,
            isvmDpc: profile?.networkData?.isvmDpc,
            isvmSsn: profile?.networkData?.isvmSsn,
            cnamDpc: profile?.networkData?.cnamDpc,
            cnamSsn: profile?.networkData?.cnamSsn,
            wsmscDpc: profile?.networkData?.wsmscDpc,
            wsmscSsn: profile?.networkData?.wsmscSsn
          },
          optionalData: {
            altSpId: profile?.optionalData?.altSpId,
            lastAltSpId: profile?.optionalData?.lastAltSpId,
            altBillingId: profile?.optionalData?.altBillingId,
            altUserLocationType: profile?.optionalData?.altUserLocationType,
            altUserLocationValue: profile?.optionalData?.altUserLocationValue,
            voiceUri: profile?.optionalData?.voiceUri,
            mmsUri: profile?.optionalData?.mmsUri,
            smsUri: profile?.optionalData?.smsUri
          }
        };
      }),
      isSuppressDuplicateTnValidation: isSuppressDuplicateTnValidation
    };
  };

  // Calling Multiple Api calls
  useEffect(() => {
    if (portProposals?.orders.length && accountModels?.length > 0) {
      handleMultipleApiCalls(portProposals);
    }
  }, [accountModels, portProposals]);

  const handleMultipleApiCalls = async (proposalResponse) => {
    const { orders = [], profiles = {}, notPortable = {}, adminProjectId } = proposalResponse;
    setStoredProposalResponse(proposalResponse);
    setStoredProfiles(profiles);
    setStoredAdminProjectId(adminProjectId);
    if (Object.keys(notPortable).length > 0 && orders.length > 0 && !confirmed) {
      // If there are notPortable numbers and orders, ask for user confirmation
      setShowConfirmation(true);
      return;
    } else if (Object.keys(notPortable).length === 0 && orders.length > 0) {
      // If no notPortable numbers and we have valid orders, proceed with API calls
      callApiForOrders(proposalResponse, profiles, adminProjectId);
    }
  };

  const callApiForOrders = async (proposalResponse, profiles, adminProjectId) => {
    setShowLoadingIndicatorForDR(true);

    const { orders = [] } = proposalResponse;

    // Collect all API call promises
    const apiCalls: Promise<any>[] = orders
      .map((order) => {
        const validNumbers = order.numbers.filter((number) => {
          const profileId = number.availableProfiles[0];
          return profiles[profileId]; // Ensure valid profile exists
        });

        if (validNumbers.length === 0) {
          return null;
        }

        const requestData = createRequestForOrder(order, validNumbers, profiles, adminProjectId);

        return PortApi.DisasterRecoveryPort(requestData)
          .then((response) => ({ status: "fulfilled", response }))
          .catch((error) => ({ status: "rejected", error }));
      })
      .filter(Boolean);

    // Execute all the API calls
    Promise.allSettled(apiCalls)
      .then((results: Array<PromiseSettledResult<any>>) => {
        let allSuccessful = true;
        results.forEach((result) => {
          if (result.status === "rejected") {
            allSuccessful = false;
            setValidationErrorMessages((prev) => [
              ...prev,
              {
                message: result.reason?.message || "Unknown error",
                type: ApiErrorType.Danger
              }
            ]);
          } else if (result.status === "fulfilled") {
            const apiResponse = result.value;
            if (apiResponse?.status === "rejected" || apiResponse?.error?.result === "failed") {
              allSuccessful = false;
              setValidationErrorMessages((prev) => [
                ...prev,
                {
                  message: apiResponse?.error?.message || "Unknown failure",
                  type: ApiErrorType.Danger
                }
              ]);
            }
          }
        });

        if (allSuccessful) {
          history.push("/Orders");
          showInfoNotification(intl.formatMessage({ id: "orders.disasterRecovery.create" }));
        }
      })
      .finally(() => setShowLoadingIndicatorForDR(false));
  };
  useEffect(() => {
    if (
      location &&
      location?.state &&
      (location?.state as any)["previousOrder"] &&
      excelTemplateAccounts.length === 0
    ) {
      checkAndImportAccountFromPreviousOrder(location);
    }
  }, [location, excelTemplateAccounts.length, checkAndImportAccountFromPreviousOrder]);

  const showNumberInputAndValidationStep = useCallback(() => {
    const accountsAndCardsLoaded = accountModels?.some(
      (accountModel) => accountModel?.orderCards?.length > 0
    );
    return !accountsAndCardsLoaded || !spidLogosRetrieved;
    // eslint-disable-next-line
  }, [excelTemplateAccounts.length, accountModels, spidLogosRetrieved]);

  const validateNumbersAsync = async (
    numberInput: string,
    orderRequestType: OrderRequestType,
    isSuppressDuplicateTnValidation: boolean,
    isDisasterRecovery?: boolean
  ) => {
    setIsSuppressDuplicateTnValidation(isSuppressDuplicateTnValidation);
    let inputStr = numberInput;
    if (inputStr.includes("\n" || ",")) {
      inputStr = inputStr.replace(/\n/g, ",");
    }
    return validateNumbers(
      inputStr,
      orderRequestType,
      isSuppressDuplicateTnValidation,
      isDisasterRecovery
    );
  };

  const closeModal = () => {
    setShowConfirmation(false);
  };

  const handleConfirmation = () => {
    // When user confirms to continue with remaining numbers
    setConfirmed(true);
    setShowConfirmation(false);
    callApiForOrders(storedProposalResponse, storedProfiles, storedAdminProjectId); // Call API after confirmation
  };

  return (
    <>
      <BreadcrumbHeader>
        <Link to={"/Orders"}>
          <span className={`${theme === "light" ? "lblue-color" : "text-white"}`}>
            <FormattedMessage id="orders.title" />
          </span>
        </Link>
        {showNumberInputAndValidationStep() ? (
          <FormattedMessage id="orders.disasterRecovery.title" />
        ) : (
          <>
            <span className="cursor-pointer" onClick={discardPreviousData}>
              <FormattedMessage id="orders.disasterRecovery.title" />
            </span>
            <span className="pc-breadcrumb-item">
              <FormattedMessage id="orders.portIn.stepTwo.breadcrumbTitle" />
            </span>
          </>
        )}
      </BreadcrumbHeader>
      <Container fluid>
        <Row className="card-wrapper">
          {
            <>
              <Col lg="12">
                <PortInValidationSection
                  handleSubmit={validateNumbersAsync}
                  setValidationErrorMessages={setValidationErrorMessages}
                  portProposals={portProposals}
                  validationMessages={validationErrorMessages}
                  orderRequestType={orderRequestType}
                  setOrderRequestType={setOrderRequestType}
                  setExcelTemplateAccounts={setExcelTemplateAccounts}
                  isShowLoadingIndicator={showLoadingIndicator}
                />
                {(showLoadingIndicator || showLoadingIndicatorForDR) && <LoadingIndicator white />}
              </Col>
              {showConfirmation && (
                <Modal className="modal-dialog-centered modal-md" isOpen={true} toggle={closeModal}>
                  <div className="modal-header pb-0">
                    <strong className="modal-title" id="modal-title-default">
                      {intl.formatMessage({ id: "confirmationModal.confirmButton" })}
                    </strong>
                    <CloseButton close={closeModal} />
                  </div>
                  <div className="modal-body pb-0">
                    <h4>
                      {intl.formatMessage({ id: "orders.disasterRecovery.confirmation.title" })}
                    </h4>
                    <div style={{ maxHeight: "200px", overflowY: "auto" }}>
                      <ul>
                        {Object.entries(storedProposalResponse?.notPortable || {}).map(
                          ([number, message]) => (
                            <li key={number}>
                              <strong>{number}</strong>: {message}
                            </li>
                          )
                        )}
                      </ul>
                    </div>
                    <h4>
                      {intl.formatMessage({ id: "orders.disasterRecovery.confirmation.footer" })}
                    </h4>
                  </div>
                  <div className="modal-footer">
                    <Button color="link" className={`${theme === "light" ? "lblue-color" : ""}`} type="button" onClick={closeModal}>
                      <FormattedMessage id="confirmationModal.cancelButton" />
                    </Button>
                    <Button
                      color="danger"
                      type="button"
                      className={`ml-auto ${theme === "light" ? "bg-lblue no-border" : ""}`}
                      onClick={handleConfirmation}>
                      {intl.formatMessage({ id: "orders.disasterRecovery.confirmation.button.continue" })}
                    </Button>
                  </div>
                </Modal>
              )}
            </>
          }
        </Row>
      </Container>
    </>
  );
}
