import { ApiError } from "components/common/types/ApiError";
import { ApiErrorType } from "components/common/types/ApiErrorType";
import { ExcelTemplateAccount } from "components/orders/types/ExcelTemplateAccount";
import { PortInAccountModel } from "components/orders/types/PortInAccountModel";
import { useCallback, useContext, useMemo, useState } from "react";
import { useIntl } from "react-intl";
import { PortApi } from "services/apis/PortApi";
import { ServiceProviderApi } from "services/apis/ServiceProviderApi";
import { OrderHandlerType } from "services/apis/types/port/OrderHandlerType";
import { OrderRequestType } from "services/apis/types/port/OrderRequestType";
import { PortProposalDto } from "services/apis/types/port/PortProposalDto";
import { SpidLogo } from "services/apis/types/port/SpidLogo";
import { AppContext } from "services/appContext/AppContext";
import { useIsMounted } from "services/customHooks/useIsMounted";
import { handleError } from "services/util/ApiUtil";
import { deepCloneWithCircular, distinctFilter } from "services/util/ArrayUtil";
import { validateNumberInputValue } from "services/validators/ValidatePhoneNumbers";
import { ValidationResult } from "services/validators/ValidationResult";
import  usePortInAccountModelMapper from "services/customHooks/usePortInAccountModelMapper";
import { PreviousOrderDto } from "services/apis/types/order/OrderDto";
import { EnvironmentUtil } from "services/util/EnvironmentUtil";

const isMixNetworkUrl = EnvironmentUtil.isMixNetwork;
export default function PortInHandlers() {
    const intl = useIntl();
    
    const {appContext} = useContext(AppContext);
    const [selectedProfile] = useState(()=> appContext.localStorageInfo.selectedProfile);

    const [isVpop] = useState(()=> selectedProfile?.external);

    const [previousOrder, setPreviousOrder]=useState<PreviousOrderDto|undefined>(undefined);
    const [orderRequestType, setOrderRequestType] = useState(OrderRequestType.OrderOnly);
    const orderRequestTypesWithCsrRequest = [OrderRequestType.PreOrderAndOrder, OrderRequestType.CSROnly];
        
    const [excelTemplateAccounts, setExcelTemplateAccounts] = useState<Array<ExcelTemplateAccount>>([]);
    const [portProposals, setPortProposals] = useState<PortProposalDto>();
    const [validationErrorMessages, setValidationErrorMessages] = useState<ApiError[]>([]);
    const showCustomerDetails = useMemo(() => portProposals?.orders.some((x) => x.showAccountDetails),[portProposals]);
    const [spidLogosRetrieved, setSpidLogosRetrieved] = useState(false);
    const [spidLogos, setSpidLogos] = useState<SpidLogo[]>([]);
    
  
    const PortInAccountModelsMapper = usePortInAccountModelMapper();
    
    const isMounted = useIsMounted();
    
    const [showLoadingIndicator, setShowLoadingIndicator] = useState(false);

    const selectCsrRequestByDefault = useCallback((account: PortInAccountModel, orderRequestType:OrderRequestType, tenantHasCsrPermissions) : boolean =>{
      // if 'csr only' order then csr request will be selected
      if (orderRequestType === OrderRequestType.CSROnly)
        return true;

      // in other cases, csr request will be selected if :
      // order type allows csr request, 
      // tenant/company has 'allowCsr' enabled, 
      // csrRequested was enabled as part of a 'spreadsheet import' 
      return(tenantHasCsrPermissions ?? false) 
        && orderRequestTypesWithCsrRequest.some(o=> o === orderRequestType) 
        && account.csrRequested === true;
    },[orderRequestTypesWithCsrRequest]);

    const CircularJSON = require('circular-json');

    const filterUniqueObjectsByLataAndNumbers = (orderCards) => {
        var uniqueLATAs = {};
        return orderCards.filter( obj =>  {
            const clonedCard = deepCloneWithCircular(obj.numbers);
          const key = obj.lata + CircularJSON.stringify(clonedCard);
          if (!uniqueLATAs[key]) {
            uniqueLATAs[key] = true; // Add LATA & number to the tracking object
            return true; // Include the object in the filtered array
          }
          return false; // Exclude duplicate objects with the same LATA
        });
    }
    const selectOrderRequestHandler = useCallback((orderRequestType: OrderRequestType, orderHandler: OrderHandlerType, hasCsrRequest: boolean) => {
        let selectedOrderHandler = OrderHandlerType.SelectOne;
        
        switch (true)
        {
          case hasCsrRequest:
          case orderRequestType === OrderRequestType.PreOrderAndOrder && isVpop:
          case orderRequestType === OrderRequestType.OrderOnly:
          case orderHandler === OrderHandlerType.PortingDotCom:
            selectedOrderHandler = OrderHandlerType.PortingDotCom; 
            break;
        
          case orderHandler === OrderHandlerType.ServiceProvider:
            selectedOrderHandler = OrderHandlerType.ServiceProvider;
            break;

          default: 
          selectedOrderHandler = OrderHandlerType.SelectOne;
          break;
        }
        
        return selectedOrderHandler;
    },[isVpop]);
        
    

    const fetchSpidLogos = useCallback((proposal: PortProposalDto | undefined) => {
        if (!spidLogosRetrieved) {

            proposal?.orders
            .map((order) => order.currentSpId)
            .forEach((spid) => {
              ServiceProviderApi.getLogo(spid).then((logoResponse) => {
                if (logoResponse instanceof Blob) {
                  setSpidLogos((previous) => [
                    ...previous,
                    { logo: URL.createObjectURL(logoResponse), spid: spid }
                  ]);
                }
              });
            });

            setSpidLogosRetrieved(true);
        }
      }, [spidLogosRetrieved]);
    
    
      const requestPortProposalAndSetErrors = useCallback((numbersToPort, ClientOrderRequestType, isSuppressDuplicateTnValidation, selectedProfileCompanyId, isDisasterRecovery )=> {
        const orderRequestType = isMixNetworkUrl ? OrderRequestType.PreOrderAndOrder : ClientOrderRequestType
        const handlePortApiRequest = (apiEndpoint) => {
            apiEndpoint({ numbersToPort, orderRequestType, isSuppressDuplicateTnValidation, selectedProfileCompanyId })
                .then((result) => {
                    setPortProposals(result);
                    setValidationErrorMessages((prev) => 
                        [...prev, 
                        ...Object.keys(result.notPortable).map((x) => {
                            return {
                                message: result.notPortable[x],
                                type: ApiErrorType.Danger
                            };
                        })]
                        .filter(distinctFilter) as ApiError[]
                    );
                })
                .catch((error) => handleError(error))
                .finally(() => {
                    if (isMounted.current) {
                        setShowLoadingIndicator(false);
                    }
                });
        };
        
        isDisasterRecovery ? handlePortApiRequest(PortApi.getDisasterRecoveryProposal): !isMixNetworkUrl ? handlePortApiRequest(PortApi.getProposal) : handlePortApiRequest(PortApi.getMixProposal);
        
    },[isMounted]);
    
    const validateNumbers = useCallback((numberInputValue: string, orderRequestType: OrderRequestType, isSuppressDuplicateTnValidation?:boolean, isDisasterRecovery?: boolean ) => {
        if (isMounted.current) {
            setShowLoadingIndicator(true);
        }
        
        if (validateNumberInputValue(numberInputValue) === ValidationResult.Valid) {
            setValidationErrorMessages([]);
            requestPortProposalAndSetErrors(numberInputValue, orderRequestType, isSuppressDuplicateTnValidation, selectedProfile?.companyId, isDisasterRecovery);
            
        } else {
            setShowLoadingIndicator(false);
            setValidationErrorMessages([{
                    message: `${intl.formatMessage({ id: "orders.portIn.stepOne.invalidNumbers" })}`,
                    type: ApiErrorType.Danger
            }]);
        }
    }, [isMounted, intl, requestPortProposalAndSetErrors]);


    const checkAndImportAccountFromPreviousOrder = useCallback((location)=> {
        if (location) {
            const prevOrder = location?.state["previousOrder"] as PreviousOrderDto;
            if (prevOrder) {
                setPreviousOrder(prevOrder);
                setOrderRequestType(OrderRequestType.PreOrderAndOrder);
                setExcelTemplateAccounts([...[PortInAccountModelsMapper.mapOrderToAccount(prevOrder)]]);
            
                validateNumbers(prevOrder?.numbers.map(n=> n.phoneNumber).join(","), prevOrder.orderRequestType);
            }
        }
    },[validateNumbers, PortInAccountModelsMapper, setPreviousOrder]);


    const accountModels = useMemo(() => {
        const accountModelsMapped = PortInAccountModelsMapper.mapToModel(excelTemplateAccounts, portProposals, orderRequestType)
            .filter((accountModel) => accountModel.orderCards.length > 0)
            .map(account=> {return {
                        ...account,
                        previousOrder: previousOrder,
                        csrRequested: selectCsrRequestByDefault(account, orderRequestType, selectedProfile?.allowCsr),
                        orderCards: filterUniqueObjectsByLataAndNumbers(account.orderCards).map(card=> { return {
                            ...card,
                            orderHandler: selectOrderRequestHandler(orderRequestType, card.orderHandler, selectCsrRequestByDefault(account, orderRequestType, selectedProfile?.allowCsr)),
                            adminProjectId: account.adminProjectId
                        }
                    })
                }
        });

        if (PortInAccountModelsMapper.apiErrors?.length >0){
            setValidationErrorMessages(prev=> [ 
                ...prev, 
                ...PortInAccountModelsMapper.apiErrors
            ].filter(distinctFilter));
        }

        return accountModelsMapped;
        // eslint-disable-next-line
    },[portProposals, excelTemplateAccounts, previousOrder]);

    const discardPreviousData = useCallback(() => {
        setExcelTemplateAccounts([]);
        setPortProposals(undefined);
        setValidationErrorMessages([]);
        
    }, []);

    return {
        orderRequestType,
        setOrderRequestType,
        excelTemplateAccounts : useMemo(()=> excelTemplateAccounts,[excelTemplateAccounts]),
        setExcelTemplateAccounts,
        showLoadingIndicator,
        portProposals,
        spidLogos,
        spidLogosRetrieved,
        accountModels,
        showCustomerDetails,
        validationErrorMessages,
        setValidationErrorMessages,
        validateNumbers,
        fetchSpidLogos,
        checkAndImportAccountFromPreviousOrder,
        discardPreviousData
    }
}