import React, { useContext } from "react";
import { FormattedMessage } from "react-intl";
import { Button, Modal } from "reactstrap";
import CloseButton from "components/framework/modals/CloseButton";
import { AppContext } from "services/appContext/AppContext";

type Props = {
  closeModal: () => void;
  showLoadingIndicator: boolean;
  onSubmit: () => void;
};

export default function RestoreToOriginalModal(props: Props) {
  const { appContext } = useContext(AppContext);
  const theme = appContext.theme;
  
  return (
    <>
      <Modal className="modal-dialog-centered modal-sm" isOpen={true}>
        <div className="modal-header pb-0">
          <h6 className="modal-title">
            <FormattedMessage id="orders.disasterRecovery.restore.title" />
          </h6>
          <CloseButton close={props.closeModal} />
        </div>
        <div className="modal-body pb-0">
          <div>
            <FormattedMessage id="orders.orderDetails.restoreToOriginal.confirmMessage"></FormattedMessage>
          </div>
        </div>
        <div className="modal-footer">
          <Button color="link" type="button" className={`${theme === "light" ? "lblue-color" : ""}`} onClick={props.closeModal}>
            <FormattedMessage id="confirmationModal.cancelButton" />
          </Button>
          <Button
            color="primary"
            type="submit"
            disabled={props.showLoadingIndicator}
            className={`ml-auto ${theme === "light" ? "bg-lblue no-border" : ""}`}
            onClick={props.onSubmit}>
            {props.showLoadingIndicator && <i className="fas fa-spinner fa-spin mr-2" />}
            <FormattedMessage id="confirmationModal.confirmButton" />
          </Button>
        </div>
      </Modal>
    </>
  );
}
