import React, { useContext, useEffect, useState } from "react";
import { Modal, Button } from "reactstrap";
import CloseButton from "components/framework/modals/CloseButton";
import { FormattedMessage } from "react-intl";
import { SpIdProfileDto } from "services/apis/types/account/SpIdProfileDto";
import CheckBoxFormInput from "components/framework/forms/CheckboxFormInput";
import { AppContext } from "services/appContext/AppContext";

type Props = {
  onSubmit: (selectedCustomers: SpIdProfileDto[]) => Promise<unknown>;
  closeModal: () => void;
  isCustomerModalOpen: boolean;
  toggleCustomerModal: () => void;
  customers: SpIdProfileDto[];
  companyIds: string[]; // Array of company IDs
  selectedCustomers: SpIdProfileDto[]; // Previously selected customers
};

export default function CustomersModal(props: Props) {
  const [showLoadingIndicator, setShowLoadingIndicator] = useState(false);
  const [selectAll, setSelectAll] = useState(false);
  const [customers, setCustomers] = useState<SpIdProfileDto[]>([]);
  const { appContext } = useContext(AppContext);
  const theme = appContext.theme;

  useEffect(() => {
    if (props.isCustomerModalOpen) {
      if(props.companyIds?.length === 0 || props.selectedCustomers?.length === 0) {
        setCustomers(props.customers)
      }

      if(props.companyIds?.length > 0) {
        const initializedCustomers = props.customers.map((customer) => ({
          ...customer,
          selected: props.companyIds?.includes(customer.companyId),
        }));
        setCustomers(initializedCustomers);
        const allSelected = initializedCustomers.every(customer => customer.selected);
        setSelectAll(allSelected);
      }

      if(props.selectedCustomers.length > 0) {
        const initializedCustomers = props.customers.map((customer) => ({
          ...customer,
          selected: props.selectedCustomers.some(
            (selected) => selected.companyId === customer.companyId
          )
        }));
        setCustomers(initializedCustomers);
        // Check if all customers are selected initially
        const allSelected = initializedCustomers.every(customer => customer.selected);
        setSelectAll(allSelected);
      }
    }
  }, [props.isCustomerModalOpen, props.customers, props.companyIds, props.selectedCustomers]);

  const handleSelectAll = () => {
    const newSelection = customers.map((customer) => ({
      ...customer,
      selected: !selectAll,
    }));
    setSelectAll(!selectAll);
    setCustomers(newSelection);
  };

  const handleIndividualSelect = (companyId: string) => {
    const newSelection = customers.map((customer) =>
      customer.companyId === companyId
        ? { ...customer, selected: !customer.selected }
        : customer
    );
    setCustomers(newSelection);

    // Update selectAll state if all customers are selected
    const allSelected = newSelection.every(customer => customer.selected);
    setSelectAll(allSelected);
  };

  const handleSaveChanges = () => {
    const selectedCustomers = customers.filter((customer) => customer.selected);
    props.onSubmit(selectedCustomers);
    props.closeModal();
  };

  return (
    <Modal
      className="modal-dialog-centered"
      isOpen={props.isCustomerModalOpen}
      toggle={props.toggleCustomerModal}
    >
      <div className="modal-header">
        <h5 className="modal-title">Customers</h5>
        <CloseButton close={props.closeModal} />
      </div>
      <div className="modal-body p-0">
        <div className="customer-list">
          <div className="customer-header">
            <CheckBoxFormInput
              checked={selectAll}
              value="selectAll"
              handleInputChange={handleSelectAll}
            />
            <span>Name</span>
          </div>
          {customers.map((customer) => (
            <div key={customer.companyId} className="customer-row">
              <CheckBoxFormInput
                label=""
                checked={customer.selected}
                value={customer.companyId}
                handleInputChange={() => handleIndividualSelect(customer.companyId)}
              />
              <span>{customer.name}</span>
            </div>
          ))}
        </div>
      </div>
      <div className="modal-footer">
        <Button color="link" className={`${theme === "light" ? "lblue-color" : ""}`} type="button" onClick={props.closeModal}>
          <FormattedMessage id="network.CustomersModal.edit.cancelButton" />
        </Button>
        <Button
          color="primary"
          onClick={handleSaveChanges}
          className={`ml-auto ${theme === "light" ? "bg-lblue no-border" : ""}`}
          disabled={showLoadingIndicator}
        >
          {showLoadingIndicator && <i className="fas fa-spinner fa-spin mr-2" />}
          <FormattedMessage id="network.CustomersModal.edit.submitButton" />
        </Button>
      </div>
    </Modal>
  );
}
