import React, { useState, useEffect } from "react";
import { Row, Col } from "reactstrap";
import CountCard from "components/common/countCard/CountCard";
import { WorkStatisticsDto } from "services/apis/types/workQueue/WorkStatisticsDto";
import { WorkQueueApi } from "services/apis/WorkQueueApi";
import { useIsMounted } from "services/customHooks/useIsMounted";
import { handleError } from "services/util/ApiUtil";
import { LoadingIndicator } from "components/framework/loadingIndicator/LoadingIndicator";

type Props = {
    toggleRefreshValue: boolean;
};

export default function StatisticsCards(props: Props) {
    const [showLoadingIndicator, setShowLoadingIndicator] = useState(false);
    const [statisticsDto, setStatisticsDto] = useState<WorkStatisticsDto>();
    const [statisticsDtoLoaded, setStatisticsDtoLoaded] = useState(false);
    const isMounted = useIsMounted();

    useEffect(() => {
        setShowLoadingIndicator(true);
        WorkQueueApi.statistics()
            .then((result) => {
                if (isMounted) {
                    setStatisticsDto(result);
                    setStatisticsDtoLoaded(true);
                }
            })
            .catch((error) => handleError(error))
            .finally(() => {
                if (isMounted) {
                    setShowLoadingIndicator(false);
                }
            });
    }, [isMounted, props.toggleRefreshValue]);

    return (
        <>
            {!statisticsDtoLoaded || showLoadingIndicator ? (
                <LoadingIndicator white />
            ) : (
                statisticsDto && (
                    <Row className="d-flex">
                        <Col className="workqueue-count-card-container">
                            <CountCard
                                count={statisticsDto.newOrders}
                                titeTranslationId="workQueue.statistics.newOrders.title"
                                descriptionTranslationId="workQueue.statistics.newOrders.description"
                                iconClass="fa-sparkles"
                                iconBackgroundColor="bg-gradient-info"
                            />
                        </Col>
                        <Col className="workqueue-count-card-container ">
                            <CountCard
                                count={statisticsDto.pendingOrders}
                                titeTranslationId="workQueue.statistics.pendingOrders.title"
                                descriptionTranslationId="workQueue.statistics.pendingOrders.description"
                                iconClass="fas fa-clock"
                                iconBackgroundColor="bg-gradient-success"
                            />
                        </Col>
                        <Col className="workqueue-count-card-container ">
                            <CountCard
                                count={statisticsDto.reviewOrders}
                                titeTranslationId="workQueue.statistics.reviewOrders.title"
                                descriptionTranslationId="workQueue.statistics.reviewOrders.description"
                                iconClass="fas fa-file-search"
                                iconBackgroundColor="bg-gradient-success"
                            />
                        </Col>
                        <Col className="workqueue-count-card-container ">
                            <CountCard
                                count={statisticsDto.supplementedOrders}
                                titeTranslationId="workQueue.statistics.supplementedOrders.title"
                                descriptionTranslationId="workQueue.statistics.supplementedOrders.description"
                                iconClass="fa-edit"
                                iconBackgroundColor="bg-gradient-orange"
                            />
                        </Col>
                        <Col className="workqueue-count-card-container ">
                            <CountCard
                                count={statisticsDto.rejectedOrders}
                                titeTranslationId="workQueue.statistics.rejectedOrders.title"
                                descriptionTranslationId="workQueue.statistics.rejectedOrders.description"
                                iconClass="fa-times"
                                iconBackgroundColor="bg-gradient-red"
                            />
                        </Col>
                    </Row>
                )
            )}
        </>
    );
}