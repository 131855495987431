import { Row, Col, Card, CardHeader, CardTitle, CardBody, Button } from "reactstrap";
import React, { useState, useRef, useContext } from "react";
import { FormattedMessage } from "react-intl";
import DateTimeFormInput from "components/framework/forms/DateTimeFormInput";
import { Moment } from "moment";
import { nameOf } from "services/util/ObjectUtil";
import { ExportModel, DefaultExportModel } from "./types/ExportModel";
import { CSVLink } from "react-csv";
import { AppConfiguration } from "AppConfiguration";
import { BillingTransactionsDto, DefaultBillingTransactionsDto } from "services/apis/types/reports/BillingTransactionsDto";
import { getUtcDate } from "services/util/DateUtil";
import { useErrors } from "services/customHooks/useErrors";
import { useIsMounted } from "services/customHooks/useIsMounted";
import { ReportsApi } from "services/apis/ReportsApi";
import { handleError, getFieldErrors } from "services/util/ApiUtil";
import { AppContext } from "services/appContext/AppContext";

export default function BillingTransactions() {
  const { setErrors, getErrorHandler } = useErrors();
  const [showExportLoadingIndicator, setShowExportLoadingIndicator] = useState(false);
  const [exportModel, setExportModel] = useState<ExportModel>(DefaultExportModel);
  const [billingTransactionsDtos, setBillingTransactionsDtos] = useState<BillingTransactionsDto[]>([]);
  const isMounted = useIsMounted();
  const csvRef = useRef<any>();
  const { appContext } = useContext(AppContext);
  const theme = appContext.theme;

  const handleExport = () => {
    setShowExportLoadingIndicator(true);

    ReportsApi.getInvoice(exportModel.from.toDate(), exportModel.to.toDate())
      .then((result) => {
        if (isMounted.current) {
          setBillingTransactionsDtos(result);
          csvRef.current.link.click();
        }
      })
      .catch((error) => {
        handleError(error);
        if (isMounted.current) {
          const errorsResult = getFieldErrors(error.fieldErrors);
          setErrors(errorsResult);
        }
      })
      .finally(() => {
        if (isMounted.current) {
          setShowExportLoadingIndicator(false);
        }
      });
  };
  return (
    <Col md="12">
      <Card>
        <CardHeader>
          <CardTitle tag="h6" className="text-uppercase text-light mb-0">
          </CardTitle>
          <span className="h2 font-weight-bold mb-0">
            <FormattedMessage id="reports.billingTransactions.subtitle" />
          </span>
        </CardHeader>
        <CardBody>
          <Row className="pc-flex-end">
            <Col md="4">
              <DateTimeFormInput
                className="col-lg-12"
                labelTranslationId="reports.export.fromDate"
                value={exportModel.from}
                handleInputChange={(value: Moment) =>
                  setExportModel({ ...exportModel, from: value })
                }
                errorHandler={getErrorHandler(nameOf<ExportModel>("from"))}
              />
            </Col>
            <Col md="4">
              <DateTimeFormInput
                className="col-lg-12"
                labelTranslationId="reports.export.toDate"
                value={exportModel.to}
                handleInputChange={(value: Moment) =>
                  setExportModel({ ...exportModel, to: value })
                }
                errorHandler={getErrorHandler(nameOf<ExportModel>("to"))}
              />
            </Col>
            <Col md="1" className="offset-2">
              <CSVLink
                ref={csvRef}
                data={getCsvData(billingTransactionsDtos)}
                target="_blank"
                className="hidden"
                filename={`transactions-${exportModel.from.format(
                  AppConfiguration.dateFormat
                )}-${exportModel.to.format(AppConfiguration.dateFormat)}.csv`}></CSVLink>
              <Button
                color="primary"
                type="button"
                onClick={() => handleExport()}
                className={`ml-auto ${theme === "light" ? "bg-lblue no-border" : ""}`}
                disabled={showExportLoadingIndicator}>
                {showExportLoadingIndicator && <i className="fas fa-spinner fa-spin mr-2" />}
                <FormattedMessage id="reports.export.downloadButton" />
              </Button>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </Col>
  );
}

const getCsvData = (billingTransactionsDtos: BillingTransactionsDto[]) => {
  return billingTransactionsDtos.length > 0
    ? billingTransactionsDtos.map((x) => {
      return {
        ...x,
        transactionDate: getUtcDate(x.transactionDate).format(AppConfiguration.fullDateFormat)
      };
    })
    : [{ ...DefaultBillingTransactionsDto, transactionDate: "" }];
};
