import React, { useState, FormEvent, useContext } from "react";
import NumberTable from "./numberTable/NumberTable";
import {
  Container,
  Form,
  Input,
  Button,
  InputGroupAddon,
  InputGroupText,
  FormGroup,
  InputGroup,
  Row,
  Col
} from "reactstrap";
import { FormattedMessage, useIntl } from "react-intl";
import classNames from "classnames";
import { validateNumberInputValue } from "services/validators/ValidatePhoneNumbers";
import { ValidationResult } from "services/validators/ValidationResult";
import BreadcrumbHeader from "components/common/BreadcrumbHeader";
import { usePastedNumberFormatter } from "services/customHooks/usePastedNumberFormatter";
import { AppContext } from "services/appContext/AppContext";

export default function NumberExplorer() {
  const [searchInputValue, setSearchInputValue] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const [errorMessageId, setErrorMessageId] = useState("");
  const [focusedSearchInput, setFocusedSearchInput] = useState(false);
  const intl = useIntl();
  const { appContext } = useContext(AppContext);
  const theme = appContext.theme;
  const onPaste = usePastedNumberFormatter(searchInputValue, formattedValue =>
    handleSearchChange(formattedValue)
  );

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    const inputValue = searchInputValue.trim();
    const validationResult = validateNumberInputValue(inputValue);
    setErrorMessageId(errorMessageIdResults[validationResult.toString()]);

    if (validationResult === ValidationResult.Valid) {
      setSearchValue(inputValue);
    }
  };

  const handleSearchChange = (searchValue: string) => {
    setErrorMessageId("");
    setSearchInputValue(searchValue);
  };

  return (
    <>
      <BreadcrumbHeader>
        <FormattedMessage id="explore.title" />
      </BreadcrumbHeader>
      <Container className="mb-5">
        <Row>
          <Col md="6" className="offset-md-3">
            <h1 className={`display-3 ${theme === "light" ? "lblue-color" : "pc-number-explorer-bg"}`}>
              <FormattedMessage id="explore.title" />
            </h1>
          </Col>
        </Row>
        <Row>
          <Col md="6" className="offset-md-3">
            <Form onSubmit={handleSubmit}>
              <FormGroup
                className={classNames("input-group-merge pc-search-container mb-0", {
                  focused: focusedSearchInput
                })}>
                <InputGroup className="input-group-merge">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="fas fa-search" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    type="text"
                    className="pc-search"
                    placeholder={intl.formatMessage({ id: "explore.search.placeholder" })}
                    value={searchInputValue}
                    onChange={e => handleSearchChange(e.currentTarget.value)}
                    onFocus={() => setFocusedSearchInput(true)}
                    onBlur={() => setFocusedSearchInput(false)}
                    onPaste={onPaste}
                  />
                </InputGroup>
                <Button color={`${theme === "dark" ? "info" : ""}`} type="submit" className={`pc-btn ml-4 ${theme === "light" ? "bg-lblue" : ""}`}>
                  <FormattedMessage id="explore.search.button" />
                </Button>
              </FormGroup>
              {errorMessageId && (
                <>
                  <div className="text-red text-sm mt-1">
                    <FormattedMessage id={errorMessageId} />
                  </div>
                  <div className="text-red text-sm mt-1">
                    <FormattedMessage id="explore.search.validInputExample" />
                  </div>
                </>
              )}
            </Form>
          </Col>
        </Row>
      </Container>
      <Container fluid>
        <NumberTable telephoneNumber={searchValue} />
      </Container>
    </>
  );
}

const getDictionary = (): { [validationResult: string]: string } => {
  var errorMessageIdResults: { [validationResult: string]: string } = {};

  errorMessageIdResults[ValidationResult.Valid.toString()] = "";
  errorMessageIdResults[ValidationResult.NoInput.toString()] = "explore.search.emptySearch";
  errorMessageIdResults[ValidationResult.InvalidNumber.toString()] = "explore.search.invalidNumber";
  errorMessageIdResults[ValidationResult.InvalidRange.toString()] = "explore.search.invalidRange";

  return errorMessageIdResults;
};

const errorMessageIdResults = getDictionary();
