export enum OrderNumberActionType {
  Activate = "Activate",
  Modify = "Modify",
  RemoveFromConflict = "RemoveFromConflict",
  Cancel = "Cancel",
  Archive = "Archive",
  UndoCancel = "UndoCancel",
  PreOrderSupplement = "PreOrderSupplement",
  PreOrderApprove = "PreOrderApprove",
  PreOrderReject = "PreOrderReject",
  PreOrderSubmit = "PreOrderSubmit",
  CsrRequested = "CsrRequested",
  CsrValid = "CsrValid",
  CsrInvalid = "CsrInvalid",
  CsrModify = "CsrModify",
  CsrCancel = "CsrCancel",
  CsrEscalate = "CsrEscalate",
  LsrSubmit = "LsrSubmit",
  CsrReceived = "CsrReceived",
  CsrToLsr = "CsrToLsr",
  ScheduleActivation = "ScheduleActivation",
  ModifyActivation = "ModifyActivation",
  CancelActivation = "CancelActivation",
  Restore = "Restore"
}
