import React, { useContext, useMemo } from "react";
import ReactTable from "components/framework/table/ReactTable";
import { IntlShape, useIntl } from "react-intl";
import { getValueOrEmpty } from "services/util/StringUtil";
import FormatDateTime from "components/framework/date/FormatDateTime";
import { SvQueryDetailsDto } from "services/apis/types/query/SvQueryDetailsDto";
import SpIdCell from "components/common/SpIdCell";
import { getFormattedUtcDate } from "services/util/DateUtil";
import { Authorized } from "components/orders/types/Authorized";
import { AppContext } from "services/appContext/AppContext";

type Props = {
  numberDetails: SvQueryDetailsDto[];
};

export default function QueryDetailsTable(props: Props) {
  const { appContext } = useContext(AppContext);
  const theme = appContext.theme;
  const intl = useIntl();
  const columns = useMemo(() => getColumns(intl, theme), [intl, theme]);

  return (
    <div className="card-wrapper">
      <ReactTable
        title={intl.formatMessage({ id: "querySv.queryDetails.table.title" })}
        data={props.numberDetails}
        columns={columns}
        showExport={true}
        filterMapper={filterMapper}
      />
    </div>
  );
}

const getColumns = (intl: IntlShape, theme: string) => [
  {
    Header: intl.formatMessage({ id: "querySv.queryDetails.table.phoneNumber" }),
    accessor: "phoneNumber",
    Cell: (cell: any) => getValueOrEmpty(cell.cell.value)
  },
  {
    Header: intl.formatMessage({ id: "querySv.queryDetails.table.versionId" }),
    accessor: "versionId",
    Cell: (cell: any) => getValueOrEmpty(cell.cell.value)
  },
  {
    Header: intl.formatMessage({ id: "querySv.queryDetails.table.status" }),
    accessor: "status",
    Cell: (cell: any) => getValueOrEmpty(cell.cell.value)
  },
  {
    Header: intl.formatMessage({ id: "querySv.queryDetails.table.newSpId" }),
    accessor: "newSpId",
    Cell: (cell: any) => <SpIdCell spId={cell.cell.value} labelColor={`${theme === "light" ? "spid-bg-lgreen" : ""}`} identifier={"lgreen"} />
  },
  {
    Header: intl.formatMessage({ id: "querySv.queryDetails.table.oldSpId" }),
    accessor: "oldSpId",
    Cell: (cell: any) => <SpIdCell spId={cell.cell.value} labelColor={`${theme === "light" ? "bg-lblue" : ""}`} />
  },
  {
    Header: intl.formatMessage({ id: "querySv.queryDetails.table.newDueDate" }),
    accessor: "newDueDate",
    Cell: (cell: any) => cell.cell.value && <FormatDateTime utcDate={cell.cell.value} />
  },
  {
    Header: intl.formatMessage({ id: "querySv.queryDetails.table.oldDueDate" }),
    accessor: "oldDueDate",
    Cell: (cell: any) => cell.cell.value && <FormatDateTime utcDate={cell.cell.value} />
  },
  {
    Header: intl.formatMessage({ id: "querySv.queryDetails.table.authorized" }),
    accessor: "authorized",
    Cell: (cell: any) => (getValueOrEmpty(cell.cell.value) ? Authorized.YES : Authorized.NO)
  }
];

const filterMapper = (svQueryDetailsDto: SvQueryDetailsDto) => {
  return {
    ...svQueryDetailsDto,
    oldDueDate: getFormattedUtcDate(svQueryDetailsDto.oldDueDate),
    newDueDate: getFormattedUtcDate(svQueryDetailsDto.newDueDate)
  };
};
