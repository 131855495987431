import React, { FormEvent, useContext, useState } from "react";
import { Form, Button } from "reactstrap";
import { nameOf } from "services/util/ObjectUtil";
import { SignUpRequestDto } from "services/apis/types/account/SignUpRequestDto";
import { FormattedMessage, useIntl } from "react-intl";
import { splitCommaSeparatedList, isEmail, isSpid } from "services/util/StringUtil";
import { HasErrors, AddError, Errors } from "components/framework/errorHandling/ErrorUtil";
import { AccountApi } from "services/apis/AccountApi";
import { ApiError } from "services/apis/types/ApiError";
import { getFieldErrors } from "services/util/ApiUtil";
import { useErrors } from "services/customHooks/useErrors";
import { SignupModel, DefaultSignupModel } from "./SignupModel";
import { useIsMounted } from "services/customHooks/useIsMounted";
import CheckboxFormInput from "components/framework/forms/CheckboxFormInput";
import { isEmailFromFreeDomain } from "services/validators/ValidationUtil";
import { showErrorNotificationNonAuthenticated } from "components/framework/notification/NotificationUtil";
import { getTranslation } from "translations/TranslationService";
import TextFormInputGroup from "components/framework/forms/TextFormInputGroup";
import { AppContext } from "services/appContext/AppContext";

type Props = {
  onSignUpSuccessfull: () => void;
};

export default function SignupForm(props: Props) {
  const { setErrors, getErrorHandler } = useErrors();
  const [model, setModel] = useState<SignupModel>(DefaultSignupModel);
  const [showLoadingIndicator, setShowLoadingIndicator] = useState(false);
  const { appContext } = useContext(AppContext);
  const theme = appContext.theme;
  const isMounted = useIsMounted();
  const intl = useIntl();

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    setShowLoadingIndicator(true);

    const requestModel: SignUpRequestDto = {
      ...model,
      associatedSpid: splitCommaSeparatedList(model.associatedSpid),
      portControlSpidsAccess: splitCommaSeparatedList(model.portControlSpidsAccess)
    };

    const result = validate(requestModel);

    if (HasErrors(result.errors)) {
      setErrors(result.errors);
      if (isMounted.current) {
        setShowLoadingIndicator(false);

        if (result.notificationId)
          showErrorNotificationNonAuthenticated(getTranslation(intl, result.notificationId));
      }
    } else {
      AccountApi.signup(requestModel)
        .then(() => {
          props.onSignUpSuccessfull();
        })
        .catch((error: ApiError) => {
          if (isMounted.current) {
            const errorsResult = getFieldErrors(error.fieldErrors);
            setErrors(errorsResult);
            showErrorNotificationNonAuthenticated(error.message!);
          }
        })
        .finally(() => {
          if (isMounted.current) {
            setShowLoadingIndicator(false);
          }
        });
    }
  };

  return (
    <>
      <div className="text-center text-muted mb-4">
        <small className={`font-weight-bold ${theme === "light" ? "lblue-color" : ""}`}>
          <FormattedMessage id="account.signup.title" />
        </small>
      </div>
      <Form role="form" autoComplete="off" onSubmit={handleSubmit}>
        <TextFormInputGroup
          icon=""
          placeholderTranslationId="account.signup.name"
          value={model.name}
          handleInputChange={(value: string) => setModel({ ...model, name: value })}
          errorHandler={getErrorHandler(nameOf<SignUpRequestDto>("name"))}
        />
        <TextFormInputGroup
          icon=""
          placeholderTranslationId="account.signup.email"
          value={model.email}
          handleInputChange={(value: string) => setModel({ ...model, email: value })}
          errorHandler={getErrorHandler(nameOf<SignUpRequestDto>("email"))}
        />
        <TextFormInputGroup
          icon=""
          placeholderTranslationId="account.signup.phoneNumber"
          value={model.phoneNumber}
          handleInputChange={(value: string) => setModel({ ...model, phoneNumber: value })}
          errorHandler={getErrorHandler(nameOf<SignUpRequestDto>("phoneNumber"))}
        />
        <TextFormInputGroup
          icon=""
          placeholderTranslationId="account.signup.companyName"
          value={model.companyName}
          handleInputChange={(value: string) => setModel({ ...model, companyName: value })}
          errorHandler={getErrorHandler(nameOf<SignUpRequestDto>("companyName"))}
        />
        <TextFormInputGroup
          icon=""
          placeholderTranslationId="account.signup.associatedSpid"
          value={model.associatedSpid}
          handleInputChange={(value: string) => setModel({ ...model, associatedSpid: value })}
          errorHandler={getErrorHandler(nameOf<SignUpRequestDto>("associatedSpid"))}
        />
        <TextFormInputGroup
          icon=""
          placeholderTranslationId="account.signup.portControlSpidsAccess"
          value={model.portControlSpidsAccess}
          handleInputChange={(value: string) =>
            setModel({ ...model, portControlSpidsAccess: value })
          }
          errorHandler={getErrorHandler(nameOf<SignUpRequestDto>("portControlSpidsAccess"))}
        />
        <TextFormInputGroup
          type="password"
          icon="fas fa-unlock-alt"
          placeholderTranslationId="account.signup.password"
          value={model.password}
          handleInputChange={(value: string) =>
            setModel({ ...model, password: value })
          }
          errorHandler={getErrorHandler(nameOf<SignUpRequestDto>("password"))}
        />
        <TextFormInputGroup
          type="password"
          icon="fas fa-unlock-alt"
          placeholderTranslationId="account.signup.confirmPassword"
          value={model.confirmPassword}
          handleInputChange={(value: string) =>
            setModel({ ...model, confirmPassword: value })
          }
          errorHandler={getErrorHandler(nameOf<SignUpRequestDto>("confirmPassword"))}
        />
        <CheckboxFormInput
          label={intl.formatMessage({ id: "account.signup.licenseAgreement" })}
          className={`${theme === "light" ? "purple-color" : ""}`}
          key={""}
          value={""}
          checked={model.licenseAgreed}
          handleInputChange={(value: boolean) =>
            setModel({
              ...model,
              licenseAgreed: value
            })
          }
        >
          <a className={`pl-1 ${theme === "light" ? "purple-color" : ""}`} href="https://atlc.com/portcontrol-end-user-license-agreement/" target="_blank" rel="noopener noreferrer">
            <strong>{intl.formatMessage({ id: "account.signup.licenseAgreementLinkTitle" })}</strong>
          </a>
        </CheckboxFormInput>

        <div className="text-center">
          <Button className={`mt-4 ${theme === "light" ? "bg-lblue border-0" : ""}`} color="default" type="submit" disabled={showLoadingIndicator || !model.licenseAgreed}>
            {showLoadingIndicator && <i className="fas fa-spinner fa-spin mr-2" />}
            <FormattedMessage id="account.signup.submit" />
          </Button>
        </div>
      </Form>
    </>
  );
}

const validate = (model: SignUpRequestDto) => {
  const errors: Errors = {};
  let notificationId;

  if (!model.email) {
    AddError(errors, nameOf<SignUpRequestDto>("email"), "account.signup.email.required");
  } else if (!isEmail(model.email)) {
    AddError(errors, nameOf<SignUpRequestDto>("email"), "account.signup.email.invalid");
  } else if (isEmailFromFreeDomain(model.email)) {
    AddError(errors, nameOf<SignUpRequestDto>("email"), "account.signup.email.freeDomain");
    notificationId = "account.signup.email.freeDomainDescription";
  }

  if (!model.name) {
    AddError(errors, nameOf<SignUpRequestDto>("name"), "account.signup.name.required");
  }

  if (!model.companyName) {
    AddError(
      errors,
      nameOf<SignUpRequestDto>("companyName"),
      "account.signup.companyName.required"
    );
  }

  if (!model.phoneNumber) {
    AddError(
      errors,
      nameOf<SignUpRequestDto>("phoneNumber"),
      "account.signup.phoneNumber.required"
    );
  }

  if (!model.associatedSpid.length) {
    AddError(
      errors,
      nameOf<SignUpRequestDto>("associatedSpid"),
      "account.signup.associatedSpid.required"
    );
  } else if (model.associatedSpid.filter((x) => !isSpid(x)).length) {
    AddError(
      errors,
      nameOf<SignUpRequestDto>("associatedSpid"),
      "account.signup.associatedSpid.invalid"
    );
  }

  if (!model.portControlSpidsAccess.length) {
    AddError(
      errors,
      nameOf<SignUpRequestDto>("portControlSpidsAccess"),
      "account.signup.portControlSpidsAccess.required"
    );
  } else if (model.portControlSpidsAccess.filter((x) => !isSpid(x)).length) {
    AddError(
      errors,
      nameOf<SignUpRequestDto>("portControlSpidsAccess"),
      "account.signup.portControlSpidsAccess.invalid"
    );
  }

  if (model.password.length === 0) {
    AddError(errors, nameOf<SignUpRequestDto>("password"), "myProfile.changePassword.emptyNewPassword");
  }

  if (model.confirmPassword.length === 0) {
    AddError(errors, nameOf<SignUpRequestDto>("confirmPassword"), "myProfile.changePassword.emptyNewPassword");
  }

  if (
    model.password &&
    model.confirmPassword &&
    model.password !== model.confirmPassword
  ) {
    AddError(errors, nameOf<SignUpRequestDto>("password"), "myProfile.changePassword.passwordsDoNotMatch");
  }

  return { errors, notificationId };
};
