import React, { useContext, useState } from "react";
import DropdownInput from "../forms/DropdownInput";
import IconWithTooltip from "components/common/IconWithTooltip";
import { getTranslation } from "translations/TranslationService";
import { useIntl } from "react-intl";
import { Button, Col, Row } from "reactstrap";
import DateTimeInput from "../forms/DateTimeInput";
import Icon from "components/common/Icon";
import moment, { Moment } from "moment";
import TextFormInput from "../forms/TextFormInput";
import { AppContext } from "services/appContext/AppContext";

type Props = {
  options: any;
  onSearch: any; 
};

export default function TopColumnFilter(props: Props) {
  const intl = useIntl();
  const [statusValue, setStatusValue] = useState('');
  const [fromDate, setFromDate] = useState<Moment | null>(null);
  const [toDate, setToDate] = useState<Moment | null>(null);
  const [orderIdValue, setOrderIdValue] = useState('');
  const { appContext } = useContext(AppContext);
  const theme = appContext.theme;

  const handleSearch = () => {
    const adjustedFromDate = fromDate ? moment(fromDate).startOf('day').format('YYYY-MM-DD HH:mm:ss') : null;
    const adjustedToDate = toDate ? moment(toDate).endOf('day').format('YYYY-MM-DD HH:mm:ss') : null;

    const filterData = {
      orderIdValue,
      statusValue,
      fromDate: adjustedFromDate,
      toDate: adjustedToDate,
    };

    // Send data to another component
    props.onSearch(filterData);
  };

  const handleReset = () => {
    setOrderIdValue('')
    setStatusValue('');
    setFromDate(null);
    setToDate(null);
    // Optionally trigger a search with reset values
    props.onSearch({
      orderIdValue: '',
      statusValue: '',
      fromDate: null,
      toDate: null,
    });
  };

  return (
    <>
      <div className="filter-container">
        <Row className="d-flex align-items-center w-100 filter-row">
          <Col md="1" className="d-flex align-items-center no-gutters">
            <Row className="d-flex align-items-center">
              <Col md="3" className="d-flex align-items-center">
                <IconWithTooltip
                  className={`fas fa-filter ${theme === "light" ? "lblue-color" : "icon-filter"}`}
                  tooltip={getTranslation(intl, "table.filters")}
                />
              </Col>
              <Col md="3" className="d-flex align-items-center mt-2">
                <h4 className={`${theme === "light" ? "lblue-color" : ""}`}>
                {getTranslation(
                    intl,
                    "table.filters"
                  )}
                </h4>
              </Col>
            </Row>
          </Col>
          <Col className="d-flex align-items-center no-gutters">
            <TextFormInput
              formGroupClassName="col-lg-12 mt-2"
              placeholderTranslationId="Order Id"
              value={orderIdValue}
              inputClassName={`${theme === "light" ? "table-filters-dark" : ""}`}
              handleInputChange={(value: string) =>
                setOrderIdValue(value.trim())
              }
            />
          </Col>
          <Col md="2">
            <DropdownInput
              sm={true}
              onChange={(e) => setStatusValue(e.target.value)}
              value={statusValue}
              options={props.options}
              className={`${theme === "light" ? "table-filters-dark" : ""}`}
            />
          </Col>
          <Col md="2" className="no-gutters">
            <DateTimeInput
              className={`mr-2 ${theme === "light" ? "table-filters-dark" : ""}`}
              value={fromDate}
              onChange={(val) => setFromDate(val ? moment(val) : null)}
              placeholder="From Date"
              showCalendar={true}
              iconColor={`${theme === "light" ? "lblue-color" : ""}`}
            />
          </Col>
          <Col md="2" className="no-gutters ml-3">
            <DateTimeInput
              className={`mr-2 ${theme === "light" ? "table-filters-dark" : ""}`}
              value={toDate}
              onChange={(val) => setToDate(val ? moment(val) : null)}
              placeholder="To Date"
              showCalendar={true}
              iconColor={`${theme === "light" ? "lblue-color" : ""}`}
            />
          </Col>
          <Col md="1">
            <Button type="submit" className={`pc-btn-logs ${theme === "light" ? "bg-lblue" : ""}`} onClick={handleSearch}>
              <Icon className={"fas fa-search"} title={""} />
            </Button>
          </Col>
          <Col md="2" className="no-gutters">
            { (statusValue || fromDate || toDate || orderIdValue) && (
              <Button type="button" className={`pc-btn-logs ${theme === "light" ? "bg-lblue" : ""}`} onClick={handleReset}>
                <Icon className={"fas fa-times"} title={"Reset"} />
              </Button>
            )}
          </Col>
        </Row>
      </div>
    </>
  );
}