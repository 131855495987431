import React, { useState, FormEvent, useContext } from "react";
import classnames from "classnames";
import {
  Button,
  Card,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
  Alert
} from "reactstrap";
import { FormattedMessage, useIntl } from "react-intl";
import { Link } from "react-router-dom";
import LoginLayout from "components/framework/loginLayout/LoginLayout";
import { IdentityApi } from "services/apis/IdentityApi";
import { AddError, HasErrors, Errors } from "components/framework/errorHandling/ErrorUtil";
import InputError from "components/framework/errorHandling/InputError";
import { EnvironmentUtil } from "services/util/EnvironmentUtil";
import LoginHeader from "components/framework/loginLayout/LoginHeader";
import { AppContext } from "services/appContext/AppContext";

export default function ForgotPassword() {
  const [email, setEmail] = useState("");
  const [focusedEmail, setFocusedEmail] = useState(false);
  const [showError, setShowError] = useState(false);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [showLoadingIndicator, setShowLoadingIndicator] = useState(false);
  const [errors, setErrors] = useState<Errors>({});
  const { appContext } = useContext(AppContext);
  const theme = appContext.theme;

  const intl = useIntl();

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();

    const errors = validateModel(email);

    if (HasErrors(errors)) {
      setErrors(errors);
    } else {
      setShowLoadingIndicator(true);

      IdentityApi.requestPasswordReset({ email, callbackUrl: EnvironmentUtil.frontendUrl("ResetPassword?token=") })
        .then(() => setShowSuccessMessage(true))
        .catch(() => setShowError(true))
        .finally(() => setShowLoadingIndicator(false));
    }
  };

  return (
    <LoginLayout>
      <LoginHeader title={intl.formatMessage({ id: "account.forgotPassword.header" })} />
      <Container className="mt--8 pb-5">
        <Row className="justify-content-center">
          <Col lg="5" md="7">
            <Alert color="danger" isOpen={showError} toggle={() => setShowError(false)}>
              <span className="alert-text ml-1">
                <strong>
                  <FormattedMessage id="account.forgotPassword.error" />
                </strong>
              </span>
            </Alert>
            <Alert color="info" isOpen={showSuccessMessage} toggle={() => setShowSuccessMessage(false)}>
              <span className="alert-text ml-1">
                <strong>
                  <FormattedMessage id="account.forgotPassword.successMessage" values={{ email: email }} />
                </strong>
              </span>
            </Alert>

            <Card className="bg-secondary border-0 mb-0">
              <CardBody className="px-lg-5 py-lg-5">
                <div className="text-center text-muted mb-4">
                  <small className={`${theme === "light" ? "lblue-color" : ""}`}>
                    <FormattedMessage id="account.forgotPassword.title" />
                  </small>
                </div>
                <Form role="form" onSubmit={handleSubmit}>
                  <FormGroup
                    className={classnames("mb-3", {
                      focused: focusedEmail
                    })}>
                    <InputGroup className="input-group-merge input-group-alternative">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="fas fa-envelope" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        placeholder={intl.formatMessage({ id: "account.forgotPassword.email" })}
                        type="email"
                        onFocus={() => setFocusedEmail(true)}
                        onBlur={() => setFocusedEmail(false)}
                        value={email}
                        onChange={e => {
                          setEmail(e.target.value);
                          setErrors({ ...errors, email: [] });
                        }}
                      />
                    </InputGroup>
                    <InputError errors={errors["email"]} />
                  </FormGroup>
                  <div className="text-center">
                    <Button className={`mt-4 ${theme === "light" ? "bg-lblue border-0" : ""}`} color="primary" type="submit">
                      {showLoadingIndicator && <i className="fas fa-spinner fa-spin mr-2" />}
                      <FormattedMessage id="account.forgotPassword.resetPassword" />
                    </Button>
                  </div>
                </Form>
              </CardBody>
            </Card>
            <Row className="mt-3">
              <Col xs="6">
                <small>
                  <Link to="/Login" className={`${theme === "light" ? "purple-color" : "text-light"}`}>
                    <FormattedMessage id="account.forgotPassword.goBack" />
                  </Link>
                </small>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </LoginLayout>
  );
}

const validateModel = (email: string): Errors => {
  const errors: Errors = {};

  if (email.length === 0) {
    AddError(errors, "email", "account.forgotPassword.email.emptyError");
  }

  return errors;
};
