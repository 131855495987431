import React, { useState } from "react";
import { DnoApi } from './../../services/apis/DnoApi';
import { showInfoNotification } from "components/framework/notification/NotificationUtil";
import { useIntl } from "react-intl";
import { handleError } from "services/util/ApiUtil";
type Props = {
  data: any;
};
export default function ToggleCheckbox(props: Props) {
  const intl = useIntl();
  const [isChecked, setIschecked] = useState(props.data.dno);
  const handleInputChange = (event) => {
    let number = new Array(props.data.phoneNumber);
    setIschecked(event);
    DnoApi.setDnoFlag(number, event)
      .then(() => {
        showInfoNotification(
          intl.formatMessage({ id: "somos.number.update.dno" })
        );
      })
      .catch((error) => {
        handleError(error);
      })
  }
  let displayStyle = isChecked ? "btn-success" : "btn-text-switc-off";
  return (
    <label>
      <span className="small switch-wrapper">
        <input
          type="checkbox"
          onChange={(e) => handleInputChange(e.currentTarget.checked)}
          checked={isChecked}
        />
        <span className={`${displayStyle} switch text-left`}>
          <span className="switch-handle" /></span>
      </span>
    </label>
  );
}
