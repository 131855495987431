import React, { useContext, useState } from "react";
import { Modal, Button } from "reactstrap";
import { FormattedMessage, useIntl } from "react-intl";
import CloseButton from "components/framework/modals/CloseButton";
import { CustomerInventoryApi } from "services/apis/CustomerInventoryApi";
import { handleError } from "services/util/ApiUtil";
import { showInfoNotification } from "components/framework/notification/NotificationUtil";
import { AppContext } from "services/appContext/AppContext";

type Props = {
  customerName: string;
  customerId: string;
  activation: boolean;
  closeModal: () => void;
  onUpdateToggle:(boolean) => void;
};

export default function ActivateDeactivateModal(props: Props) {
  const { appContext } = useContext(AppContext);
  const theme = appContext.theme;

  const intl = useIntl();
  const [showLoadingIndicator, setShowLoadingIndicator] = useState(false);

  const activateDeactivateAction = () => {
    setShowLoadingIndicator(true);
    CustomerInventoryApi.manageCustomer(props.customerId, !props.activation)
    .then((result) => {
      if(result) {
        showInfoNotification(intl.formatMessage({ id: "customerInventory.activation.confirm.message" }));
        props.onUpdateToggle(!props.activation);
      }
    })
    .catch((error) => handleError(error))
    .finally(() => {
      setShowLoadingIndicator(false);
      props.closeModal();
    });
  }

  return (
    <Modal className="modal-dialog-centered modal-sm" isOpen={true} toggle={props.closeModal}>
      <div className="modal-header pb-0">
        <h4 className="modal-title" id="modal-title-default">
          <FormattedMessage id={"customerInventory.activation.confirm"} />
        </h4>
        <CloseButton close={props.closeModal} />
      </div>
      <div className="modal-body pb-0">
        <FormattedMessage
          id={!props.activation ? "customerInventory.activation.message" : "customerInventory.deactivation.message"}
          values={{name: props.customerName}}
        />
      </div>
      <div className="modal-footer">
        <Button color="link" type="button" className={`${theme === "light" ? "lblue-color" : ""}`} onClick={() => props.closeModal()}>
            <FormattedMessage id="orders.modify.edit.cancelButton"/>
        </Button>
        <Button
            color="primary"
            onClick={activateDeactivateAction}
            className={`ml-auto ${theme === "light" ? "bg-lblue no-border" : ""}`}
            disabled={showLoadingIndicator}>
            {showLoadingIndicator && <i className="fas fa-spinner fa-spin mr-2" />}
            <FormattedMessage id="orders.modify.edit.submitButton" />
        </Button>
        </div>
    </Modal>
  );
}
