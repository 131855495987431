import React, { useState, useEffect } from "react";
import { OrderListDto } from "services/apis/types/order/OrderListDto";
import { OrderNumberActionConfigurations } from "components/orders/orderDetails/configuration/OrderNumberActionConfigurations";
import { useIsMounted } from "services/customHooks/useIsMounted";
import ExecuteOrderAction from "components/orders/orderDetails/ExecuteOrderAction";
import { OrderDto, DefaultOrder } from "services/apis/types/order/OrderDto";
import { OrderApi } from "services/apis/OrderApi";
import { handleError } from "services/util/ApiUtil";
import { OrderNumberActionType } from "services/portControl/types/OrderNumberActionType";
import Icon from "components/common/Icon";

type Props = {
  order: OrderListDto;
  onActionExecuted: () => void;
  bulkActionSelected?: boolean;
  bulkActionOrderId?: string[];
};
export const OrderActionCell = (props: Props) => {
  const [order, setOrder] = useState<OrderDto>();
  const [selectedAction, setSelectedAction] = useState<OrderNumberActionType>();
  const isMounted = useIsMounted();
  const excludedActions = ["ScheduleActivation", "ModifyActivation", "CancelActivation"]
  const availableActions = props.order.availableActions ?  props.order.availableActions?.filter(action => !excludedActions.includes(action)) : [];

  useEffect(() => {
    if (selectedAction) {
      if (selectedAction === OrderNumberActionType.Modify) {
        OrderApi.get(props.order.orderId, props.order.tenant)
          .then((result) => {
            if (isMounted.current) {
              setOrder(result);
            }
          })
          .catch((error) => handleError(error));
      } else {
        setOrder({ ...DefaultOrder, orderId: props.order.orderId, tenant: props.order.tenant });
      }
    } else {
      setOrder(undefined);
    }
  }, [selectedAction, isMounted, props.order.orderId, props.order.tenant]);

  return (
    <>
      {props.bulkActionSelected && props.order.availableActions && props.bulkActionOrderId && props.bulkActionOrderId.includes(props.order.orderId) && props.order.availableActions.length ? (
        <i className="fas fa-spinner fa-spin"/>
        ) : props.order.availableActions &&
        availableActions.map((action) => (
          <span key={action}>
            {OrderNumberActionConfigurations[action] && (
              <span>
                {selectedAction === action && order && (
                  <ExecuteOrderAction
                    order={order}
                    action={action}
                    confirmationMessageId={
                      OrderNumberActionConfigurations[action].Order.confirmMessageId
                    }
                    skipConfirmation={
                      OrderNumberActionConfigurations[action].Order.skipConfirmation
                    }
                    closeModal={() => setSelectedAction(undefined)}
                    onActionExecuted={props.onActionExecuted}/>
                )}
                <Icon
                  className={`${OrderNumberActionConfigurations[action].Number.iconClass} mr-2 cursor-pointer`}
                  title={action}
                  onClick={() => setSelectedAction(action)}
                />
              </span>
            )}
          </span>
        ))}
    </>
  );
};
