import React from "react";
import { Link } from "react-router-dom";
import { OrderListDto } from "services/apis/types/order/OrderListDto";
import { OrderType } from "services/apis/types/order/OrderType";

type Props = {
  urlRoutePath: string;
  order: OrderListDto;
  tenant: string;
  value: string;
}

export default function OrderLink(props: Props) {
  const isDisasterRecovery = props.order.orderType === OrderType.DisasterRecovery;
  const isRestore = props.order.orderType === OrderType.Restore;

  return (
    <span className="pc-text-link">
        <Link to={`${props.urlRoutePath}${isDisasterRecovery || isRestore ? "/DisasterRecovery" : ""}/${isDisasterRecovery ? props.order.adminProjectId : props.order.orderId}`}>  {props.value ? props.value : props.order.orderId}</Link>
    </span>
  )
}