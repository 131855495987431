import React, { useContext, useState } from "react";
import BreadcrumbHeader from "components/common/BreadcrumbHeader";
import { FormattedMessage } from "react-intl";
import {
  Container,
  Row,
  Col,
  Dropdown,
  DropdownToggle,
  DropdownMenu
} from "reactstrap";
import Authorize from "components/framework/authorization/Authorize";
import { PermissionType } from "services/authorization/PermissionType";
import NotificationTable from "./NotificationTable";
import NotificationModal from "./NotificationModal";
import { ManageAction } from "../types/ManageAction";
import { NotificationType } from "../types/NotificationType";
import { AppContext } from "services/appContext/AppContext";

type NotificationContext = {
  notificationCallback: () => void;
};

export const NotificationContext = React.createContext<NotificationContext>({
  notificationCallback: () => {}
});

export default function NotificationList() {
  const [showDropdown, setShowDropdown] = useState(false);
  const [triggerRefresh, setTriggerRefresh] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [notificationType, setNotificationType] = useState<NotificationType>();
  const { appContext } = useContext(AppContext);
  const theme = appContext.theme;

  const dropdownToggle = () => setShowDropdown((prevState) => !prevState);

  const handleCreateDropdownItemButtonClick = (notificationType: NotificationType) => {
    setShowModal(true);
    setShowDropdown(false);
    setNotificationType(notificationType);
  };

  const notificationContext: NotificationContext = {
    notificationCallback: () => {
      setTriggerRefresh((prev) => !prev);
    }
  };

  return (
    <NotificationContext.Provider value={notificationContext}>
      <BreadcrumbHeader>
        <FormattedMessage id="notifications.title" />
      </BreadcrumbHeader>
      <Container className="mb-5">
        <Row className="align-items-center">
          <Col className="offset-md-3">
            <h1 className={`display-3 ${theme === "light" ? "lblue-color" : "text-blue"}`}>
              <FormattedMessage id="notifications.title" />
            </h1>
          </Col>
          <Col>
            <Authorize userPermissions={[PermissionType.Webhooks]}>
              <div className="pc-create-dropdown">
                <Dropdown isOpen={showDropdown} toggle={dropdownToggle}>
                  <DropdownToggle className="cursor-pointer pb-0 pt-0v" tag="span">
                    <button className={`btn ${theme === "light" ? "bg-lblue text-white" : "pc-btn-audit"}`}>
                      <i className="fas fa-plus-square mr-2" />
                      <FormattedMessage id="notifications.create" />
                    </button>
                  </DropdownToggle>
                  <DropdownMenu className={`dropdown-menu-lg dropdown-menu-white ${theme === "light" ? "bg-dropdown text-crow" : "bg-dark text-white pc-no-shadow"}`}>
                    <Row className="shortcuts px-4">
                      <Col
                        className="shortcut-item cursor-pointer"
                        onClick={() =>
                          handleCreateDropdownItemButtonClick(NotificationType.Webhook)
                        }
                        xs="4">
                        <span className="shortcut-media avatar rounded-circle bg-gradient-pc-blue">
                          <i className="fa fa-check-double" />
                        </span>
                        <small>
                          <FormattedMessage id="notifications.create.webhook" />
                        </small>
                      </Col>
                      <Col
                        className="shortcut-item cursor-pointer"
                        onClick={() =>
                          handleCreateDropdownItemButtonClick(NotificationType.EmailSubscription)
                        }
                        xs="4">
                        <span className="shortcut-media avatar rounded-circle bg-gradient-pc-blue">
                          <i className="ni ni-email-83" />
                        </span>
                        <small>
                          <FormattedMessage id="notifications.create.emailSubscription" />
                        </small>
                      </Col>
                    </Row>
                  </DropdownMenu>
                </Dropdown>
              </div>
            </Authorize>
          </Col>
        </Row>
      </Container>
      <Container>
        <NotificationTable triggerRefresh={triggerRefresh} searchValue={""} />
        {showModal && notificationType && (
          <NotificationModal
            manageAction={ManageAction.Add}
            notificationType={notificationType}
            closeModal={() => setShowModal(false)}
          />
        )}
      </Container>
    </NotificationContext.Provider>
  );
}
