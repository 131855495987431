export enum OrderRequestType {
  OrderOnly = "OrderOnly",
  PreOrderAndOrder = "PreOrderAndOrder",
  CSROnly = "CSROnly",
  DisasterRecovery = "DisasterRecovery"
}

export const OrderRequestTypes: Record<keyof typeof OrderRequestType, string> = {
  OrderOnly: "Order only",
  PreOrderAndOrder: "PreOrder & Order",
  CSROnly: "CSR Only",
  DisasterRecovery: "DisasterRecovery"
};

export enum NewOrderRequestType {
  OrderOnly = "OrderOnly",
  PreOrderAndOrder = "PreOrderAndOrder",
  CSROnly = "CSROnly"
}

export const NewOrderRequestTypes: Record<keyof typeof NewOrderRequestType, string> = {
  OrderOnly: "Order only",
  PreOrderAndOrder: "PreOrder & Order",
  CSROnly: "CSR Only"
};
