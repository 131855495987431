import React, { useContext } from "react";
import { AppContext } from "services/appContext/AppContext";
import { EnvironmentUtil } from "services/util/EnvironmentUtil";

const isMixNetworkUrl = EnvironmentUtil.isMixNetwork

export default function FeedbackButton() {
  const { appContext } = useContext(AppContext);
  const theme = appContext.theme;

  return (
    <span className="btn-inner--icon">
      <a href={isMixNetworkUrl ? "mailto:support@mixnetworks.com" : "mailto:lnp@atlc.com"} className="btn btn-primary btn-icon-only rounded-circle">
        <i className={`fas fa-envelope ${theme === "light" ? "text-xs" : ""}`}></i>
      </a>
    </span>
  );
}
