import React, { useContext } from "react";
import LoginNavbar from "./LoginNavbar";
import Footer from "components/framework/footer/Footer";
import { AppContext } from "services/appContext/AppContext";

type Props = {
  children: React.ReactNode;
};

export default function LoginLayout(props: Props) {
  const { appContext } = useContext(AppContext);
  const theme = appContext.theme;

  return (
    <div className={`main-content ${theme === "light" ? "bg-lcolor" : ""}`}>
      <LoginNavbar />
      {props.children}
      <Footer />
    </div>
  );
}
