import React, { useContext, useEffect, useState } from "react";
import AdminNavbar from "./AdminNavbar";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Footer from "components/framework/footer/Footer";
import Sidebar from "components/framework/sidebar/Sidebar";
import { BodyClassUtil } from "services/util/BodyClassUtil";
import { hasAnyPermissions } from "services/authorization/AuthorizationService";
import { AppContext } from "services/appContext/AppContext";

type Props = {
  children: React.ReactNode;
};

export default function AdminLayout(props: Props) {
  const [sidenavOpen, setSidenavOpen] = useState(true);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [isManualToggle, setIsManualToggle] = useState(false);
  const { appContext } = useContext(AppContext);
  const theme = appContext.theme;

  const toggleSidenav = () => {
    setIsManualToggle(!isManualToggle);
    if (BodyClassUtil.hasClass("g-sidenav-pinned")) {
      BodyClassUtil.removeClass("g-sidenav-pinned");
      BodyClassUtil.addClass("g-sidenav-hidden");
    } else {
      BodyClassUtil.addClass("g-sidenav-pinned");
      BodyClassUtil.removeClass("g-sidenav-hidden");
    }

    setSidenavOpen(!sidenavOpen);
  };

  useEffect(() => {
    const handleResize = () => {
      if (!isManualToggle) {
        if (window.innerWidth > 1500) {
          BodyClassUtil.addClass("g-sidenav-pinned");
          BodyClassUtil.removeClass("g-sidenav-hidden");
        } else {
          BodyClassUtil.removeClass("g-sidenav-pinned");
          BodyClassUtil.addClass("g-sidenav-hidden");
        }
      }
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener('resize', handleResize);
    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [isManualToggle]);

  return (
    <>
      <ToastContainer />
      {hasAnyPermissions() && <Sidebar windowWidth={windowWidth} sidenavOpen={sidenavOpen} toggleSidenav={toggleSidenav} />}
      <div className={theme === "light"? "main-content bg-lcolor" : "main-content bg-dark"}>
        <AdminNavbar sidenavOpen={sidenavOpen} toggleSidenav={toggleSidenav} />
        <div className="mb-2">{props.children}</div>
        <Footer />
      </div>
    </>
  );
}
