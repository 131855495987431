import React, { useContext } from 'react';
import { AppContext } from 'services/appContext/AppContext';

export default function VerticalLoader() {
  const { appContext } = useContext(AppContext);
  const theme = appContext.theme;

  return (
    <>
      <div className="loading-wave">
        <div className={`loading-bar ${theme === "light" ? "bg-lblue" : ""}`}></div>
        <div className={`loading-bar ${theme === "light" ? "bg-lblue" : ""}`}></div>
        <div className={`loading-bar ${theme === "light" ? "bg-lblue" : ""}`}></div>
        <div className={`loading-bar ${theme === "light" ? "bg-lblue" : ""}`}></div>
      </div>
    </>
  )
}