import React, { useState, useEffect, FormEvent, useRef, useContext } from "react";
import { CardFooter, Card, Form, Button, Label } from "reactstrap";
import { FormattedMessage, useIntl } from "react-intl";
import { useErrors } from "services/customHooks/useErrors";
import { nameOf } from "services/util/ObjectUtil";
import { HasErrors, Errors, AddError } from "components/framework/errorHandling/ErrorUtil";
import { ServiceProviderApi } from "services/apis/ServiceProviderApi";
import { showInfoNotification } from "components/framework/notification/NotificationUtil";
import TextFormInput from "components/framework/forms/TextFormInput";
import { ServiceProviderProfile } from "services/apis/types/serviceProvider/ServiceProviderProfile";
import { authorize } from "services/authorization/AuthorizationService";
import { ServiceProviderProfileResult } from "../types/ServiceProviderProfileResult";
import { isPhoneNumberRegex, isEmail, isEmailOrUrl, isValidResidentialOrBusinessRule } from "services/util/StringUtil";
import { handleError, getFieldErrors, handleFieldOrApiErrors } from "services/util/ApiUtil";
import { PermissionType } from "services/authorization/PermissionType";
import LnpInformation from "./LnpInformation";
import OperationsInformation from "./OperationsInformation";
import ContactInformation from "./ContactInformation";
import TextAreaFormInput from "components/framework/forms/TextAreaFormInput";
import TradingPartnerProfileCardFooterButtons from "./TradingPartnerProfileCardFooterButtons";
import useTpp from "services/customHooks/useTpp";
import DateTimeFormInput from "components/framework/forms/DateTimeFormInput";
import moment, { Moment } from "moment";
import TradingPartnerProfileCardHeader from "./TradingPartnerProfileCardHeader";
import { ProcessingTimeType } from "../types/ProcessingTimeType";
import { getUtcDate } from "services/util/DateUtil";
import CheckboxFormInput from "components/framework/forms/CheckboxFormInput";
import { getUserSpId } from "services/util/SpidUtil";
import { AppContext } from "services/appContext/AppContext";

type Props = {
  serviceProviderResult: ServiceProviderProfileResult;
  cardIsUsedInModal?: boolean;
  closeModalOnCancel?: () => void;
  fetchServiceProvider: () => void;
  isTpp?: boolean;
  onActionExecuted?: () => void;
};

export default function TradingPartnerProfileCard(props: Props) {
  const { setErrors, getErrorHandler } = useErrors();
  const [serviceProvider, setServiceProvider] = useState<ServiceProviderProfile>({
    ...props.serviceProviderResult.contactInformation,
    lnpSpeed:
      props.serviceProviderResult.contactInformation?.lnpSpeed ?? ProcessingTimeType.Instant,
    spId: props.serviceProviderResult.spId,
    external: props.serviceProviderResult.isVpop
  });
  const [showLoadingIndicator, setShowLoadingIndicator] = useState(false);
  const [readOnlyMode, setReadOnlyMode] = useState(true);
  const [createMode, setCreateMode] = useState(false);
  const [triggerRefreshLogo, setTriggerRefreshLogo] = useState(false);
  const [validationChanged, setValidationChanged] = useState(false);
  const isMounted = useRef(true);
  const intl = useIntl();
  const [userIsAdmin] = useState(authorize(PermissionType.Admin));
  const { tpp, fetchTpp } = useTpp(props.serviceProviderResult.spId);
  const [userHasAccess] = useState(authorize(PermissionType.NetworkWrite) &&  (props.serviceProviderResult.spId === getUserSpId()));
  const { appContext } = useContext(AppContext);
  const theme = appContext.theme;

  const editMode = !readOnlyMode && !createMode;

  useEffect(() => {
    setErrors({});
  }, [validationChanged, setErrors]);

  useEffect(() => {
    if (!props.serviceProviderResult.spId) {
      setCreateMode(true);
      setReadOnlyMode(false);
    }

    return () => {
      isMounted.current = false;
    };
  }, [props.serviceProviderResult]);

  const handleSubmit = (e: FormEvent) => {
    if (userIsAdmin || userHasAccess) {
      e.preventDefault();
      setShowLoadingIndicator(true);
      const errors = validateServiceProvideProfile(serviceProvider, createMode);
      if (HasErrors(errors)) {
        setErrors(errors);
        if (isMounted.current) {
          setShowLoadingIndicator(false);
        }
      } else {
        ServiceProviderApi.addOrUpdate(serviceProvider)
          .then(() => {
            showInfoNotification(intl.formatMessage({ id: "tppModal.successNotificationMessage" }));
            props.fetchServiceProvider();
            setReadOnlyMode(true);
          })
          .catch((error) => {
            handleError(error);
            if (isMounted.current) {
              const errorsResult = getFieldErrors(error.fieldErrors);
              setErrors(errorsResult);
            }
          })
          .finally(() => {
            if (isMounted.current) {
              setShowLoadingIndicator(false);
            }
          });
      }
    }
  };

  const handleUploadLogo = (file?: File) => {
    if (file && !createMode) {
      ServiceProviderApi.uploadLogo(serviceProvider.spId, file)
        .then(() => {
          showInfoNotification(
            intl.formatMessage({ id: "tppModal.logo.uploadSuccessfullMessage" })
          );
          setTriggerRefreshLogo((p) => !p);
        })
        .catch((error) => handleFieldOrApiErrors(error));
    }
  };

  return (
    <Card className="mb-0">
      <TradingPartnerProfileCardHeader
        serviceProviderResult={props.serviceProviderResult}
        triggerRefreshLogo={triggerRefreshLogo}
        createMode={createMode}
        readOnlyMode={readOnlyMode}
        tpp={tpp}
        setReadOnlyMode={setReadOnlyMode}
      />
      <Form onSubmit={(e) => handleSubmit(e)}>
        <div className="card-header pb-0">
          {createMode && (
            <TextFormInput
              readonly={readOnlyMode}
              labelTranslationId="tppModal.spidId"
              value={serviceProvider.spId}
              required
              handleInputChange={(value: string) =>
                setServiceProvider((previousState) => {
                  if (
                    (previousState.spId.length === 4 && value.length === 5) ||
                    (previousState.spId.length === 5 && value.length === 4)
                  )
                    setValidationChanged((previous) => !previous);
                  return { ...serviceProvider, spId: value };
                })
              }
              errorHandler={getErrorHandler(nameOf<ServiceProviderProfile>("spId"))}
            />
          )}
          {(userIsAdmin || userHasAccess) && editMode && (
            <div className="d-flex">
              <div className="flex-basis-33 pr-2">
                <Label className="form-control-label">
                  <FormattedMessage id="tppModal.logo" />
                </Label>
                <div className="custom-file mb-2">
                  <input
                    type="file"
                    className="custom-file-input"
                    lang="en"
                    onChange={(e) => handleUploadLogo(e.target.files?.[0])}
                  />
                  <label className="custom-file-label">
                    <FormattedMessage id="tppModal.logo.placeholder" />
                  </label>
                </div>
              </div>
            </div>
          )}
          <div className="row pl-3">
            <CheckboxFormInput
              value={intl.formatMessage({ id: "tppModal.vpopEnabled" })}
              label={intl.formatMessage({ id: "tppModal.vpopEnabled" })}
              handleInputChange={(value: boolean) => setServiceProvider({ ...serviceProvider, external: value })}
              checked={serviceProvider.external}
              disabled={readOnlyMode}
              className="font-weight-bold"
            />
          </div>
          <div className="d-flex">
            <div className="flex-basis-33 mr-2">
              <LnpInformation
                readonly={readOnlyMode}
                serviceProvider={serviceProvider}
                isRespOrgId={serviceProvider.spId.length === 5}
                setServiceProvider={setServiceProvider}
                getErrorHandler={getErrorHandler}
              />
            </div>
            <div className="flex-basis-33 mr-2">
              <OperationsInformation
                readonly={readOnlyMode}
                serviceProvider={serviceProvider}
                setServiceProvider={setServiceProvider}
                getErrorHandler={getErrorHandler}
              />
            </div>
            <div className="flex-basis-33">
              <ContactInformation
                readonly={readOnlyMode}
                serviceProvider={serviceProvider}
                setServiceProvider={setServiceProvider}
                getErrorHandler={getErrorHandler}
              />
            </div>
          </div>
        </div>
        <div className="modal-body">
          <div className="d-flex flex-column">
            <div className="flex-grow-1">
              <div className="d-flex flex-direction-column">
                <TextAreaFormInput
                  labelTranslationId="tppModal.notes"
                  placeholderTranslationId="tppModal.notes.placeholder"
                  rows={1}
                  disabled={readOnlyMode}
                  value={serviceProvider.notes}
                  handleInputChange={(value: string) =>
                    setServiceProvider({ ...serviceProvider, notes: value })
                  }
                  errorHandler={getErrorHandler(nameOf<ServiceProviderProfile>("notes"))}
                />
              </div>
            </div>
            {!readOnlyMode && (userIsAdmin || userHasAccess) && (
              <div className="d-flex">
                <DateTimeFormInput
                  className="mr-3"
                  labelTranslationId="tppModal.verifiedOn"
                  disabled={readOnlyMode}
                  value={
                    serviceProvider.verifiedDate ? moment(serviceProvider.verifiedDate) : undefined
                  }
                  showModalOnTop
                  handleInputChange={(value: Moment) =>
                    setServiceProvider({
                      ...serviceProvider,
                      verifiedDate: getUtcDate(value.toDate()).toDate()
                    })
                  }
                  errorHandler={getErrorHandler(nameOf<ServiceProviderProfile>("verifiedDate"))}
                />
                <i
                  className="far fa-calendar-times form-control-sm mb-2 align-self-end cursor-pointer"
                  data-toggle="tooltip"
                  data-placement="top"
                  title="Clear"
                  onClick={() =>
                    setServiceProvider({ ...serviceProvider, verifiedDate: null })
                  }></i>
              </div>
            )}
          </div>
        </div>
        {(userIsAdmin || userHasAccess ) && !readOnlyMode && (
          <div className="modal-footer">
            <Button
              color="link"
              type="button"
              className={`${theme === "light" ? "lblue-color" : ""}`}
              onClick={() => {
                if (props.closeModalOnCancel) props.closeModalOnCancel();
                else {
                  setServiceProvider({
                    ...props.serviceProviderResult.contactInformation,
                    spId: props.serviceProviderResult.spId,
                    external: props.serviceProviderResult.isVpop
                  });
                  setReadOnlyMode(true);
                  setErrors({});
                }
              }}>
              <FormattedMessage id="tppModal.cancelButton" />
            </Button>
            <div className="ml-auto">
              <Button color="primary" className={`${theme === "light" ? "bg-lblue no-border" : ""}`} type="submit" disabled={showLoadingIndicator}>
                {showLoadingIndicator && <i className="fas fa-spinner fa-spin mr-2" />}
                <FormattedMessage id="tppModal.submitButton" />
              </Button>
            </div>
          </div>
        )}
      </Form>
      {readOnlyMode && tpp && (
        <CardFooter className="pc-flex-button-container">
          <TradingPartnerProfileCardFooterButtons
            tpp={tpp ? tpp : undefined}
            spid={serviceProvider.spId}
            serviceProvider={serviceProvider}
            requestTradingPartnerCallback={fetchTpp}
            isTpp={props.isTpp}
            onActionExecuted={props.onActionExecuted}
          />
        </CardFooter>
      )}
    </Card>
  );
}
const validateServiceProvideProfile = (
  serviceProvider: ServiceProviderProfile,
  createMode: boolean
) => {
  const errors: Errors = {};

  if (createMode && !serviceProvider.spId) {
    AddError(errors, nameOf<ServiceProviderProfile>("spId"), "tppModal.emptySpid");
  }

  if (serviceProvider.spId.length === 5) {
    if (!serviceProvider.lnpEmail)
      AddError(errors, nameOf<ServiceProviderProfile>("lnpEmail"), "tppModal.lnpEmail.required");
    if (!serviceProvider.lnpPhoneNumber) {
      AddError(
        errors,
        nameOf<ServiceProviderProfile>("lnpPhoneNumber"),
        "tppModal.lnpPhoneNumber.required"
      );
    }
  } else {
    if (!serviceProvider.lsrLocation) {
      AddError(
        errors,
        nameOf<ServiceProviderProfile>("lsrLocation"),
        "tppModal.lsrLocation.required"
      );
    }

    if (!serviceProvider.csrLocation) {
      AddError(
        errors,
        nameOf<ServiceProviderProfile>("csrLocation"),
        "tppModal.csrLocation.required"
      );
    }

    if (!serviceProvider.lnpTppLocation) {
      AddError(
        errors,
        nameOf<ServiceProviderProfile>("lnpTppLocation"),
        "tppModal.lnpTppLocation.required"
      );
    }
  }
  
  if (serviceProvider.lnpPhoneNumber && (serviceProvider.lnpPhoneNumber.length > 15 || !isPhoneNumberRegex(serviceProvider.lnpPhoneNumber))) {
    AddError(errors, nameOf<ServiceProviderProfile>("lnpPhoneNumber"), "tppModal.lnpPhoneNumber.validation");
  }

  if (serviceProvider.notes && serviceProvider.notes.length > 2000) {
    AddError(errors, nameOf<ServiceProviderProfile>("notes"), "tppModal.notes.validation");
  }

  if (serviceProvider.lnpEmail && !isEmail(serviceProvider.lnpEmail)) {
    AddError(errors, nameOf<ServiceProviderProfile>("lnpEmail"), "tppModal.notAnEmail");
  }

  if (serviceProvider.lnpTppLocation && !isEmailOrUrl(serviceProvider.lnpTppLocation)) {
    AddError(errors, nameOf<ServiceProviderProfile>("lnpTppLocation"), "tppModal.notAnEmailOrUrl");
  }

  if (serviceProvider.csrLocation && !isEmailOrUrl(serviceProvider.csrLocation)) {
    AddError(errors, nameOf<ServiceProviderProfile>("csrLocation"), "tppModal.notAnEmailOrUrl");
  }

  if (serviceProvider.lsrLocation && !isEmailOrUrl(serviceProvider.lsrLocation)) {
    AddError(errors, nameOf<ServiceProviderProfile>("lsrLocation"), "tppModal.notAnEmailOrUrl");
  }

  if (serviceProvider.residentialRules && !isValidResidentialOrBusinessRule(serviceProvider.residentialRules )) {
    AddError(errors, nameOf<ServiceProviderProfile>("residentialRules"), "tppModal.limitExceeded");
  }

  if (serviceProvider.businessRules && !isValidResidentialOrBusinessRule(serviceProvider.businessRules)) {
    AddError(errors, nameOf<ServiceProviderProfile>("businessRules"), "tppModal.limitExceeded");
  }
  
  return errors;
};
