import React, { useState, useMemo, useCallback, useEffect, useContext } from "react";
import { Card, CardHeader, Nav, NavItem, NavLink, TabContent } from "reactstrap";
import { useIntl } from "react-intl";
import { OrderCardModel } from "components/orders/types/OrderCardModel";
import { PortInAccountModel } from "components/orders/types/PortInAccountModel";
import { useHistory } from "react-router-dom";
import { OrderRequestType } from "services/apis/types/port/OrderRequestType";
import classnames from "classnames";
import { useDocuments } from "../../../../services/customHooks/useDocuments";
import PortInAccountTab from "./PortInAccountTab";
import { useActiveAccount } from "../../../../services/customHooks/useActiveAccount";
import {
  showInfoNotification,
  showErrorNotification
} from "components/framework/notification/NotificationUtil";
import classNames from "classnames";
import { SpidLogo } from "services/apis/types/port/SpidLogo";
import { useCreateOrders } from "services/customHooks/useCreateOrders";
import { PortInBackButton } from "./PortInBackButton";
import { PortInHeaderError } from "./PortInHeaderError";
import ErrorList from "components/framework/errorHandling/ErrorList";
import { OrderRequestTypes } from "../../../../services/apis/types/port/OrderRequestType";
import { ApiError } from "components/common/types/ApiError";
import { PortProposalDto } from "services/apis/types/port/PortProposalDto";
import { EnvironmentUtil } from "services/util/EnvironmentUtil";
import { AppContext } from "services/appContext/AppContext";

const isMixNetworkUrl = EnvironmentUtil.isMixNetwork

type Props = {
  validationMessages: ApiError[];
  setValidationErrorMessages: React.Dispatch<React.SetStateAction<ApiError[]>>;
  spidLogos: SpidLogo[];
  accountModels: PortInAccountModel[];
  orderRequestType: OrderRequestType;
  showCustomerDetails?: boolean;
  overrideCustomerDetailsFields?: string[];
  backToStepOne: () => void;
  portProposals: PortProposalDto | undefined;
};

export default function PortInProposalSection(props: Props) {
  const [showLoadingIndicator, setShowLoadingIndicator] = useState(false);
  const [createAllSelected, setCreateAllSelected] = useState<boolean | undefined>(undefined);
  const [accountModels, setAccountModels] = useState(props.accountModels);
  const intl = useIntl();
  const history = useHistory();
  const [showOrderHandler] = useState(props.orderRequestType === OrderRequestType.PreOrderAndOrder);
  const [orderDeleted, setOrderDeleted] = useState(false);
  const { appContext } = useContext(AppContext);
  const theme = appContext.theme;

  useEffect(() => setAccountModels(props.accountModels), [props.accountModels]);

  useEffect(() => {
    const ordersToBeCreated = accountModels.some((overviewModel) =>
      overviewModel.orderCards.some((orderCard) => !orderCard.orderId)
    );

    if (!ordersToBeCreated) {
      history.push("/Orders");
      !orderDeleted && showInfoNotification(intl.formatMessage({ id: "orders.portIn.stepTwo.orderCreatedSuccess" }));
      accountModels.forEach((accountModel) =>
        accountModel.apiErrors?.forEach((error) => showErrorNotification(error.message))
      );
    }
  }, [history, intl, accountModels, orderDeleted]);

  const listOfAccountsWithErrorsMessage = useMemo(() => {
    var message = "Error in ";
    accountModels.forEach((accountModel, index) => {
      if (
        (accountModel.apiErrors?.length as number) > 0 &&
        accountModel.orderCards.some((orderCard) => !orderCard.orderId)
      ) {
        message = message.concat(
          accountModel.customerDetails.accountName
            ? accountModel.customerDetails.accountName
            : accountModels.length === 1
              ? OrderRequestTypes[props.orderRequestType]
              : `Account ${index + 1}`,
          ", "
        );
      }
    });
    return message.slice(0, -2);
  }, [accountModels, props.orderRequestType]);

  const {
    activeAccountIndex,
    setActiveAccountIndex,
    setActiveAccountModel,
    activeAccount
  } = useActiveAccount(accountModels, setAccountModels);

  const setAccountModel = useCallback(
    (newAccountModel: PortInAccountModel, accountIndex: number) => {
      setAccountModels((previousState) =>
        previousState.map((oldOverviewModel, index) => {
          if (index === accountIndex) {
            return newAccountModel;
          }

          return oldOverviewModel;
        })
      );
    },
    []
  );

  const {
    deleteDocument,
    addDocument,
    removeDocumentsThatFailedToUpload,
    uploadOrderDocuments
  } = useDocuments(activeAccountIndex, activeAccount, setAccountModel, setAccountModels);

  const handleSetOrderCards = useCallback(
    (orderCards: OrderCardModel[]) => {
      setActiveAccountModel({ ...activeAccount, orderCards: orderCards });
    },
    [activeAccount, setActiveAccountModel]
  );

  const { createOrdersForAccount, createOrdersForAllAccounts, validateAccountModelsAndSetApiErrors: validateAccountModels } = useCreateOrders(
    accountModels,
    setAccountModel,
    setAccountModels,
    props.orderRequestType,
    uploadOrderDocuments,
    removeDocumentsThatFailedToUpload,
    props.portProposals
  );

  const isDelete = () => {
    setOrderDeleted(true)
  }

  return (
    <>
      <Card>
        <CardHeader>
          <div className="d-flex mb-4 align-items-center">
            <PortInBackButton backButtonCallback={props.backToStepOne} />
            <PortInHeaderError
              accountModels={accountModels}
              errorMessage={listOfAccountsWithErrorsMessage}
            />
          </div>
          <div className="d-flex flex-direction-column">
            <ErrorList 
              errors={props.validationMessages} 
              onDismiss={(error)=> props.setValidationErrorMessages(()=> [...props.validationMessages?.filter(m=> m.message !== error.message)])}
            />
          </div>
        </CardHeader>
        <div className="nav-wrapper py-0">
          <Nav
            className="nav-fill flex-column flex-md-row flex-nowrap overflow-x-scroll"
            id="account-tabs"
            pills
            role="tablist">
            {accountModels.map((account) => (
              <NavItem
                key={accountModels.indexOf(account)}
                className={classnames("min-width-200 px-0", {
                  "account-tab-active-nav-item-light":
                    activeAccountIndex === accountModels.indexOf(account) && theme === "light"
                },
                {
                  "account-tab-active-nav-item":
                    activeAccountIndex === accountModels.indexOf(account)
                })}>
                <NavLink
                  aria-selected={activeAccountIndex === accountModels.indexOf(account)}
                  className={classnames(
                    "mb-sm-3 mb-md-0 rounded-0 shadow-none h-100 d-flex align-items-center justify-content-center",
                    {
                      "account-tab-active-nav-link-light":
                        activeAccountIndex === accountModels.indexOf(account) && theme === "light"
                    },
                    {
                      "account-tab-active-nav-link":
                        activeAccountIndex === accountModels.indexOf(account)
                    },
                  )}
                  onClick={() => {
                    setActiveAccountIndex(accountModels.indexOf(account));
                  }}
                  role="tab"
                  href="#">
                  {account.customerDetails.accountName
                    ? account.customerDetails.accountName
                    : accountModels.length === 1
                      ? isMixNetworkUrl ? OrderRequestTypes["PreOrderAndOrder"] : OrderRequestTypes[props.orderRequestType]
                      : `Account ${accountModels.indexOf(account) + 1}`}
                  {accountModels[accountModels.indexOf(account)].apiErrors &&
                    accountModels[accountModels.indexOf(account)].hasErrors && (
                      <i
                        className={classNames("pl-1 fas ", {
                          "fa-check-circle text-success": accountModels[
                            accountModels.indexOf(account)
                          ].orderCards.every((x) => x.orderId),
                          "fa-exclamation-circle text-danger": accountModels[
                            accountModels.indexOf(account)
                          ].orderCards.some((x) => !x.orderId)
                        })}
                      />
                    )}
                </NavLink>
              </NavItem>
            ))}
          </Nav>
        </div>
        <TabContent activeTab={"tabs" + activeAccountIndex}>
          {accountModels.map((account) => (
            <PortInAccountTab
              isDelete={isDelete}
              orderRequestType={props.orderRequestType}
              key={accountModels.indexOf(account)}
              tabId={"tabs" + accountModels.indexOf(account)}
              spidLogos={props.spidLogos}
              showCustomerDetails={props.showCustomerDetails ?? false}
              showLoadingIndicator={showLoadingIndicator}
              createAllSelected={createAllSelected}
              showStatusInOrderCards={account?.hasErrors && account?.apiErrors !== undefined}
              showOrderHandler={showOrderHandler}
              overviewModel={accountModels[activeAccountIndex]}
              addDocument={addDocument}
              deleteDocument={deleteDocument}
              setOverviewModel={setActiveAccountModel}
              setOrderCards={handleSetOrderCards}
              portProposals={props.portProposals}
              showApplyAll={accountModels.length > 1}
              applyAll={(account: PortInAccountModel)=>{
                accountModels.forEach(accountToUpdate=> {
                  accountToUpdate.dueDate = account.dueDate;
                  accountToUpdate.projectId = account.projectId;
                  accountToUpdate.autoActivationDate = account.autoActivationDate;
                });       
                showInfoNotification("Account settings : 'Due Date/Time', 'Project Id' , 'Activation time', have been applied to all account tabs.");
              }}
              createFromSingleTab={async () => {
                setShowLoadingIndicator(true);
                setCreateAllSelected(false);
                
                if (validateAccountModels(accountModels[activeAccountIndex], activeAccountIndex)) {
                  await createOrdersForAccount(accountModels[activeAccountIndex], activeAccountIndex, props.portProposals);
                }
                
                setCreateAllSelected(undefined);
                setShowLoadingIndicator(false);
              }}
              createIndividual={async () => {
                setShowLoadingIndicator(true);
                setCreateAllSelected(false);
                
                if (validateAccountModels(accountModels[activeAccountIndex], activeAccountIndex)) {
                  await createOrdersForAccount(accountModels[activeAccountIndex], activeAccountIndex);
                }
                
                setCreateAllSelected(undefined);
                setShowLoadingIndicator(false);
              }}
              createAll={async () => {
                setShowLoadingIndicator(true);
                setCreateAllSelected(true);
                
                if (validateAccountModels(accountModels[activeAccountIndex], activeAccountIndex)) {
                  await createOrdersForAllAccounts();
                }
                
                setCreateAllSelected(undefined);
                setShowLoadingIndicator(false);
              }}
              disabled={accountModels[activeAccountIndex].orderCards.every(
                (orderCard) => orderCard.orderId
              )}
            />
          ))}
        </TabContent>
      </Card>
    </>
  );
}
