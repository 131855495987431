import React, { useContext, useState } from "react";
import ServiceProviderModal from "./ServiceProviderModal";
import { Button } from "reactstrap";
import { AppContext } from "services/appContext/AppContext";

type Props = {
  spId: string;
  labelColor?: string;
  isTpp? : boolean;
  onActionExecuted?: () => void;
  identifier?: string;
};

export default function SpIdCell(props: Props) {
  const [showModal, setShowModal] = useState(false);
  const { appContext } = useContext(AppContext);
  const theme = appContext.theme;
  const bgGreen = props.identifier === "lgreen";

  return (
    <>
      {props.spId && (
        <>
          <Button
            className={`cursor-pointer ${
              props.labelColor || "bg-primary"
            } ${ (theme === "light" && bgGreen) ? "lgreen-spid-cell" : "text-white" } rounded p-1 border-0`}
            onClick={(e) => {
              e.preventDefault();
              setShowModal(true);
            }}>
            {props.spId}
          </Button>
          {showModal && (
            <ServiceProviderModal closeModal={() => setShowModal(false)} spId={props.spId} isTpp={props.isTpp} onActionExecuted={props.onActionExecuted}/>
          )}
        </>
      )}
    </>
  );
}
