import React, { useContext } from "react";
import { BreadcrumbItem, Breadcrumb, Container } from "reactstrap";
import { Link } from "react-router-dom";
import { AppContext } from "services/appContext/AppContext";

type Props = {
  children?: any;
};

export default function BreadcrumbHeader(props: Props) {
  const { appContext } = useContext(AppContext);
  const theme = appContext.theme;
  
  return (
    <Container fluid className="text-white py-4">
      <div className="row">
        <div className="col-lg-6 col-7">
          <Breadcrumb className="d-md-inline-block" listClassName={`breadcrumb-links ${theme === "dark" ? "breadcrumb-dark" : "breadcrumb-bold"}`}>
            <BreadcrumbItem>
              <Link key={"/"} to={"/"}>
                <i className={`fas fa-home ${theme === "light" ? "lblue-color" : ""}`}></i>
              </Link>
            </BreadcrumbItem>
            {props.children && !props.children.length && <BreadcrumbItem className={`${theme === "light" ? "lblue-color" : ""}`}>{props.children}</BreadcrumbItem>}
            {props.children &&
              props.children.length &&
              props.children.map((x: any, i: number) => <BreadcrumbItem className={`${theme === "light" ? "lblue-color" : ""}`} key={i}>{x}</BreadcrumbItem>)}
          </Breadcrumb>
        </div>
      </div>
    </Container>
  );
}
