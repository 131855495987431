import React, { useState, FormEvent, useContext } from "react";
import { CardBody, Card, Col, Row, CardHeader, Button, Form, CardFooter } from "reactstrap";
import { FormattedMessage, useIntl } from "react-intl";
import { useErrors } from "services/customHooks/useErrors";
import { nameOf } from "services/util/ObjectUtil";
import { Errors, AddError, HasErrors } from "components/framework/errorHandling/ErrorUtil";
import { showInfoNotification } from "components/framework/notification/NotificationUtil";
import { getFieldErrors, handleError } from "services/util/ApiUtil";
import { AppContext } from "services/appContext/AppContext";
import DropdownFormInput from "components/framework/forms/DropdownFormInput";
import { userZoneInfos, userLocales } from "components/common/types/UserInfo";
import TextFormInput from "components/framework/forms/TextFormInput";
import { AccountApi } from "services/apis/AccountApi";
import {
  UpdateAccountDto,
  DefaultUpdateAccountDto
} from "services/apis/types/account/UpdateAccountDto";
import { useIsMounted } from "services/customHooks/useIsMounted";

export default function ProfileCard() {
  const isMounted = useIsMounted();
  const intl = useIntl();
  const { appContext, setAppContext } = useContext(AppContext);
  const theme = appContext.theme;
  const { setErrors, getErrorHandler } = useErrors();
  const [showLoadingIndicator, setShowLoadingIndicator] = useState(false);
  const [profile, setProfile] = useState<UpdateAccountDto>(mapToUpdateUserDto(appContext));

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    setShowLoadingIndicator(true);

    const errors = validateModel(profile);

    if (HasErrors(errors)) {
      setErrors(errors);
      if (isMounted.current) {
        setShowLoadingIndicator(false);
      }
    } else {
      AccountApi.updateInfo(profile)
        .then(() => {
          let user = appContext.localStorageInfo.user
            ? { ...appContext.localStorageInfo.user, ...profile }
            : undefined;

          setAppContext({
            ...appContext,
            localStorageInfo: {
              ...appContext.localStorageInfo,
              user: user
            }
          });

          showInfoNotification(intl.formatMessage({ id: "myProfile.profile.successMessage" }));
        })
        .catch((error) => {
          handleError(error);
          if (isMounted.current) {
            const errorsResult = getFieldErrors(error.fieldErrors);
            setErrors(errorsResult);
          }
        })
        .finally(() => {
          if (isMounted.current) {
            setShowLoadingIndicator(false);
          }
        });
    }
  };

  return (
    <Card>
      <CardHeader>
        <Row className="align-items-center">
          <Col xs="12">
            <h3 className="mb-0">
              <FormattedMessage id="myProfile.profile.title" />
            </h3>
          </Col>
        </Row>
      </CardHeader>
      <Form onSubmit={(e) => handleSubmit(e)}>
        <CardBody>
          <Row>
            <TextFormInput
              formGroupClassName="col-lg-12"
              labelTranslationId="myProfile.profile.email"
              value={profile.email}
              handleInputChange={(value: string) => setProfile({ ...profile, email: value })}
              errorHandler={getErrorHandler(nameOf<UpdateAccountDto>("email"))}
            />
          </Row>
          <Row>
            <TextFormInput
              formGroupClassName="col-lg-12"
              labelTranslationId="myProfile.profile.firstName"
              value={profile.firstName}
              handleInputChange={(value: string) => setProfile({ ...profile, firstName: value })}
              errorHandler={getErrorHandler(nameOf<UpdateAccountDto>("firstName"))}
            />
          </Row>
          <Row>
            <TextFormInput
              formGroupClassName="col-lg-12"
              labelTranslationId="myProfile.profile.lastName"
              value={profile.lastName}
              handleInputChange={(value: string) => setProfile({ ...profile, lastName: value })}
              errorHandler={getErrorHandler(nameOf<UpdateAccountDto>("lastName"))}
            />
          </Row>
          <Row>
            <DropdownFormInput
              className="col-lg-12"
              required
              labelTranslationId="myProfile.profile.zoneInfo"
              value={profile.zoneInfo}
              handleInputChange={(value: string) => setProfile({ ...profile, zoneInfo: value })}
              errorHandler={getErrorHandler(nameOf<UpdateAccountDto>("zoneInfo"))}
              options={userZoneInfos}
            />
          </Row>
          <Row>
            <DropdownFormInput
              className="col-lg-12"
              required
              labelTranslationId="myProfile.profile.locale"
              value={profile.locale}
              handleInputChange={(value: string) => setProfile({ ...profile, locale: value })}
              errorHandler={getErrorHandler(nameOf<UpdateAccountDto>("locale"))}
              options={userLocales}
            />
          </Row>
        </CardBody>
        <CardFooter className="text-right">
          <Button color="primary" type="submit" className={`ml-auto ${theme === "light" ? "bg-lblue no-border" : ""}`} disabled={showLoadingIndicator}>
            {showLoadingIndicator && <i className="fas fa-spinner fa-spin mr-2" />}
            <FormattedMessage id="myProfile.profile.saveChanges" />
          </Button>
        </CardFooter>
      </Form>
    </Card>
  );
}

const validateModel = (model: UpdateAccountDto) => {
  const errors: Errors = {};

  if (!model.email) {
    AddError(errors, nameOf<UpdateAccountDto>("email"), "myProfile.profile.email.required");
  }
  if (!model.firstName) {
    AddError(errors, nameOf<UpdateAccountDto>("firstName"), "myProfile.profile.firstName.required");
  }
  if (!model.lastName) {
    AddError(errors, nameOf<UpdateAccountDto>("lastName"), "myProfile.profile.lastName.required");
  }

  return errors;
};

function mapToUpdateUserDto(appContext: AppContext): UpdateAccountDto | (() => UpdateAccountDto) {
  return appContext.localStorageInfo.user
    ? {
        email: appContext.localStorageInfo.user.email,
        firstName: appContext.localStorageInfo.user.firstName,
        lastName: appContext.localStorageInfo.user.lastName,
        zoneInfo: appContext.localStorageInfo.user.zoneInfo,
        locale: appContext.localStorageInfo.user.locale,
        userSettings: {
          ui: JSON.stringify(appContext.localStorageInfo.user.settings.ui)
        }
      }
    : DefaultUpdateAccountDto;
}
