import React, { useContext } from "react";
import { Card, CardBody, Row, CardTitle, Col } from "reactstrap";
import { FormattedMessage } from "react-intl";
import { OrderTypeConfigurations } from "components/orders/orderDetails/configuration/OrderTypeConfigurations";
import { OrderType } from "services/apis/types/order/OrderType";
import SpIdCell from "components/common/SpIdCell";
import { AppContext } from "services/appContext/AppContext";
import { OrderDto } from "services/apis/types/order/OrderDto";

type Props = {
  order?: OrderDto
  orderType: OrderType
  gainingSpId: string,
  loosingSpId: string
}

export default function OrderPortTypeCard(props: Props) {
  const { appContext } = useContext(AppContext);
  const theme = appContext.theme;

  return (
    <Card className={`flex-grow-1 ${theme === "light" ? "bg-lwhite" : "bg-gradient-primary"}`}>
      <CardBody>
        <Row>
          <div className="col">
            <CardTitle tag="h5" className={`text-uppercase text-muted mb-0 ${theme === "light" ? "text-crow" : "text-white"}`}>
              <FormattedMessage id="orders.orderDetails.portType" />
            </CardTitle>
            <span className={`h2 font-weight-bold mb-0 ${theme === "light" ? "text-crow" : "text-white"}`}>
              {props.orderType}
            </span>
          </div>
          <Col className="col-auto">
            <div
              className={`${OrderTypeConfigurations[props.orderType].backgroundColor} shortcut-media avatar rounded-circle`}>
              <i className={`${OrderTypeConfigurations[props.orderType].iconClass}`} />
            </div>
          </Col>
        </Row>
        <p className="mt-3 mb-0 text-sm">
          <span className={`${theme === "light" ? "text-crow" : "text-white"}`}>
            <FormattedMessage id={OrderTypeConfigurations[props.orderType].messageId} values={{
              gainingSpid: <SpIdCell spId={ props.orderType === OrderType.DisasterRecovery ? props.order?.numbers ? props.order.numbers[0].gainingProvider : "" : props.gainingSpId} labelColor={`${theme === "light" ? "spid-bg-lgreen" : ""}`} identifier={"lgreen"} />,
              loosingSpid: <SpIdCell spId={props.loosingSpId} labelColor={`${theme === "dark" ? "bg-warning" : "bg-lblue"}`} />
            }} />
          </span>
        </p>
      </CardBody>
    </Card>
  );
}
