import React, { FormEvent, useState, useCallback, useEffect, useContext } from "react";
import {
  Container,
  Form,
  Input,
  Button,
  FormGroup,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  Row,
  Col
} from "reactstrap";
import ServiceProviderDetails from "./serviceProviderDetails/ServiceProviderDetails";
import NpaNxxDetails from "./npaNxx/NpaNxxDetails";
import BlockDetails from "./block/BlockDetails";
import LrnDetails from "./lrn/LrnDetails";
import { useIntl, FormattedMessage } from "react-intl";
import { isSpid, isLrn, isBlock, isRespOrg, isCode } from "services/util/StringUtil";
import classNames from "classnames";
import CreateLrnModal from "./lrn/CreateLrnModal";
import CreateNpaNxxModal from "./npaNxx/CreateNpaNxxModal";
import CreateBlockModal from "./block/BlockModal";
import BreadcrumbHeader from "components/common/BreadcrumbHeader";
import ServiceProviderModal from "components/common/ServiceProviderModal";
import NetworkProfilesCard from "./networkProfile/NetworkProfilesCard";
import { NetworkItemType } from "components/networkExplorer/types/NetworkItemType";
import { LoadingIndicator } from "components/framework/loadingIndicator/LoadingIndicator";
import Authorize from "components/framework/authorization/Authorize";
import { authorize } from "services/authorization/AuthorizationService";
import NetworkProfileModal from "./networkProfile/NetworkProfileModal";
import { ManageAction } from "components/manage/types/ManageAction";
import { NetworkProfileDto } from "services/apis/types/networkProfile/NetworkProfileDto";
import { useIsMounted } from "services/customHooks/useIsMounted";
import { NetworkProfileApi } from "services/apis/NetworkProfileApi";
import { handleError } from "services/util/ApiUtil";
import { AppContext } from "services/appContext/AppContext";
import { PermissionType } from "services/authorization/PermissionType";
import { CompanyClientType } from "components/manage/serviceProvider/CompanyClientType";

type NetworkExplorerContext = {
  clearSearch: () => void;
  networkProfileActionCallback: () => void;
};

export const NetworkExplorerContext = React.createContext<NetworkExplorerContext>({
  clearSearch: () => {},
  networkProfileActionCallback: () => {}
});

export default function NetworkExplorer() {
  const [searchInputValue, setSearchInputValue] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const [errorMessageId, setErrorMessageId] = useState("");
  const [focusedSearchInput, setFocusedSearchInput] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);
  const [networkItemType, setNetworkItemType] = useState<NetworkItemType>();
  const [showLoadingIndicator, setShowLoadingIndicator] = useState(false);
  const [networkProfiles, setNetworkProfiles] = useState<Array<NetworkProfileDto>>([]);
  const [showModal, setShowModal] = useState(false);
  const { appContext } = useContext(AppContext);
  const theme = appContext.theme;
  const intl = useIntl();
  const isMounted = useIsMounted();
  const isWholeSaleCompany = appContext.localStorageInfo.selectedProfile?.clientType === CompanyClientType.WHOLESALE;

  const fetchNetworkProfiles = useCallback(() => {
    NetworkProfileApi.dualCarrierList(isWholeSaleCompany)
      .then((result) => {
        if (isMounted.current) {
          setNetworkProfiles(result);
        }
      })
      .catch((error) => handleError(error))
      .finally(() => {
        if (isMounted.current) {
          setShowLoadingIndicator(false);
        }
      });
  }, [isMounted]);

  useEffect(() => {
    if (authorize(PermissionType.PortingProfiles, true)) {
      setShowLoadingIndicator(true);
      fetchNetworkProfiles();
    }
  }, [fetchNetworkProfiles, appContext.localStorageInfo.selectedProfile]);

  const networkExplorerContext: NetworkExplorerContext = {
    clearSearch: () => {
      setSearchValue("");
      setSearchInputValue("");
    },
    networkProfileActionCallback: fetchNetworkProfiles
  };

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    const inputValue = searchInputValue.trim();
    const errorMessageIdResult = validateSearchValue(inputValue);
    setErrorMessageId(errorMessageIdResult);

    if (!errorMessageIdResult) {
      setSearchValue(inputValue);
    }
  };

  const dropdownToggle = () => setShowDropdown((prevState) => !prevState);
  const handleSearchChange = (searchValue: string) => {
    setErrorMessageId("");
    setSearchInputValue(searchValue);
  };

  const handleCreateDropdownItemButtonClick = (networkItemType: NetworkItemType) => {
    setNetworkItemType(networkItemType);
    setShowModal(true);
    setShowDropdown(false);
  };

  return (
    <>
      <BreadcrumbHeader>
        <FormattedMessage id="network.title" />
      </BreadcrumbHeader>
      <Container className="mb-5">
        <Row>
          <Col md="6" className="offset-md-3">
            <h1 className={`display-3 ${theme === "light" ? "lblue-color" : "pc-network-explorer-bg"}`}>
              <FormattedMessage id="network.title" />
            </h1>
          </Col>
        </Row>
        <Row>
          <Col md="3"></Col>
          <Col md="6" xs="12" className="mb-1">
            <Form onSubmit={handleSubmit}>
              <FormGroup
                className={classNames("input-group-merge pc-search-container mb-0", {
                  focused: focusedSearchInput
                })}>
                <InputGroup className="input-group-merge">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="fas fa-search" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    type="text"
                    className="pc-search"
                    placeholder={intl.formatMessage({ id: "network.search.placeholder" })}
                    value={searchInputValue}
                    onChange={(e) => handleSearchChange(e.currentTarget.value)}
                    onFocus={() => setFocusedSearchInput(true)}
                    onBlur={() => setFocusedSearchInput(false)}
                  />
                </InputGroup>
                <Button color={`${theme === "dark" ? "warning" : ""}`} type="submit" className={`pc-btn ml-4 ${theme === "light" ? "bg-lblue" : ""}`}>
                  <FormattedMessage id="network.search.button" />
                </Button>
              </FormGroup>
              {errorMessageId && (
                <div className="text-red text-sm mt-1">
                  <FormattedMessage id={errorMessageId} />
                </div>
              )}
            </Form>
          </Col>
          <Col md="3" xs="12" className="mb-1 pl-2">
            <Authorize
              spidPermissions={[PermissionType.NetworkWrite, PermissionType.PortingProfiles, PermissionType.TradingPartners]}>
              <div className="pc-create-dropdown">
                <Dropdown isOpen={showDropdown} toggle={dropdownToggle}>
                  <DropdownToggle className="cursor-pointer pb-0 pt-0v" tag="span">
                    <Button color={`${theme === "dark" ? "warning" : ""}`} className={`pc-btn create-btn ${theme === "light" ? "bg-lblue" : ""}`}>
                      <i className="fas fa-plus-square mr-2" />
                      <FormattedMessage id="network.create" />
                    </Button>
                  </DropdownToggle>
                  <DropdownMenu className={`dropdown-menu-lg dropdown-menu-white ${theme === "light" ? "bg-dropdown text-crow" : "bg-dark text-white pc-no-shadow"}`}>
                    <Row className="shortcuts px-4">
                      <Authorize spidPermissions={[PermissionType.PortingProfiles]}>
                        <Col
                          className="shortcut-item cursor-pointer"
                          onClick={() =>
                            handleCreateDropdownItemButtonClick(NetworkItemType.NetworkProfile)
                          }
                          xs="4">
                          <span className="shortcut-media avatar rounded-circle bg-gradient-pc-blue">
                            <i className="fa fa-project-diagram" />
                          </span>
                          <small>
                            <FormattedMessage id="network.create.networkProfile" />
                          </small>
                        </Col>
                      </Authorize>
                      <Authorize spidPermissions={[PermissionType.NetworkWrite]}>
                        <Col
                          className="shortcut-item cursor-pointer"
                          onClick={() => handleCreateDropdownItemButtonClick(NetworkItemType.Block)}
                          xs="4">
                          <span className="shortcut-media avatar rounded-circle bg-gradient-pc-orange">
                            <i className="fa fa-cubes" />
                          </span>
                          <small>
                            <FormattedMessage id="network.create.block" />
                          </small>
                        </Col>
                      </Authorize>
                      <Authorize spidPermissions={[PermissionType.NetworkWrite]}>
                        <Col
                          className="shortcut-item cursor-pointer"
                          onClick={() =>
                            handleCreateDropdownItemButtonClick(NetworkItemType.NpaNxx)
                          }
                          xs="4">
                          <span className="shortcut-media avatar rounded-circle bg-gradient-red">
                            <i className="fa fa-map-marker-alt" />
                          </span>
                          <small>
                            <FormattedMessage id="network.create.npaNxx" />
                          </small>
                        </Col>
                      </Authorize>
                      <Authorize spidPermissions={[PermissionType.NetworkWrite]}>
                        <Col
                          className="shortcut-item cursor-pointer"
                          onClick={() => handleCreateDropdownItemButtonClick(NetworkItemType.Lrn)}
                          xs="4">
                          <span className="shortcut-media avatar rounded-circle bg-gradient-red">
                            <i className="fa fa-route" />
                          </span>
                          <small>
                            <FormattedMessage id="network.create.lrn" />
                          </small>
                        </Col>
                      </Authorize>
                      <Authorize userPermissions={[PermissionType.Admin]}>
                        <Col
                          className="shortcut-item cursor-pointer"
                          onClick={() =>
                            handleCreateDropdownItemButtonClick(NetworkItemType.SpidProfile)
                          }
                          xs="4">
                          <span className="shortcut-media avatar rounded-circle bg-gradient-primary">
                            <i className="fas fa-building" />
                          </span>
                          <small>
                            <FormattedMessage id="network.create.tpp" />
                          </small>
                        </Col>
                      </Authorize>
                    </Row>
                  </DropdownMenu>
                </Dropdown>
              </div>
            </Authorize>
          </Col>
        </Row>
      </Container>
      <NetworkExplorerContext.Provider value={networkExplorerContext}>
        <Container fluid={searchValue.length === 6} className="mb-4">
          {showLoadingIndicator ? (
            <LoadingIndicator white />
          ) : (
            <>
              {searchValue.length === 0 && authorize(PermissionType.PortingProfiles, true) && (
                <NetworkProfilesCard networkProfiles={networkProfiles} />
              )}
            </>
          )}
          {(searchValue.length === 4 || searchValue.length === 5) && (
            <ServiceProviderDetails spId={searchValue} />
          )}
          {searchValue.length === 6 && <NpaNxxDetails code={searchValue} />}
          {searchValue.length === 7 && <BlockDetails blockDash={searchValue} />}
          {searchValue.length === 10 && <LrnDetails lrn={searchValue} />}
          {networkItemType === NetworkItemType.Lrn &&
            showModal &&
            authorize(PermissionType.NetworkWrite, true) && (
              <CreateLrnModal closeModal={() => setShowModal(false)} />
            )}
          {networkItemType === NetworkItemType.NpaNxx &&
            showModal &&
            authorize(PermissionType.NetworkWrite, true) && (
              <CreateNpaNxxModal closeModal={() => setShowModal(false)} />
            )}
          {networkItemType === NetworkItemType.Block &&
            showModal &&
            authorize(PermissionType.NetworkWrite, true) && (
              <CreateBlockModal closeModal={() => setShowModal(false)} />
            )}
          {networkItemType === NetworkItemType.NetworkProfile &&
            showModal &&
            authorize(PermissionType.PortingProfiles, true) && (
              <NetworkProfileModal
                manageAction={ManageAction.Add}
                closeModal={() => setShowModal(false)}
              />
            )}
          {networkItemType === NetworkItemType.SpidProfile && showModal && (
            <ServiceProviderModal closeModal={() => setShowModal(false)} />
          )}
        </Container>
      </NetworkExplorerContext.Provider>
    </>
  );
}

const validateSearchValue = (searchInputValue: string): string => {
  const validationFunctions: Record<number, () => string | false> = {
    4: () => !isSpid(searchInputValue) && "network.search.invalidSpid",
    5: () => !isRespOrg(searchInputValue) && "network.search.invalidRespOrg",
    6: () => !isCode(searchInputValue) && "network.search.invalidCode",
    7: () => !isBlock(searchInputValue) && "network.search.invalidBlock",
    10: () => !isLrn(searchInputValue) && "network.search.invalidLrn"
  };

  return searchInputValue
    ? validationFunctions[searchInputValue.length]
      ? validationFunctions[searchInputValue.length]() || ""
      : "network.search.invalidLength"
    : "network.search.emptySearch";
};
