import LoginLayout from "components/framework/loginLayout/LoginLayout";
import React, { useContext, useEffect, useState } from "react";
import LoginHeader from "components/framework/loginLayout/LoginHeader";
import { Container, Row, Col, Card, CardBody, UncontrolledAlert } from "reactstrap";
import { FormattedMessage, useIntl, FormattedHTMLMessage } from "react-intl";
import { Link, useParams } from "react-router-dom";
import { getTranslation } from "translations/TranslationService";
import { AccountApi } from "services/apis/AccountApi";
import { ApiError } from "services/apis/types/ApiError";
import { LoadingIndicator } from "components/framework/loadingIndicator/LoadingIndicator";
import { useIsMounted } from "services/customHooks/useIsMounted";
import { ApiErrorType } from "components/common/types/ApiErrorType";
import { useCallback } from "react";
import { AppContext } from "services/appContext/AppContext";

export default function SignupVerification() {
  const intl = useIntl();
  let { id }: any = useParams();
  const isMounted = useIsMounted();
  const [apiError, setApiError] = useState<ApiError>();
  const [showLoadingIndicator, setShowLoadingIndicator] = useState(true);
  const [verificationSuccessfull, setVerificationSuccessfull] = useState(false);
  const { appContext } = useContext(AppContext);
  const theme = appContext.theme;

  const verifyToken = useCallback(() => {
    AccountApi.verify({ token: id }).then(() => {
      setVerificationSuccessfull(true);
    })
      .catch((error) => {
        if (isMounted.current) {
          setApiError(error);
        }
      })
      .finally(() => {
        if (isMounted.current) {
          setShowLoadingIndicator(false);
        }
      });
  }, [id, isMounted]);

  useEffect(() => {
    //Call API to verify token
    if (id) {
      verifyToken();
    }
  }, [id, verifyToken]);

  return (
    <LoginLayout>
      <LoginHeader title={getTranslation(intl, "account.verification.header")} />
      <Container className="mt--8 pb-5">
        <Row className="justify-content-center">

          {showLoadingIndicator ? (
            <Col lg="5" md="7">
              <LoadingIndicator white />
            </Col>
          ) : (
            <Col lg="5" md="7">

              <Card className="bg-secondary border-0 mb-0">
                <CardBody className="px-lg-5 py-lg-5">
                  <div className="text-center text-muted mb-4">
                    <small className="font-weight-bold">
                      <FormattedHTMLMessage id="account.verification.checkingToken" />
                    </small>
                  </div>
                  {verificationSuccessfull ? (
                    <div className="text-center">
                      <p>
                        <i className="far fa-check-circle text-success display-2 mb-4"></i>
                      </p>
                      <p>
                        <FormattedMessage id="account.verification.successNotificationMessage" />
                      </p>
                    </div>
                  ) : (
                    <Row className="mb-3">
                      <Col lg="12">
                        <UncontrolledAlert
                          key={apiError?.message}
                          color={ApiErrorType.Danger}
                          className="d-flex p-0 pl-1 mb-1 align-items-center">
                          <span className="alert-icon">
                            <i className="far fa-exclamation-circle"></i>
                          </span>
                          <span className="alert-text ml-1 flex-basis-95">{apiError?.message}</span>
                        </UncontrolledAlert>
                      </Col>
                    </Row>
                  )}
                </CardBody>
              </Card>
              <Row className="mt-3">
                <Col xs="6">
                  <small>
                    <Link to="/Login" className={`text-xs ${theme === "light" ? "purple-color" : "text-light"}`}>
                      <FormattedMessage id="account.signup.login" />
                    </Link>
                  </small>
                </Col>
              </Row>
            </Col>
          )}
        </Row>
      </Container>
    </LoginLayout >
  );
}
