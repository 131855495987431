import { IdentityApi } from "services/apis/IdentityApi";
import {
  AuthenticationInfo,
  DefaultAuthenticationInfo
} from "services/appContext/AuthenticationInfo";
import { IdentityResponse } from "services/apis/types/identity/IdentityResponse";
import { AppContextAccessor } from "../appContext/AppContextAccessor";
import { AccountApi } from "services/apis/AccountApi";
import { DefaultOrderDetails } from "services/appContext/Orders";
import { DefaultWorkQueueDetails } from "services/appContext/WorkQueue";

export const IdentityService = {
  clearAuthenticationInfo: (sessionExpired?: boolean) => {
    localStorage.removeItem('isCheckAutoActivation');
    localStorage.removeItem('isCheckAttentionRequired');
    AppContextAccessor.setAppContext((appContext) => {
      return {
        ...appContext,
        localStorageInfo: {
          ...appContext.localStorageInfo,
          authenticationInfo: DefaultAuthenticationInfo,
          user: undefined,
          selectedProfile: undefined,
          orders: DefaultOrderDetails,
          workQueue: DefaultWorkQueueDetails,
          hasTokenIdentifier: false
        },
        showSessionExpiredError: sessionExpired || false,
        hasTokenIdentifier: false,
        previousUrl: '',
        isLoggedOut: true
      };
    });
  },

  login: async (username: string, password: string): Promise<boolean> => {
    const identityResponse = await IdentityApi.login(username, password);

    if (identityResponse.error) {
      return false;
    }

    AppContextAccessor.setAppContext((appContext) => {
      const authenticationInfo = getNewAuthenticationInfo(
        identityResponse,
        appContext.localStorageInfo.authenticationInfo
      );

      return {
        ...appContext,
        localStorageInfo: {
          ...appContext.localStorageInfo,
          authenticationInfo: {
            ...authenticationInfo,
            authenticated: true,
            username: username
          }
        }
      };
    });

    return true;
  },

  checkAndRenewAccessToken: async (): Promise<boolean> => {
    if (AppContextAccessor.getAppContext().localStorageInfo.authenticationInfo.authenticated) {
      if(!AppContextAccessor.getAppContext().localStorageInfo.orders) {
        AppContextAccessor.setAppContext((appContext) => {
          appContext.localStorageInfo.orders = DefaultOrderDetails;
          return {
            ...appContext,
            localStorageInfo: {
              ...appContext.localStorageInfo,
            }
          };
        });
      }
      if(!AppContextAccessor.getAppContext().localStorageInfo.workQueue) {
        AppContextAccessor.setAppContext((appContext) => {
          appContext.localStorageInfo.workQueue = DefaultWorkQueueDetails;
          return {
            ...appContext,
            localStorageInfo: {
              ...appContext.localStorageInfo,
            }
          };
        });
      }
      if (
        accessTokenIsExpired(
          AppContextAccessor.getAppContext().localStorageInfo.authenticationInfo.expiry
        )
      ) {
        const identityResponse: IdentityResponse = await IdentityApi.refreshAccessToken();

        if (renewFailed(identityResponse)) {
          IdentityService.clearAuthenticationInfo(true);

          return false;
        }

        AppContextAccessor.setAppContext((appContext) => {
          return {
            ...appContext,
            localStorageInfo: {
              ...appContext.localStorageInfo,
              authenticationInfo: getNewAuthenticationInfo(
                identityResponse,
                appContext.localStorageInfo.authenticationInfo
              )
            }
          };
        });
      }

      return true;
    }

    return false;
  },

  fetchUserInfo: async () => {
    AccountApi.info().then((fetchedUser) => {
      AppContextAccessor.setAppContext((appContext) => {
        return {
          ...appContext,
          localStorageInfo: {
            ...appContext.localStorageInfo,
            user: {
              ...fetchedUser,
              settings: {
                ui: fetchedUser.settings.ui && JSON.parse(fetchedUser.settings.ui)
              }
            },
            selectedProfile:
              !appContext.localStorageInfo.selectedProfile && fetchedUser.spIdProfiles.length > 0
                ? fetchedUser.spIdProfiles[0]
                : appContext.localStorageInfo.selectedProfile
          }
        };
      });
    });
  }
};

export const getNewAuthenticationInfo = (
  identityResponse: IdentityResponse,
  authenticationInfo: AuthenticationInfo
): AuthenticationInfo => {
  const newAuthenticationInfo: AuthenticationInfo = {
    ...authenticationInfo,
    access_token: identityResponse.access_token,
    refresh_token: identityResponse.refresh_token,
    expiry: getNewExpiryDate(identityResponse.expires_in)
  };

  return newAuthenticationInfo;
};

const accessTokenIsExpired = (expiry: string): boolean => {
  const date = new Date();
  const expiryDate = new Date(expiry);

  return date > expiryDate;
};

const getNewExpiryDate = (expiresIn: number): string =>
  new Date(new Date().getTime() + expiresIn * 1000).toString();

const renewFailed = (identityResponse: IdentityResponse) =>
  !identityResponse ||
  identityResponse.error ||
  !identityResponse.access_token ||
  !identityResponse.refresh_token;
