import React, { useState, FormEvent, useCallback, useContext } from "react";
import { Modal, Form, Button, Row } from "reactstrap";
import { FormattedMessage, useIntl } from "react-intl";
import { showInfoNotification } from "components/framework/notification/NotificationUtil";
import { handleError, getFieldErrors } from "services/util/ApiUtil";
import { useErrors } from "services/customHooks/useErrors";
import TextFormInput from "components/framework/forms/TextFormInput";
import { nameOf } from "services/util/ObjectUtil";
import { useIsMounted } from "services/customHooks/useIsMounted";
import { Errors, HasErrors, AddError } from "components/framework/errorHandling/ErrorUtil";
import { AuditApi } from "services/apis/AuditApi";
import {
  RequestAuditModel,
  DefaultRequestAuditModel
} from "services/apis/types/audit/RequestAuditModel";
import CloseButton from "components/framework/modals/CloseButton";
import { useHistory } from "react-router-dom";
import PhoneNumberInput from "components/framework/forms/PhoneNumberInput";
import { isValidName, hasNameLengthExceeded } from "services/util/StringUtil";
import { AppContext } from "services/appContext/AppContext";

type Props = {
  closeModal: () => void;
  triggerRefresh?: () => void;
};

export default function CreateAuditModal(props: Props) {
  const { setErrors, getErrorHandler } = useErrors();
  const [audit, setAudit] = useState<RequestAuditModel>({ ...DefaultRequestAuditModel });
  const [showLoadingIndicator, setShowLoadingIndicator] = useState(false);
  const intl = useIntl();
  const isMounted = useIsMounted();
  const history = useHistory();
  const { appContext } = useContext(AppContext);
  const theme = appContext.theme;

  const closeModal = useCallback(props.closeModal, []);

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    const errors = validateAudit(audit);

    if (HasErrors(errors)) {
      setErrors(errors);
    } else {
      setShowLoadingIndicator(true);

      AuditApi.create(audit)
        .then(() => {
          if (props.triggerRefresh) {
            props.triggerRefresh();
          }
          showInfoNotification(intl.formatMessage({ id: "audits.create.audit.success" }));
          closeModal();
          history.push("/Audits");
        })
        .catch((error) => {
          handleError(error);
          if (isMounted.current) {
            const errorsResult = getFieldErrors(error.fieldErrors);
            setErrors(errorsResult);
          }
        })
        .finally(() => {
          if (isMounted.current) {
            setShowLoadingIndicator(false);
          }
        });
    }
  };

  return (
    <Modal className="modal-dialog-centered modal-sm" isOpen={true}>
      <div className="modal-header">
        <h5 className="modal-title">
          <FormattedMessage id="audits.create.audit.title" />
        </h5>
        <CloseButton close={props.closeModal} />
      </div>
      <Form onSubmit={(e) => handleSubmit(e)}>
        <div className="modal-body">
          <Row>
            <TextFormInput
              formGroupClassName="col-lg-12"
              labelTranslationId="audits.create.audit.name"
              value={audit.name}
              handleInputChange={(value: string) => setAudit({ ...audit, name: value })}
              errorHandler={getErrorHandler(nameOf<RequestAuditModel>("name"))}
            />
          </Row>
          <Row>
            <PhoneNumberInput
              formGroupClassName="col-lg-12"
              labelTranslationId="audits.create.audit.phoneNumbers"
              placeholderTranslationId="audits.create.audit.phoneNumbers.placeholder"
              value={audit.phoneNumberRange}
              handleInputChange={(value: string) => setAudit({ ...audit, phoneNumberRange: value })}
              errorHandler={getErrorHandler(nameOf<RequestAuditModel>("phoneNumberRange"))}
            />
          </Row>
        </div>
        <div className="modal-footer">
          <Button color="link" type="button" className={`${theme === "light" ? "lblue-color" : ""}`} onClick={() => props.closeModal()}>
            <FormattedMessage id="audits.create.audit.close" />
          </Button>
          <Button color="primary" type="submit" className={`ml-auto ${theme === "light" ? "bg-lblue no-border" : ""}`} disabled={showLoadingIndicator}>
            {showLoadingIndicator && <i className="fas fa-spinner fa-spin mr-2" />}
            <FormattedMessage id="audits.create.audit.submit" />
          </Button>
        </div>
      </Form>
    </Modal>
  );
}

const validateAudit = (audit: RequestAuditModel) => {
  const errors: Errors = {};

  if (!audit.name) {
    AddError(errors, nameOf<RequestAuditModel>("name"), "audits.create.audit.name.emptyError");
  }
  if (audit.name && !isValidName(audit.name)) {
    AddError(errors, nameOf<RequestAuditModel>("name"), "audits.create.query.name.invalidName");
  }
  if (audit.name && !hasNameLengthExceeded(audit.name)) {
    AddError(errors, nameOf<RequestAuditModel>("name"), "audits.create.query.name.limitExceeded");
  }
  if (!audit.phoneNumberRange) {
    AddError(
      errors,
      nameOf<RequestAuditModel>("phoneNumberRange"),
      "audits.create.audit.phoneNumbers.emptyError"
    );
  }

  return errors;
};
