import React, { useState, useContext } from "react";
import { FormattedMessage } from "react-intl";
import { Dropdown, DropdownToggle, Button, DropdownMenu, Row, Col } from "reactstrap";
import { OrderDto } from "services/apis/types/order/OrderDto";
import { useIsMounted } from "services/customHooks/useIsMounted";
import classNames from "classnames";
import ExecuteOrderAction from "./ExecuteOrderAction";
import { OrderNumberActionType } from "services/portControl/types/OrderNumberActionType";
import { OrderNumberActionConfigurations } from "./configuration/OrderNumberActionConfigurations";
import Icon from "components/common/Icon";
import { OrderDetailsContext } from "./OrderDetailsContainer";
import { AppContext } from "services/appContext/AppContext";

type Props = {
  order: OrderDto;
};

export default function PortingNumbersTableActionsButton(props: Props) {
  const [selectedAction, setSelectedAction] = useState<OrderNumberActionType>();
  const [showDropdown, setShowDropdown] = useState(false);
  const dropdownToggle = () => setShowDropdown((prevState) => !prevState);
  const orderDetailsContext = useContext(OrderDetailsContext);
  const isMounted = useIsMounted();
  const { appContext } = useContext(AppContext);
  const theme = appContext.theme;

  const actions: OrderNumberActionType[] =  props.order.availableActions as OrderNumberActionType[];

  const toggleAction = (action: OrderNumberActionType) => {
    if (isMounted.current) {
      setSelectedAction(action);
      setShowDropdown(false);
    }
  };

  return (
    <Dropdown isOpen={showDropdown} toggle={dropdownToggle}>
      <DropdownToggle className="cursor-pointer pb-0 pt-0v" tag="span">
        <Button color="danger" className={`pc-btn ${theme === "light" ? "bg-lblue no-border" : ""}`}>
          <i className="fas fa-plus-square mr-2" />
          <FormattedMessage id="orders.order.actions.actions" />
        </Button>
      </DropdownToggle>
      <DropdownMenu
        className={`dropdown-menu-lg dropdown-menu-white ${theme === "light" ? "bg-dropdown text-crow" : "bg-dark text-white pc-no-shadow"}`}
        right>
        <Row className="shortcuts px-4">
          {actions &&
          actions.length > 0 &&
          actions.some((x) => OrderNumberActionConfigurations[x]) ? (
            actions.map((action) => (
              <React.Fragment key={action}>
                {OrderNumberActionConfigurations[action] && (
                  <Col
                    className="shortcut-item cursor-pointer"
                    xs="4"
                    onClick={() => toggleAction(action)}>
                    {selectedAction === action && (
                      <ExecuteOrderAction
                        order={props.order}
                        closeModal={() => setSelectedAction(undefined)}
                        onActionExecuted={orderDetailsContext.fetchOrder}
                        confirmationMessageId={
                          OrderNumberActionConfigurations[action].Order.confirmMessageId
                        }
                        action={action}
                        skipConfirmation={
                          OrderNumberActionConfigurations[action].Order.skipConfirmation
                        }
                      />
                    )}
                    <span
                      className={classNames(
                        "shortcut-media avatar rounded-circle",
                        OrderNumberActionConfigurations[action].Order.background
                      )}>
                      <Icon
                        className={`${OrderNumberActionConfigurations[action].Order.iconClass}`}
                        title={action}
                      />
                    </span>
                    <small>
                      <FormattedMessage id={OrderNumberActionConfigurations[action].Order.text} />
                    </small>
                  </Col>
                )}
              </React.Fragment>
            ))
          ) : (
            <Col md="12 text-center py-3">
              <FormattedMessage id="orders.order.actions.actions.noActions" />
            </Col>
          )}
        </Row>
      </DropdownMenu>
    </Dropdown>
  );
}
