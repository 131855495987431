import React, { useMemo, useState, useEffect, useCallback, FormEvent, useContext } from "react";
import { Button, Form, Modal } from "reactstrap";
import CloseButton from "components/framework/modals/CloseButton";
import { LoadingIndicator } from "components/framework/loadingIndicator/LoadingIndicator";
import { FormattedMessage } from "react-intl";
import ReactTable from "components/framework/table/ReactTable";
import { IntlShape, useIntl } from "react-intl";
import { getValueOrEmpty } from "services/util/StringUtil";
import { TradingPartnerApi } from "services/apis/TradingPartnerApi";
import { useIsMounted } from "services/customHooks/useIsMounted";
import { TppHistory, DefaultTppHistory } from "services/apis//types/tpp/TppHistory";
import { handleError } from "services/util/ApiUtil";
import FormatDateTime from "components/framework/date/FormatDateTime";
import TextFormInput from "components/framework/forms/TextFormInput";
import { CustomerInventoryApi } from "services/apis/CustomerInventoryApi";
import { InternalId } from "services/apis/types/customerInventory/CustomerInventoryInternalId";
import { showInfoNotification } from "components/framework/notification/NotificationUtil";
import { AppContext } from "services/appContext/AppContext";

type Props = {
  closeModal: () => void;
  internalId: any;
  customerId: string;
  onUpdateSuccess: (boolean) => void
};

export default function CustomerInventoryEditModal(props: Props) {
  const [showLoadingIndicator, setShowLoadingIndicator] = useState(false);
  const [internalId, setInternalId] = useState(props.internalId);
  const [onUpdateInternalId, setOnUpdateInternalId] = useState(false);
  const { appContext } = useContext(AppContext);
  const theme = appContext.theme;
  const intl = useIntl();
  const isMounted = useIsMounted();

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    setShowLoadingIndicator(true);
    CustomerInventoryApi.updateCustomer(props.customerId, internalId)
    .then((result) => {
      if(result) {
        showInfoNotification(intl.formatMessage({ id: "customerInventory.internalId.edit.confirm.message" }));
        props.onUpdateSuccess(!onUpdateInternalId);
      }
    })
    .catch((error) => handleError(error))
    .finally(() => {
      setShowLoadingIndicator(false);
      props.closeModal();
    });
  }

  return (
    <>
      <Modal className="pc-modal-sm modal-dialog-centered" isOpen={true}>
        <div className="modal-header border-bottom">
          <h4 className="modal-title">
            <FormattedMessage id={"customerInventory.Action.Edit"} />
          </h4>
          <CloseButton close={props.closeModal} />
        </div>
        <Form onSubmit={(e) => handleSubmit(e)}>
          <div className="modal-body">
          <TextFormInput
            formGroupClassName="col-lg-12"
            labelTranslationId="customerInventory.table.internalId"
            value={internalId}
            handleInputChange={(value: string) =>{
                setInternalId(value)
                }
              }
           />
          </div>
          <div className="modal-footer">
            <Button color="link" type="button" className={`${theme === "light" ? "lblue-color" : ""}`} onClick={() => props.closeModal()}>
              <FormattedMessage id="orders.modify.edit.cancelButton" />
            </Button>
            <Button
              color="primary"
              type="submit"
              className={`ml-auto ${theme === "light" ? "bg-lblue no-border" : ""}`}
              disabled={showLoadingIndicator}>
              {showLoadingIndicator && <i className="fas fa-spinner fa-spin mr-2" />}
              <FormattedMessage id="orders.modify.edit.submitButton" />
            </Button>
          </div>
        </Form>
        </Modal>
    </>
  );
}
