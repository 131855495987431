import React, { useEffect, useState, useCallback, useContext } from "react";
import { handleError } from "services/util/ApiUtil";
import { Container, Row, Col } from "reactstrap";
import { LoadingIndicator } from "components/framework/loadingIndicator/LoadingIndicator";
import { DefaultOrder, OrderDto } from "services/apis/types/order/OrderDto";
import PortingNumbersTable from "./PortingNumbersTable";
import { useIsMounted } from "services/customHooks/useIsMounted";
import { OrderApi } from "services/apis/OrderApi";
import OrderMetadataCard from "./OrderMetadataCard";
import OrderPortTypeCard from "./OrderPortTypeCard";
import OrderStatusCard from "./OrderStatusCard";
import NotesCard from "./notes/NotesCard";
import TasksCard from "./tasks/TasksCard";
import OrderDetailsCard from "./OrderDetailsCard";
import { AppContext } from "services/appContext/AppContext";
import { EnvironmentUtil } from "services/util/EnvironmentUtil";
import { PermissionType } from "services/authorization/PermissionType";
import { useLocation } from "react-router-dom";

type OrderDetailsContext = {
  fetchOrder: () => void;
  order: OrderDto;
};

export const OrderDetailsContext = React.createContext<OrderDetailsContext>({
  fetchOrder: () => {},
  order: DefaultOrder
});

const isMixNetworkUrl = EnvironmentUtil.isMixNetwork;

type Props = {
  orderId: string;
  isDisasterRecovery?: boolean;
};

export default function OrderDetailsContainer(props: Props) {
  const [showLoadingIndicator, setShowLoadingIndicator] = useState(true);
  const [order, setOrder] = useState<OrderDto>(DefaultOrder);
  const isMounted = useIsMounted();
  const { appContext } = useContext(AppContext);
  const isUserAdmin = appContext.localStorageInfo.user?.permissions.find(value => value === PermissionType.Admin);

  const fetchOrder = useCallback(() => {
    if (props.isDisasterRecovery) {
      OrderApi.getDR(props.orderId)
      .then((result) => {
        if (isMounted) {
          setOrder(result);
        }
      })
      .catch((error) => handleError(error))
      .finally(() => {
        if (isMounted) {
          setShowLoadingIndicator(false);
        }
      });
    } else {
      OrderApi.get(props.orderId)
      .then((result) => {
        if (isMounted) {
          setOrder(result);
        }
      })
      .catch((error) => handleError(error))
      .finally(() => {
        if (isMounted) {
          setShowLoadingIndicator(false);
        }
      });
    }
  }, [props.orderId, isMounted, props.isDisasterRecovery]);

  useEffect(() => {
    if (props.orderId) {
      fetchOrder();
    }
  }, [props.orderId, fetchOrder]);

  const orderDetailsContext: OrderDetailsContext = {
    fetchOrder: fetchOrder,
    order: order
  };

  return (
    <OrderDetailsContext.Provider value={orderDetailsContext}>
      <Container fluid>
        {showLoadingIndicator ? (
          <LoadingIndicator white />
        ) : (
          <>
            <Row>
              <Col xl={(!isMixNetworkUrl || isUserAdmin) ? "5" : "6"} md="12" className="d-flex">
                <OrderMetadataCard orderMetadata={order.metadata} orderId={order.orderId} orderType={order.orderType} />
              </Col>
              {(!isMixNetworkUrl || isUserAdmin) && (
                <Col xl="3" md="6">
                  <OrderPortTypeCard
                    order={order}
                    orderType={order.orderType}
                    loosingSpId={order.loosingProvider}
                    gainingSpId={order.gainingProvider}
                  />
                </Col>
              )}
              <Col xl={(!isMixNetworkUrl || isUserAdmin) ? "4" : "6"} md="12" className="d-flex">
                <OrderStatusCard order={order} />
              </Col>
            </Row>
            {order.customerDetails && !props.isDisasterRecovery && (
              <Row>
                <Col md="12">
                  <OrderDetailsCard order={order} />
                </Col>
              </Row>
            )}
            <Row>
              <Col md="5" className="mb-3">
                <PortingNumbersTable order={order} isDisasterRecovery={props.isDisasterRecovery} />
              </Col>
              <Col md="3">
                <TasksCard orderId={props.orderId} />
              </Col>
              <Col md="4">
                <NotesCard order={order} />
              </Col>
            </Row>
          </>
        )}
      </Container>
    </OrderDetailsContext.Provider>
  );
}
