import React, { useContext } from "react";
import { OrderListDto } from "services/apis/types/order/OrderListDto";
import IconWithTooltip from "./IconWithTooltip";
import { useIntl } from "react-intl";
import { AppContext } from "services/appContext/AppContext";

type Props = {
  order: OrderListDto;
  handleInputChange?: any;
}

export default function OrderSelect(props: Props) {
  const intl = useIntl();
  const { appContext } = useContext(AppContext);
  const theme = appContext.theme;
  
  const handleSelect = (e) => {
    props.handleInputChange({ ...props.order, selected: e.currentTarget.checked }, e.nativeEvent.shiftKey)
  }

  const autoActivation=() => {
    let autoActivation = props.order.autoActivation
    let activationTimeZone = props.order.activationTimeZone ? props.order.activationTimeZone : ""
    return autoActivation + " " + activationTimeZone
  }
  return (
    <>
    <div className="d-flex align-items-center mb-2">
      <input
        className={`order-checkbox ${theme === "light" ? "lblue-checkbox" : ""}`}
        id={props.order.orderId}
        type="checkbox"
        onChange={(e) => handleSelect(e)}
        checked={props.order.selected}
      />
      {props.order.autoActivation && (
      <IconWithTooltip
        className="ml-3 fa fa-clock"
        tooltip= {autoActivation()}
      />
      )}
    </div>  
    </>
  )
}