import React from "react";
import classNames from "classnames";
import ReactDatetime from "react-datetime";
import { AppConfiguration } from "AppConfiguration";
import moment, { Moment } from "moment";
import Icon from "components/common/Icon";

type Props = {
  disabled?: boolean;
  className?: string;
  value?: Moment | null;
  placeholder?: string;
  onChange: (value: Moment) => void;
  isTimeInput?: boolean;
  showModalOnTop?: boolean;
  isValidDate?: (currentDate: Moment, selectedDate: Moment | undefined) => boolean;
  showCalendar?: boolean;
  iconColor?: string;
};

export default function DateTimeInput(props: Props) {
  return (
    <div style={{ position: 'relative' }}>
      <ReactDatetime
        utc
        inputProps={{
          disabled: props.disabled,
          className: classNames("form-control form-control-sm bg-white", props.className),
          placeholder: props.placeholder,
          readOnly: true
        }}
        className={classNames({ "top-datepicker": props.showModalOnTop })}
        value={props.value ? props.value : undefined}
        onChange={(e: string | Moment) => {
          props.onChange(moment.utc(e));
        }}
        closeOnSelect={true}
        dateFormat={!props.isTimeInput ? AppConfiguration.dateFormat : false}
        timeFormat={props.isTimeInput ? AppConfiguration.timeFormat : false}
        isValidDate={props.isValidDate}
      />
      { props.showCalendar &&
        <div className="calendar-position">
          <Icon className={`fas fa-calendar ${props.iconColor ? "lblue-color" : "calendar-icon"}`} title="" />
        </div>
      }
    </div>
  );
}
