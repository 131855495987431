import React, { useState, FormEvent, useCallback, useContext } from "react";
import { Modal, Form, Button, Row } from "reactstrap";
import { FormattedMessage, useIntl } from "react-intl";
import { NumberStatusDto } from "services/apis/types/order/NumberStatusDto";
import { useErrors } from "services/customHooks/useErrors";
import { PortInModify, DefaultPortInModify } from "../types/PortInModify";
import { DefaultPortInModifyModel } from "../types/PortInModifyModel";
import { Moment } from "moment";
import { useIsMounted } from "services/customHooks/useIsMounted";
import { HasErrors, Errors, AddError } from "components/framework/errorHandling/ErrorUtil";
import { PortApi } from "services/apis/PortApi";
import { showInfoNotification } from "components/framework/notification/NotificationUtil";
import { handleError, getFieldErrors } from "services/util/ApiUtil";
import CloseButton from "components/framework/modals/CloseButton";
import TextFormInput from "components/framework/forms/TextFormInput";
import { nameOf } from "services/util/ObjectUtil";
import { ModifyModel } from "services/apis/types/port/ModifyModel";
import DateTimeFormInput from "components/framework/forms/DateTimeFormInput";
import { DefaultDpcSsnInfo, DpcSsnInfo } from "services/apis/types/block/DpcSsnInfo";
import {
  DefaultOptionalDataInfo,
  OptionalDataInfo
} from "services/apis/types/number/OptionalDataInfo";
import { isPhoneNumber, isRange } from "services/util/StringUtil";
import { getUtcDate, isSunday } from "services/util/DateUtil";
import { OrderApi } from "services/apis/OrderApi";
import PhoneNumberInput from "components/framework/forms/PhoneNumberInput";
import { OrderDto } from "services/apis/types/order/OrderDto";
import NpacMaintenanceAlert from "../NpacMaintenanceAlert";
import { SvRequestStatus } from "../types/SvRequestStatus";
import { EnvironmentUtil } from "services/util/EnvironmentUtil";
import { AppContext } from "services/appContext/AppContext";

type Props = {
  order: OrderDto;
  numberStatus?: NumberStatusDto;
  closeModal: () => void;
  onActionExecuted?: () => void;
};
const isMixNetworkUrl = EnvironmentUtil.isMixNetwork;

export default function PortInModifyModal(props: Props) {
  const { setErrors, getErrorHandler } = useErrors();
  const { appContext } = useContext(AppContext);
  const theme = appContext.theme;
  const [portInModify, setPortInModify] = useState<PortInModify>({
    phoneNumberRange: props.numberStatus?.phoneNumber || DefaultPortInModify.phoneNumberRange,
    portInModifyModel: {
      ...DefaultPortInModifyModel,
      dueDate: props.numberStatus && getUtcDate(props.numberStatus.dueDate),
      spId: props.order.gainingProvider,
      oldSpId: props.order.loosingProvider,
      networkData: props.order.numbers[0].networkData || props.numberStatus?.dpcSsnInfo || props.order.numbers?.[0].dpcSsnInfo,
      optionalData: props.order.numbers[0].optionalData,
      lrn: props.numberStatus?.lrn || props.order.numbers?.[0]?.lrn,
      billingId: props.numberStatus?.billingId || props.order.numbers?.[0].billingId,
      userLocationType:
        props.numberStatus?.userLocationType || props.order.numbers?.[0].userLocationType,
      userLocationValue: props.numberStatus?.userLocation || props.order.numbers?.[0].userLocation
    }
  });

  const [showLoadingIndicator, setShowLoadingIndicator] = useState(false);
  const intl = useIntl();
  const isMounted = useIsMounted();
  const closeModal = useCallback(props.closeModal, []);

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();

    const errors = validateModify(portInModify, props.order.status, props.numberStatus);

    if (HasErrors(errors)) {
      setErrors(errors);
    } else {
      setShowLoadingIndicator(true);

      const model = {
        modifyType: portInModify.portInModifyModel.modifyType,
        portDirection: portInModify.portInModifyModel.portDirection,
        spId: portInModify.portInModifyModel.spId,
        oldSpId: portInModify.portInModifyModel.oldSpId,
        dueDate: portInModify.portInModifyModel.dueDate
          ? portInModify.portInModifyModel.dueDate.toDate()
          : undefined,
        lrn: portInModify.portInModifyModel.lrn,
        billingId: portInModify.portInModifyModel.billingId,
        userLocationValue: portInModify.portInModifyModel.userLocationValue,
        userLocationType: portInModify.portInModifyModel.userLocationType,
        networkData: portInModify.portInModifyModel.networkData,
        optionalData: portInModify.portInModifyModel.optionalData,
        orderId: props.order.orderId
      };

      const api = props.numberStatus
        ? PortApi.modify(portInModify.phoneNumberRange, model)
        : OrderApi.modify(props.order.orderId, props.order.tenant, model);

      api
        .then(() => {
          showInfoNotification(
            intl.formatMessage({
              id: props.numberStatus
                ? "orders.modify.edit.numberSuccessNotificationMessage"
                : "orders.modify.edit.orderSuccessNotificationMessage"
            })
          );
          closeModal();
          props.onActionExecuted && props.onActionExecuted();
        })
        .catch((error) => {
          handleError(error);
          if (isMounted.current) {
            const errorsResult = getFieldErrors(error.fieldErrors);
            setErrors(errorsResult);
          }
        })
        .finally(() => {
          if (isMounted.current) {
            setShowLoadingIndicator(false);
          }
        });
    }
  };

  return (
    <Modal className={isMixNetworkUrl ? "modal-dialog-centered modal-sm" : "modal-dialog-centered modal-lg" } isOpen={true}>
      <div className="modal-header">
        <h5 className="modal-title">
          <FormattedMessage id="orders.modify.edit.title" />
        </h5>
        <CloseButton close={props.closeModal} />
      </div>
      <Form onSubmit={(e) => handleSubmit(e)}>
        <div className="modal-body">
          {!isMixNetworkUrl && (
          <> 
          <Row>
            {props.numberStatus ? (
              <PhoneNumberInput
                formGroupClassName="col-lg-6"
                labelTranslationId="orders.modify.edit.tnRange"
                placeholderTranslationId="orders.modify.edit.tnRange.placeholder"
                value={portInModify.phoneNumberRange}
                handleInputChange={(value: string) =>
                  setPortInModify({ ...portInModify, phoneNumberRange: value })
                }
                errorHandler={getErrorHandler(nameOf<PortInModify>("phoneNumberRange"))}
              />
            ) : (
              <TextFormInput
                formGroupClassName="col-lg-6"
                labelTranslationId="orders.modify.edit.orderId"
                value={props.order.orderId}
                readonly
              />
            )}
          </Row>
          <Row>
            <TextFormInput
              formGroupClassName="col-lg-6"
              labelTranslationId="orders.modify.edit.spId"
              value={portInModify.portInModifyModel.spId}
              handleInputChange={(value: string) =>
                setPortInModify({
                  ...portInModify,
                  portInModifyModel: { ...portInModify.portInModifyModel, spId: value }
                })
              }
              errorHandler={getErrorHandler(
                nameOf<PortInModify, ModifyModel>("portInModifyModel", "spId")
              )}
            />
            <TextFormInput
              formGroupClassName="col-lg-6"
              labelTranslationId="orders.modify.edit.oldSpId"
              value={portInModify.portInModifyModel.oldSpId}
              handleInputChange={(value: string) =>
                setPortInModify({
                  ...portInModify,
                  portInModifyModel: { ...portInModify.portInModifyModel, oldSpId: value }
                })
              }
              errorHandler={getErrorHandler(
                nameOf<PortInModify, ModifyModel>("portInModifyModel", "oldSpId")
              )}
            />
          </Row>
          <Row>
            <TextFormInput
              formGroupClassName="col-lg-6"
              labelTranslationId="orders.modify.edit.lrn"
              value={portInModify.portInModifyModel.lrn}
              handleInputChange={(value: string) =>
                setPortInModify({
                  ...portInModify,
                  portInModifyModel: { ...portInModify.portInModifyModel, lrn: value }
                })
              }
              errorHandler={getErrorHandler(
                nameOf<PortInModify, ModifyModel>("portInModifyModel", "lrn")
              )}
            />
            <TextFormInput
              formGroupClassName="col-lg-6"
              labelTranslationId="orders.modify.edit.billingId"
              value={portInModify.portInModifyModel.billingId}
              handleInputChange={(value: string) =>
                setPortInModify({
                  ...portInModify,
                  portInModifyModel: { ...portInModify.portInModifyModel, billingId: value }
                })
              }
              errorHandler={getErrorHandler(
                nameOf<PortInModify, ModifyModel>("portInModifyModel", "billingId")
              )}
            />
          </Row>
          <Row>
            <DateTimeFormInput
              className="col-lg-6"
              labelTranslationId="orders.modify.edit.dueDate"
              value={portInModify.portInModifyModel.dueDate}
              handleInputChange={(value: Moment) =>
                setPortInModify({
                  ...portInModify,
                  portInModifyModel: { ...portInModify.portInModifyModel, dueDate: value }
                })
              }
              errorHandler={getErrorHandler(
                nameOf<PortInModify, ModifyModel>("portInModifyModel", "dueDate")
              )}
            />
            <DateTimeFormInput
              className="col-lg-6"
              labelTranslationId="orders.modify.edit.dueTime"
              value={portInModify.portInModifyModel.dueDate}
              isTimeInput
              handleInputChange={(value: Moment) =>
                setPortInModify({
                  ...portInModify,
                  portInModifyModel: { ...portInModify.portInModifyModel, dueDate: value }
                })
              }
              errorHandler={getErrorHandler(
                nameOf<PortInModify, ModifyModel>("portInModifyModel", "dueTime")
              )}
            />
          </Row>
          <Row>
            <TextFormInput
              formGroupClassName="col-lg-6"
              labelTranslationId="orders.modify.edit.userLocationType"
              value={portInModify.portInModifyModel.userLocationType}
              handleInputChange={(value: string) =>
                setPortInModify({
                  ...portInModify,
                  portInModifyModel: { ...portInModify.portInModifyModel, userLocationType: value }
                })
              }
              errorHandler={getErrorHandler(
                nameOf<PortInModify, ModifyModel>("portInModifyModel", "userLocationType")
              )}
            />
            <TextFormInput
              formGroupClassName="col-lg-6"
              labelTranslationId="orders.modify.edit.userLocationValue"
              value={portInModify.portInModifyModel.userLocationValue}
              handleInputChange={(value: string) =>
                setPortInModify({
                  ...portInModify,
                  portInModifyModel: { ...portInModify.portInModifyModel, userLocationValue: value }
                })
              }
              errorHandler={getErrorHandler(
                nameOf<PortInModify, ModifyModel>("portInModifyModel", "userLocationValue")
              )}
            />
          </Row>
          <h4>
            <FormattedMessage id="orders.modify.edit.networkData.header" />
          </h4>
          <Row>
            <TextFormInput
              formGroupClassName="col-lg-6"
              labelTranslationId="orders.modify.edit.networkData.classDpc"
              readonly = {isMixNetworkUrl}
              value={
                portInModify.portInModifyModel.networkData
                  ? portInModify.portInModifyModel.networkData.classDpc
                  : ""
              }
              handleInputChange={(value: string) =>
                setPortInModify({
                  ...portInModify,
                  portInModifyModel: {
                    ...portInModify.portInModifyModel,
                    networkData: portInModify.portInModifyModel.networkData
                      ? { ...portInModify.portInModifyModel.networkData, classDpc: value }
                      : { ...DefaultDpcSsnInfo, classDpc: value }
                  }
                })
              }
              errorHandler={getErrorHandler(
                nameOf<PortInModify, ModifyModel, DpcSsnInfo>(
                  "portInModifyModel",
                  "networkData",
                  "classDpc"
                )
              )}
            />
            <TextFormInput
              formGroupClassName="col-lg-6"
              labelTranslationId="orders.modify.edit.networkData.classSsn"
              readonly = {isMixNetworkUrl}
              value={
                portInModify.portInModifyModel.networkData
                  ? portInModify.portInModifyModel.networkData.classSsn
                  : ""
              }
              handleInputChange={(value: string) =>
                setPortInModify({
                  ...portInModify,
                  portInModifyModel: {
                    ...portInModify.portInModifyModel,
                    networkData: portInModify.portInModifyModel.networkData
                      ? { ...portInModify.portInModifyModel.networkData, classSsn: value }
                      : { ...DefaultDpcSsnInfo, classSsn: value }
                  }
                })
              }
              errorHandler={getErrorHandler(
                nameOf<PortInModify, ModifyModel, DpcSsnInfo>(
                  "portInModifyModel",
                  "networkData",
                  "classSsn"
                )
              )}
            />
          </Row>
          <Row>
            <TextFormInput
              formGroupClassName="col-lg-6"
              labelTranslationId="orders.modify.edit.networkData.lidbDpc"
              readonly = {isMixNetworkUrl}
              value={
                portInModify.portInModifyModel.networkData
                  ? portInModify.portInModifyModel.networkData.lidbDpc
                  : ""
              }
              handleInputChange={(value: string) =>
                setPortInModify({
                  ...portInModify,
                  portInModifyModel: {
                    ...portInModify.portInModifyModel,
                    networkData: portInModify.portInModifyModel.networkData
                      ? { ...portInModify.portInModifyModel.networkData, lidbDpc: value }
                      : { ...DefaultDpcSsnInfo, lidbDpc: value }
                  }
                })
              }
              errorHandler={getErrorHandler(
                nameOf<PortInModify, ModifyModel, DpcSsnInfo>(
                  "portInModifyModel",
                  "networkData",
                  "lidbDpc"
                )
              )}
            />
            <TextFormInput
              formGroupClassName="col-lg-6"
              labelTranslationId="orders.modify.edit.networkData.lidbSsn"
              readonly = {isMixNetworkUrl}
              value={
                portInModify.portInModifyModel.networkData
                  ? portInModify.portInModifyModel.networkData.lidbSsn
                  : ""
              }
              handleInputChange={(value: string) =>
                setPortInModify({
                  ...portInModify,
                  portInModifyModel: {
                    ...portInModify.portInModifyModel,
                    networkData: portInModify.portInModifyModel.networkData
                      ? { ...portInModify.portInModifyModel.networkData, lidbSsn: value }
                      : { ...DefaultDpcSsnInfo, lidbSsn: value }
                  }
                })
              }
              errorHandler={getErrorHandler(
                nameOf<PortInModify, ModifyModel, DpcSsnInfo>(
                  "portInModifyModel",
                  "networkData",
                  "lidbSsn"
                )
              )}
            />
          </Row>
          <Row>
            <TextFormInput
              formGroupClassName="col-lg-6"
              labelTranslationId="orders.modify.edit.networkData.isvmDpc"
              readonly = {isMixNetworkUrl}
              value={
                portInModify.portInModifyModel.networkData
                  ? portInModify.portInModifyModel.networkData.isvmDpc
                  : ""
              }
              handleInputChange={(value: string) =>
                setPortInModify({
                  ...portInModify,
                  portInModifyModel: {
                    ...portInModify.portInModifyModel,
                    networkData: portInModify.portInModifyModel.networkData
                      ? { ...portInModify.portInModifyModel.networkData, isvmDpc: value }
                      : { ...DefaultDpcSsnInfo, isvmDpc: value }
                  }
                })
              }
              errorHandler={getErrorHandler(
                nameOf<PortInModify, ModifyModel, DpcSsnInfo>(
                  "portInModifyModel",
                  "networkData",
                  "isvmDpc"
                )
              )}
            />
            <TextFormInput
              formGroupClassName="col-lg-6"
              labelTranslationId="orders.modify.edit.networkData.isvmSsn"
              readonly = {isMixNetworkUrl}
              value={
                portInModify.portInModifyModel.networkData
                  ? portInModify.portInModifyModel.networkData.isvmSsn
                  : ""
              }
              handleInputChange={(value: string) =>
                setPortInModify({
                  ...portInModify,
                  portInModifyModel: {
                    ...portInModify.portInModifyModel,
                    networkData: portInModify.portInModifyModel.networkData
                      ? { ...portInModify.portInModifyModel.networkData, isvmSsn: value }
                      : { ...DefaultDpcSsnInfo, isvmSsn: value }
                  }
                })
              }
              errorHandler={getErrorHandler(
                nameOf<PortInModify, ModifyModel, DpcSsnInfo>(
                  "portInModifyModel",
                  "networkData",
                  "isvmSsn"
                )
              )}
            />
          </Row>
          <Row>
            <TextFormInput
              formGroupClassName="col-lg-6"
              labelTranslationId="orders.modify.edit.networkData.cnamDpc"
              readonly = {isMixNetworkUrl}
              value={
                portInModify.portInModifyModel.networkData
                  ? portInModify.portInModifyModel.networkData.cnamDpc
                  : ""
              }
              handleInputChange={(value: string) =>
                setPortInModify({
                  ...portInModify,
                  portInModifyModel: {
                    ...portInModify.portInModifyModel,
                    networkData: portInModify.portInModifyModel.networkData
                      ? { ...portInModify.portInModifyModel.networkData, cnamDpc: value }
                      : { ...DefaultDpcSsnInfo, cnamDpc: value }
                  }
                })
              }
              errorHandler={getErrorHandler(
                nameOf<PortInModify, ModifyModel, DpcSsnInfo>(
                  "portInModifyModel",
                  "networkData",
                  "cnamDpc"
                )
              )}
            />
            <TextFormInput
              formGroupClassName="col-lg-6"
              labelTranslationId="orders.modify.edit.networkData.cnamSsn"
              readonly = {isMixNetworkUrl}
              value={
                portInModify.portInModifyModel.networkData
                  ? portInModify.portInModifyModel.networkData.cnamSsn
                  : ""
              }
              handleInputChange={(value: string) =>
                setPortInModify({
                  ...portInModify,
                  portInModifyModel: {
                    ...portInModify.portInModifyModel,
                    networkData: portInModify.portInModifyModel.networkData
                      ? { ...portInModify.portInModifyModel.networkData, cnamSsn: value }
                      : { ...DefaultDpcSsnInfo, cnamSsn: value }
                  }
                })
              }
              errorHandler={getErrorHandler(
                nameOf<PortInModify, ModifyModel, DpcSsnInfo>(
                  "portInModifyModel",
                  "networkData",
                  "cnamSsn"
                )
              )}
            />
          </Row>
          <Row>
            <TextFormInput
              formGroupClassName="col-lg-6"
              labelTranslationId="orders.modify.edit.networkData.wsmscDpc"
              readonly = {isMixNetworkUrl}
              value={
                portInModify.portInModifyModel.networkData
                  ? portInModify.portInModifyModel.networkData.wsmscDpc
                  : ""
              }
              handleInputChange={(value: string) =>
                setPortInModify({
                  ...portInModify,
                  portInModifyModel: {
                    ...portInModify.portInModifyModel,
                    networkData: portInModify.portInModifyModel.networkData
                      ? { ...portInModify.portInModifyModel.networkData, wsmscDpc: value }
                      : { ...DefaultDpcSsnInfo, wsmscDpc: value }
                  }
                })
              }
              errorHandler={getErrorHandler(
                nameOf<PortInModify, ModifyModel, DpcSsnInfo>(
                  "portInModifyModel",
                  "networkData",
                  "wsmscDpc"
                )
              )}
            />
            <TextFormInput
              formGroupClassName="col-lg-6"
              labelTranslationId="orders.modify.edit.networkData.wsmscSsn"
              readonly = {isMixNetworkUrl}
              value={
                portInModify.portInModifyModel.networkData
                  ? portInModify.portInModifyModel.networkData.wsmscSsn
                  : ""
              }
              handleInputChange={(value: string) =>
                setPortInModify({
                  ...portInModify,
                  portInModifyModel: {
                    ...portInModify.portInModifyModel,
                    networkData: portInModify.portInModifyModel.networkData
                      ? { ...portInModify.portInModifyModel.networkData, wsmscSsn: value }
                      : { ...DefaultDpcSsnInfo, wsmscSsn: value }
                  }
                })
              }
              errorHandler={getErrorHandler(
                nameOf<PortInModify, ModifyModel, DpcSsnInfo>(
                  "portInModifyModel",
                  "networkData",
                  "wsmscSsn"
                )
              )}
            />
          </Row>
          <h4>
            <FormattedMessage id="orders.modify.edit.optionalData.header" />
          </h4>
          <Row>
            <TextFormInput
              formGroupClassName="col-lg-6"
              labelTranslationId="orders.modify.edit.optionalData.altSpId"
              value={
                portInModify.portInModifyModel.optionalData
                  ? portInModify.portInModifyModel.optionalData.altSpId
                  : ""
              }
              handleInputChange={(value: string) =>
                setPortInModify({
                  ...portInModify,
                  portInModifyModel: {
                    ...portInModify.portInModifyModel,
                    optionalData: portInModify.portInModifyModel.optionalData
                      ? { ...portInModify.portInModifyModel.optionalData, altSpId: value }
                      : { ...DefaultOptionalDataInfo, altSpId: value }
                  }
                })
              }
              errorHandler={getErrorHandler(
                nameOf<PortInModify, ModifyModel, OptionalDataInfo>(
                  "portInModifyModel",
                  "optionalData",
                  "altSpId"
                )
              )}
            />
            <TextFormInput
              formGroupClassName="col-lg-6"
              labelTranslationId="orders.modify.edit.optionalData.lastAltSpId"
              value={
                portInModify.portInModifyModel.optionalData
                  ? portInModify.portInModifyModel.optionalData.lastAltSpId
                  : ""
              }
              handleInputChange={(value: string) =>
                setPortInModify({
                  ...portInModify,
                  portInModifyModel: {
                    ...portInModify.portInModifyModel,
                    optionalData: portInModify.portInModifyModel.optionalData
                      ? { ...portInModify.portInModifyModel.optionalData, lastAltSpId: value }
                      : { ...DefaultOptionalDataInfo, lastAltSpId: value }
                  }
                })
              }
              errorHandler={getErrorHandler(
                nameOf<PortInModify, ModifyModel, OptionalDataInfo>(
                  "portInModifyModel",
                  "optionalData",
                  "lastAltSpId"
                )
              )}
            />
          </Row>
          <Row>
            <TextFormInput
              formGroupClassName="col-lg-6"
              labelTranslationId="orders.modify.edit.optionalData.altBillingId"
              value={
                portInModify.portInModifyModel.optionalData
                  ? portInModify.portInModifyModel.optionalData.altBillingId
                  : ""
              }
              handleInputChange={(value: string) =>
                setPortInModify({
                  ...portInModify,
                  portInModifyModel: {
                    ...portInModify.portInModifyModel,
                    optionalData: portInModify.portInModifyModel.optionalData
                      ? { ...portInModify.portInModifyModel.optionalData, altBillingId: value }
                      : { ...DefaultOptionalDataInfo, altBillingId: value }
                  }
                })
              }
              errorHandler={getErrorHandler(
                nameOf<PortInModify, ModifyModel, OptionalDataInfo>(
                  "portInModifyModel",
                  "optionalData",
                  "altBillingId"
                )
              )}
            />
            <TextFormInput
              formGroupClassName="col-lg-6"
              labelTranslationId="orders.modify.edit.optionalData.altUserLocationType"
              value={
                portInModify.portInModifyModel.optionalData
                  ? portInModify.portInModifyModel.optionalData.altUserLocationType
                  : ""
              }
              handleInputChange={(value: string) =>
                setPortInModify({
                  ...portInModify,
                  portInModifyModel: {
                    ...portInModify.portInModifyModel,
                    optionalData: portInModify.portInModifyModel.optionalData
                      ? {
                        ...portInModify.portInModifyModel.optionalData,
                        altUserLocationType: value
                      }
                      : { ...DefaultOptionalDataInfo, altUserLocationType: value }
                  }
                })
              }
              errorHandler={getErrorHandler(
                nameOf<PortInModify, ModifyModel, OptionalDataInfo>(
                  "portInModifyModel",
                  "optionalData",
                  "altUserLocationType"
                )
              )}
            />
          </Row>
          <Row>
            <TextFormInput
              formGroupClassName="col-lg-6"
              labelTranslationId="orders.modify.edit.optionalData.altUserLocationValue"
              value={
                portInModify.portInModifyModel.optionalData
                  ? portInModify.portInModifyModel.optionalData.altUserLocationValue
                  : ""
              }
              handleInputChange={(value: string) =>
                setPortInModify({
                  ...portInModify,
                  portInModifyModel: {
                    ...portInModify.portInModifyModel,
                    optionalData: portInModify.portInModifyModel.optionalData
                      ? {
                        ...portInModify.portInModifyModel.optionalData,
                        altUserLocationValue: value
                      }
                      : { ...DefaultOptionalDataInfo, altUserLocationValue: value }
                  }
                })
              }
              errorHandler={getErrorHandler(
                nameOf<PortInModify, ModifyModel, OptionalDataInfo>(
                  "portInModifyModel",
                  "optionalData",
                  "altUserLocationValue"
                )
              )}
            />
            <TextFormInput
              formGroupClassName="col-lg-6"
              labelTranslationId="orders.modify.edit.optionalData.voiceUri"
              value={
                portInModify.portInModifyModel.optionalData
                  ? portInModify.portInModifyModel.optionalData.voiceUri
                  : ""
              }
              handleInputChange={(value: string) =>
                setPortInModify({
                  ...portInModify,
                  portInModifyModel: {
                    ...portInModify.portInModifyModel,
                    optionalData: portInModify.portInModifyModel.optionalData
                      ? { ...portInModify.portInModifyModel.optionalData, voiceUri: value }
                      : { ...DefaultOptionalDataInfo, voiceUri: value }
                  }
                })
              }
              errorHandler={getErrorHandler(
                nameOf<PortInModify, ModifyModel, OptionalDataInfo>(
                  "portInModifyModel",
                  "optionalData",
                  "voiceUri"
                )
              )}
            />
          </Row>
          <Row>
            <TextFormInput
              formGroupClassName="col-lg-6"
              labelTranslationId="orders.modify.edit.optionalData.mmsUri"
              value={
                portInModify.portInModifyModel.optionalData
                  ? portInModify.portInModifyModel.optionalData.mmsUri
                  : ""
              }
              handleInputChange={(value: string) =>
                setPortInModify({
                  ...portInModify,
                  portInModifyModel: {
                    ...portInModify.portInModifyModel,
                    optionalData: portInModify.portInModifyModel.optionalData
                      ? { ...portInModify.portInModifyModel.optionalData, mmsUri: value }
                      : { ...DefaultOptionalDataInfo, mmsUri: value }
                  }
                })
              }
              errorHandler={getErrorHandler(
                nameOf<PortInModify, ModifyModel, OptionalDataInfo>(
                  "portInModifyModel",
                  "optionalData",
                  "mmsUri"
                )
              )}
            />
            <TextFormInput
              formGroupClassName="col-lg-6"
              labelTranslationId="orders.modify.edit.optionalData.smsUri"
              value={
                portInModify.portInModifyModel.optionalData
                  ? portInModify.portInModifyModel.optionalData.smsUri
                  : ""
              }
              handleInputChange={(value: string) =>
                setPortInModify({
                  ...portInModify,
                  portInModifyModel: {
                    ...portInModify.portInModifyModel,
                    optionalData: portInModify.portInModifyModel.optionalData
                      ? { ...portInModify.portInModifyModel.optionalData, smsUri: value }
                      : { ...DefaultOptionalDataInfo, smsUri: value }
                  }
                })
              }
              errorHandler={getErrorHandler(
                nameOf<PortInModify, ModifyModel, OptionalDataInfo>(
                  "portInModifyModel",
                  "optionalData",
                  "smsUri"
                )
              )}
            />
          </Row>
          </>
          )}
          {isMixNetworkUrl && (
            <>
              <Row>
              {props.numberStatus ? (
                <PhoneNumberInput
                  formGroupClassName="col-lg-12"
                  labelTranslationId="orders.modify.edit.tnRange"
                  placeholderTranslationId="orders.modify.edit.tnRange.placeholder"
                  value={portInModify.phoneNumberRange}
                  handleInputChange={(value: string) =>
                    setPortInModify({ ...portInModify, phoneNumberRange: value })
                  }
                  errorHandler={getErrorHandler(nameOf<PortInModify>("phoneNumberRange"))}
                />
              ) : (
                <TextFormInput
                  formGroupClassName="col-lg-12"
                  labelTranslationId="orders.modify.edit.orderId"
                  value={props.order.orderId}
                  readonly
                />
              )}
            </Row>
            <Row>
              <DateTimeFormInput
                className="col-lg-12"
                labelTranslationId="orders.portIn.stepTwo.autoActivation"
                value={portInModify.portInModifyModel.dueDate}
                handleInputChange={(value: Moment) =>
                  setPortInModify({
                    ...portInModify,
                    portInModifyModel: { ...portInModify.portInModifyModel, dueDate: value }
                  })
                }
                errorHandler={getErrorHandler(
                  nameOf<PortInModify, ModifyModel>("portInModifyModel", "dueDate")
                )}
              />
            </Row>
          </>
          )}
          <Row>
            {
              isSunday(portInModify.portInModifyModel.dueDate) && (
                <NpacMaintenanceAlert
                  classNames="pc-npac-maintenance-modal-warning" />
              )}
          </Row>
        </div>
        <div className="modal-footer">
          <Button color="link" type="button" className={`${theme === "light" ? "lblue-color" : ""}`} onClick={() => props.closeModal()}>
            <FormattedMessage id="orders.modify.edit.cancelButton" />
          </Button>
          <Button color="primary" type="submit" className={`ml-auto ${theme === "light" ? "bg-lblue no-border" : ""}`} disabled={showLoadingIndicator}>
            {showLoadingIndicator && <i className="fas fa-spinner fa-spin mr-2" />}
            <FormattedMessage id="orders.modify.edit.submitButton" />
          </Button>
        </div>
      </Form>
    </Modal>
  );
}

const validateModify = (modify: PortInModify, orderStatus: string, numberStatus?: NumberStatusDto) => {
  const errors: Errors = {};

  if (numberStatus) {
    if (!modify.phoneNumberRange) {
      AddError(
        errors,
        nameOf<PortInModify>("phoneNumberRange"),
        "orders.modify.edit.tnRange.required"
      );
    } else if (!isPhoneNumber(modify.phoneNumberRange) && !isRange(modify.phoneNumberRange)) {
      AddError(
        errors,
        nameOf<PortInModify>("phoneNumberRange"),
        "orders.modify.edit.tnRange.invalid"
      );
    }
  }

  if (orderStatus === SvRequestStatus.Failed && modify.portInModifyModel && !modify.portInModifyModel.dueDate) {
    AddError(
      errors,
      nameOf<PortInModify, ModifyModel>("portInModifyModel", "dueDate"),
      "orders.modify.edit.dueDate.required"
    );
  }

  return errors;
};
