import { Row, Col, Card, CardHeader, CardTitle, CardBody, Button, Label } from "reactstrap";
import React, { useState, useRef, useContext } from "react";
import { FormattedMessage } from "react-intl";
import DateTimeFormInput from "components/framework/forms/DateTimeFormInput";
import { Moment } from "moment";
import { nameOf } from "services/util/ObjectUtil";
import { ExportModel, DefaultExportModel } from "./types/ExportModel";
import { CSVLink } from "react-csv";
import { AppConfiguration } from "AppConfiguration";
import { ExportDto, DefaultExportDto, ExportActiveTnsDto, DefaultExportActiveTnsDto } from "services/apis/types/reports/ExportDto";
import { getUtcDate } from "services/util/DateUtil";
import { useErrors } from "services/customHooks/useErrors";
import { useIsMounted } from "services/customHooks/useIsMounted";
import { ReportsApi } from "services/apis/ReportsApi";
import { handleError, getFieldErrors } from "services/util/ApiUtil";
import DropdownInput from "components/framework/forms/DropdownInput";
import { PortApi } from "services/apis/PortApi";
import { OrderApi } from "services/apis/OrderApi";
import { DefaultExportPortingReportDto, ExportPortingReportDto } from "services/apis/types/reports/PortingReportDto";
import { report } from "process";
import { AppContext } from "services/appContext/AppContext";

export default function ExportCard() {
  const { setErrors, getErrorHandler } = useErrors();
  const [showExportLoadingIndicator, setShowExportLoadingIndicator] = useState(false);
  const [exportModel, setExportModel] = useState<ExportModel>(DefaultExportModel);
  const [exportDtos, setExportDtos] = useState<ExportDto[]>([]);
  const [exportActiveTnsDtos, setExportActiveTnsDtos] = useState<ExportActiveTnsDto[]>([])
  const [exportPortingReportDtos, setExportPortingReportDtos] = useState<ExportPortingReportDto[]>([])
  const isMounted = useIsMounted();
  const csvRef = useRef<any>();
  const [reportType, setReportType] = useState<string>("activity");
  const { appContext } = useContext(AppContext);
  const theme = appContext.theme;

  const reportTypeOptions = [{ key: "Activity", value: "activity" }, { key: "Returned Tns", value: "returnedTns" }, { key: "Porting Report", value: "portingReport" } ]


  const handleExportClick = (reportType) => {
    switch (reportType) {
      case "activity":
        handleExport();
        break;
      case "returnedTns":
        handleActiveTnsExport();
        break;
      case "portingReport":
        handlePortingReport();
        break;
      default:
        // Handle the case when reportType doesn't match any options
        break;
    }
  };
  const handleExport = () => {
    setShowExportLoadingIndicator(true);

    ReportsApi.export(exportModel.from.toDate(), exportModel.to.toDate())
      .then((result) => {
        if (isMounted.current) {
          setExportDtos(result);  
          csvRef.current.link.click();
        }
      })
      .catch((error) => {
        handleError(error);
        if (isMounted.current) {
          const errorsResult = getFieldErrors(error.fieldErrors);
          setErrors(errorsResult);
        }
      })
      .finally(() => {
        if (isMounted.current) {
          setShowExportLoadingIndicator(false);
        }
      });
  };

  const handleActiveTnsExport = () => {
    setShowExportLoadingIndicator(true);
    PortApi.getpooledblocktnsbyreturnstospid(exportModel.from.toDate(), exportModel.to.toDate())
      .then((result) => {
        if (isMounted.current) {
          setExportActiveTnsDtos(result);
          csvRef.current.link.click();
        }
      })
      .catch((error) => {
        handleError(error);
        if (isMounted.current) {
          const errorsResult = getFieldErrors(error.fieldErrors);
          setErrors(errorsResult);
        }
      })
      .finally(() => {
        if (isMounted.current) {
          setShowExportLoadingIndicator(false);
        }
      });
  }

  const handlePortingReport = () => {
    setShowExportLoadingIndicator(true);
    OrderApi.portingReport(exportModel.from.toDate(), exportModel.to.toDate())
      .then((result) => {
        if (isMounted.current) {
          setExportPortingReportDtos(result);
          csvRef.current.link.click();
        }
      })
      .catch((error) => {
        handleError(error);
        if (isMounted.current) {
          const errorsResult = getFieldErrors(error.fieldErrors);
          setErrors(errorsResult);
        }
      })
      .finally(() => {
        if (isMounted.current) {
          setShowExportLoadingIndicator(false);
        }
      });
  }

  const getCsvActionData = (reportType) => {
    switch (reportType) {
      case "activity":
        return getCsvData(exportDtos);
      case "returnedTns":
        return getActiveTnsCsvData(exportActiveTnsDtos);
      case "portingReport":
        return getPortingReport(exportPortingReportDtos);
      default:
        // Handle the case when reportType doesn't match any options
        return ''; // Return a default value (an empty string) or handle it as needed
    }
  }

  const reportFileNames = (reportType) => {
    switch (reportType) {
      case "activity":
        return `transactions-${exportModel.from.format(
          AppConfiguration.dateFormat
        )}-${exportModel.to.format(AppConfiguration.dateFormat)}.csv`;
      case "returnedTns":
        return `returned Tns-${exportModel.from.format(
          AppConfiguration.dateFormat
        )}-${exportModel.to.format(AppConfiguration.dateFormat)}.csv`;
      case "portingReport":
        return `Porting Reports-${exportModel.from.format(
          AppConfiguration.dateFormat
        )}-${exportModel.to.format(AppConfiguration.dateFormat)}.csv`;
      default:
        // Handle the case when reportType doesn't match any options
        return ''; // Return a default value (an empty string) or handle it as needed
    }
  }
  return (
    <Row>
      <Col md="12">
        <Card>
          <CardHeader>
            <CardTitle tag="h6" className="text-uppercase text-light mb-0">
              <FormattedMessage id="reports.export" />
            </CardTitle>
            <span className="h2 font-weight-bold mb-0">
              <FormattedMessage id="reports.export.subtitle" />
            </span>
          </CardHeader>
          <CardBody>
            <Row>
              <Col md="3">
                <div className="col-lg- mb-2">
                <Label className="form-control-label">
                  <FormattedMessage id={"reports.export.reportType"} />
                </Label>
                <DropdownInput
                  sm
                  onChange={(e) => { setReportType(e.target.value) }}
                  value={reportType}
                  options={reportTypeOptions}
                />
                </div>
              </Col>
              <Col md="2">
                <DateTimeFormInput
                  className="col-lg-12"
                  labelTranslationId="reports.export.fromDate"
                  value={exportModel.from}
                  showModalOnTop
                  handleInputChange={(value: Moment) =>
                    setExportModel({ ...exportModel, from: value })
                  }
                  errorHandler={getErrorHandler(nameOf<ExportModel>("from"))}
                />
              </Col>
              <Col md="2">
                <DateTimeFormInput
                  className="col-lg-12"
                  labelTranslationId="reports.export.toDate"
                  value={exportModel.to}
                  showModalOnTop
                  handleInputChange={(value: Moment) =>
                    setExportModel({ ...exportModel, to: value })
                  }
                  errorHandler={getErrorHandler(nameOf<ExportModel>("to"))}
                />
              </Col>
              <Col md="2" className="d-flex align-items-center offset-2">
                <CSVLink
                  ref={csvRef}
                  // data={reportType === "returnedTns" ? getActiveTnsCsvData(exportActiveTnsDtos) : getCsvData(exportDtos)}
                  data= {getCsvActionData(reportType)}
                  target="_blank"
                  className="hidden"
                  filename={reportFileNames(reportType)}></CSVLink>
                <Button
                  color="primary"
                  type="button"
                  onClick={()=> handleExportClick(reportType)}
                  className={`ml-auto ${theme === "light" ? "bg-lblue no-border" : ""}`}
                  disabled={showExportLoadingIndicator}>
                  {showExportLoadingIndicator && <i className="fas fa-spinner fa-spin mr-2" />}
                  <FormattedMessage id="reports.export.submitButton" />
                </Button>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
}

const getCsvData = (exportDtos: ExportDto[]) => {
  return exportDtos.length > 0
    ? exportDtos.map((x) => {
      return {
        ...x,
        transactionDate: getUtcDate(x.transactionDate).format(AppConfiguration.fullDateFormat)
      };
    })
    : [{ ...DefaultExportDto, transactionCount: "", transactionDate: "" }];
};

const getActiveTnsCsvData = (exportDtos: ExportActiveTnsDto[]) => {
  return exportDtos.length > 0
    ? exportDtos.map((x) => {
      return {
        ...x
      };
    })
    : [{ ...DefaultExportActiveTnsDto}];
}

const getPortingReport = (exportDtos: ExportPortingReportDto[]) => {
  return exportDtos.length > 0
    ? exportDtos.map((x) => {
      return {
        ...x
      };
    })
    : [{ ...DefaultExportPortingReportDto}];
}

