import React, { useState, useEffect, useContext } from "react";
import { Card, CardBody, Button, CardHeader, Input, Row, Col } from "reactstrap";
import { FormattedMessage } from "react-intl";
import DropzoneArea from "../DropzoneArea";
import { getUniqueElements } from "services/util/ArrayUtil";
import classNames from "classnames";
import { useIsMounted } from "services/customHooks/useIsMounted";
import { ServiceProviderApi } from "services/apis/ServiceProviderApi";
import { SpInfo } from "services/apis/types/serviceProvider/SpInfo";
import { handleError } from "services/util/ApiUtil";
import { LoadingIndicator } from "components/framework/loadingIndicator/LoadingIndicator";
import Select2Dropdown from "./Select2Dropdown";
import { usePastedNumberFormatter } from "services/customHooks/usePastedNumberFormatter";
import ErrorList from "components/framework/errorHandling/ErrorList";
import { ApiError } from "components/common/types/ApiError";
import { AppContext } from "services/appContext/AppContext";

type Props = {
  generateCardCallback: (numberInputValue: string, spId: string, providerName: string) => void;
  noOfPhoneNumbers: number;
  validationMessages: ApiError[];
};

export default function PortOutValidationSection(props: Props) {
  const [inputValue, setInputValue] = useState("");
  const [loadingIndicator, setLoadingIndicator] = useState(false);
  const [focusedSearchInput, setFocusedSearchInput] = useState(false);
  const [selectedSpId, setSelectedSpId] = useState("");
  const [spIds, setSpids] = useState<SpInfo[]>([]);
  const [showSpidRequiredValidationMessage, setShowSpidRequiredValidationMessage] = useState(false);
  const { appContext } = useContext(AppContext);
  const theme = appContext.theme;
  const isMounted = useIsMounted();
  const onPaste = usePastedNumberFormatter(inputValue, (formattedValue) =>
    setInputValue(formattedValue)
  );

  const handleSpIdInputChange = (value: string) => {
    setSelectedSpId(value);
    setShowSpidRequiredValidationMessage(false);
  };

  useEffect(() => {
    setLoadingIndicator(true);
    ServiceProviderApi.list()
      .then((result) => {
        if (isMounted.current) {
          result = result.sort((x, y) => (x.spId < y.spId ? -1 : 1));
          setSpids(result);
        }
      })
      .catch((error) => handleError(error))
      .finally(() => {
        if (isMounted.current) {
          setLoadingIndicator(false);
        }
      });
  }, [isMounted]);

  const handleDropzoneResult = (result: string[]) => {
    setInputValue((previousState) =>
      getUniqueElements([...previousState.split(",").filter((x) => x), ...result]).join(",")
    );
  };

  const generateCardHandler = () => {
    const spId = spIds.find((x) => x.spId === selectedSpId);

    if (spId) {
      props.generateCardCallback(inputValue, spId.spId, spId.name);
    } else {
      setShowSpidRequiredValidationMessage(true);
    }
  };

  return (
    <Card>
      <CardHeader>
        <h5 className="h3 mb-0">
          <FormattedMessage id="orders.portOut.stepOne.title" />
        </h5>
      </CardHeader>
      <CardBody>
        <Row>
          <Col lg="5">
            <Input
              className={classNames("input-group-merge pc-search-container mb-0 h-180", {
                focused: focusedSearchInput
              })}
              rows="6"
              type="textarea"
              value={inputValue}
              onChange={(e) => setInputValue(e.currentTarget.value)}
              onFocus={() => setFocusedSearchInput(true)}
              onBlur={() => setFocusedSearchInput(false)}
              onPaste={onPaste}
            />
            <label className="form-control-label">
              <FormattedMessage id="orders.portOut.stepOne.enterNumbersMessage" />
            </label>
          </Col>
          <Col lg="2" className="d-flex align-items-center justify-content-center h-180">
            <span className="shortcut-media avatar rounded-circle bg-gradient-pc-blue text-uppercase">
              <FormattedMessage id="orders.portOut.stepOne.or" />
            </span>
          </Col>
          <Col lg="5">
            <DropzoneArea addNumbersToTextAreaHandler={handleDropzoneResult} />
          </Col>
        </Row>
        <Row>
          <Col lg="5">
            {loadingIndicator ? (
              <LoadingIndicator />
            ) : (
              <Select2Dropdown
                showErrorMessage={showSpidRequiredValidationMessage}
                value={selectedSpId}
                data={[
                  ...spIds.map((x) => {
                    return { id: x.spId, text: `${x.spId} (${x.name})` };
                  })
                ]}
                handleInputChange={handleSpIdInputChange}
              />
            )}
          </Col>
        </Row>
        <Row className="float-right mt-1 pc-btn-card">
          <Col lg="7">
            <Button color="primary" className={`${theme === "light" ? "bg-lblue no-border" : ""}`} onClick={generateCardHandler}>
              <FormattedMessage id="orders.portOut.stepOne.generateCard" />
            </Button>
          </Col>
        </Row>
        <Row className="mt-6">
          <Col lg="12">
            {props.noOfPhoneNumbers > 0 && (
              <label className={`form-control-label ${theme === "light" ? "lblue-color" : "text-primary"}`}>
                <FormattedMessage
                  id="orders.portOut.stepOne.numberDetected"
                  values={{ number: props.noOfPhoneNumbers }}
                />
              </label>
            )}
            <ErrorList errors={props.validationMessages} />
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
}
