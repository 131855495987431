import React, { useState, FormEvent, useContext } from "react";
import {
  Row,
  Col,
  Container,
  DropdownToggle,
  DropdownMenu,
  Button,
  Dropdown,
  Form,
  Input,
  FormGroup,
  InputGroup,
  InputGroupAddon,
  InputGroupText
} from "reactstrap";
import { FormattedMessage, useIntl } from "react-intl";
import { useHistory } from "react-router";
import OrderTable from "./orderTable/OrderTable";
import classNames from "classnames";
import BreadcrumbHeader from "components/common/BreadcrumbHeader";
import Authorize from "components/framework/authorization/Authorize";
import { SoaItemType } from "components/orders/types/SoaItemType";
import DisconnectModal from "../disconnect/DisconnectModal";
import ModifyModal from "../modify/ModifyModal";
import CreateAuditModal from "components/audits/CreateAuditModal";
import { PermissionType } from "services/authorization/PermissionType";
import { EnvironmentUtil } from "services/util/EnvironmentUtil";
import { AppContext } from "services/appContext/AppContext";

const isMixNetworkUrl = EnvironmentUtil.isMixNetwork

export default function OrdersList() {
  const history = useHistory();
  const [showDropdown, setShowDropdown] = useState(false);
  const [, setErrorMessageId] = useState("");
  const [searchInputValue, setSearchInputValue] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const [focusedSearchInput, setFocusedSearchInput] = useState(false);
  const [soaItemType, setSoaItemType] = useState<SoaItemType>();
  const [showModal, setShowModal] = useState(false);
  const intl = useIntl();
  const { appContext } = useContext(AppContext);
  const theme = appContext.theme;

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    const inputValue = searchInputValue.trim();
    const errorMessageIdResult = validateSearchValue();
    setErrorMessageId(errorMessageIdResult);

    if (!errorMessageIdResult) {
      setSearchValue(inputValue);
    }
  };

  const handleSearchChange = (searchValue: string) => {
    setErrorMessageId("");
    setSearchInputValue(searchValue);
  };

  const dropdownToggle = () => setShowDropdown((prevState) => !prevState);

  const handleCreateDropdownItemButtonClick = (soaItemType: SoaItemType) => {
    setSoaItemType(soaItemType);
    setShowModal(true);
    setShowDropdown(false);

    if (soaItemType === SoaItemType.PortIn) {
      history.push("/Orders/PortIn");
    }

    if(soaItemType === SoaItemType.DisasterRecovery) {
      history.push("/Orders/DisasterRecovery")
    }

    if (soaItemType === SoaItemType.PortOut) {
      history.push("/Orders/PortOut");
    }
  };

  return (
    <>
      <BreadcrumbHeader>
        <FormattedMessage id="orders.title" />
      </BreadcrumbHeader>
      <Container className="mb-5">
        <Row>
          <Col md="6" className="offset-md-3">
            <h1 className={`display-3 ${theme === "light" ? "lblue-color" : "pc-soa-order-bg"}`}>
              <FormattedMessage id="orders.title" />
            </h1>
          </Col>
        </Row>
        <Row>
          <Col md="3"></Col>
          <Col md="6" xs="12" className="mb-1">
            <Form onSubmit={handleSubmit}>
              <FormGroup
                className={classNames("input-group-merge pc-search-container mb-0", {
                  focused: focusedSearchInput
                })}>
                <InputGroup className="input-group-merge">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="fas fa-search" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    type="text"
                    className="pc-search"
                    placeholder={intl.formatMessage({ id: "orders.search.placeholder" })}
                    value={searchInputValue}
                    onChange={(e) => handleSearchChange(e.currentTarget.value)}
                    onFocus={() => setFocusedSearchInput(true)}
                    onBlur={() => setFocusedSearchInput(false)}
                  />
                </InputGroup>
                <Button color={`${theme === "dark" ? "danger" : ""}`} type="submit" className={`pc-btn ml-4 ${theme === "light" ? "bg-lblue" : ""}`}>
                  <FormattedMessage id="orders.search" />
                </Button>
              </FormGroup>
            </Form>
          </Col>
          <Col md="3" xs="12" className="mb-1 pl-2">
            <Authorize spidPermissions={[PermissionType.SOAWrite, PermissionType.PreOrderFree]}>
              <div className="pc-create-dropdown">
                <Dropdown isOpen={showDropdown} toggle={dropdownToggle}>
                  <DropdownToggle className="cursor-pointer pb-0 pt-0v" tag="span">
                    <Button color={`${theme === "dark" ? "danger" : ""}`} className={`pc-btn create-btn ${theme === "light" ? "bg-lblue" : ""}`}>
                      <i className="fas fa-plus-square mr-2" />
                      <FormattedMessage id="orders.create" />
                    </Button>
                  </DropdownToggle>
                  <DropdownMenu className={`dropdown-menu-lg dropdown-menu-white ${theme === "light" ? "bg-dropdown text-crow" : "bg-dark text-white pc-no-shadow"}`}>
                    <Row className="shortcuts px-4">
                      <Authorize
                        spidPermissions={[PermissionType.SOAWrite, PermissionType.PreOrderFree]}>
                        <Col
                          className="shortcut-item cursor-pointer"
                          onClick={() => handleCreateDropdownItemButtonClick(SoaItemType.PortIn)}
                          xs="4">
                          <span className="shortcut-media avatar rounded-circle bg-gradient-pc-blue">
                            <i className="fa fa-arrow-left" />
                          </span>
                          <small>
                            <FormattedMessage id="orders.create.portIn" />
                          </small>
                        </Col>
                        {isMixNetworkUrl &&
                          <Col
                            className="shortcut-item cursor-pointer"
                            onClick={() => handleCreateDropdownItemButtonClick(SoaItemType.DisasterRecovery)}
                            xs="4">
                            <span className="shortcut-media avatar rounded-circle bg-gradient-red">
                              <i className="fa fa-exclamation-triangle" />
                            </span>
                            <small>
                              <FormattedMessage id="orders.create.disasterRecovery" />
                            </small>
                          </Col>
                        }
                      </Authorize>
                      {!isMixNetworkUrl && <Authorize spidPermissions={[PermissionType.SOAWrite]}>
                        <Col
                          className="shortcut-item cursor-pointer"
                          onClick={() => handleCreateDropdownItemButtonClick(SoaItemType.PortOut)}
                          xs="4">
                          <span className="shortcut-media avatar rounded-circle bg-gradient-pc-orange">
                            <i className="fa fa-arrow-right" />
                          </span>
                          <small>
                            <FormattedMessage id="orders.create.portOut" />
                          </small>
                        </Col>
                        <Col
                          className="shortcut-item cursor-pointer"
                          onClick={() =>
                            handleCreateDropdownItemButtonClick(SoaItemType.Disconnect)
                          }
                          xs="4">
                          <span className="shortcut-media avatar rounded-circle bg-gradient-red">
                            <i className="fa fa-undo" />
                          </span>
                          <small>
                            <FormattedMessage id="orders.create.disconnect" />
                          </small>
                        </Col>
                      </Authorize>}
                    </Row>
                    {!isMixNetworkUrl && <Authorize spidPermissions={[PermissionType.SOAWrite]}>
                      <Row className="shortcuts px-4">
                        <Col
                          className="shortcut-item cursor-pointer"
                          onClick={() => handleCreateDropdownItemButtonClick(SoaItemType.Modify)}
                          xs="4">
                          <span className="shortcut-media avatar rounded-circle bg-gradient-red">
                            <i className="fa fa-edit" />
                          </span>
                          <small>
                            <FormattedMessage id="orders.create.modify" />
                          </small>
                        </Col>
                        <Col
                          className="shortcut-item cursor-pointer"
                          onClick={() => handleCreateDropdownItemButtonClick(SoaItemType.Audit)}
                          xs="4">
                          <span className="shortcut-media avatar rounded-circle bg-gradient-pc-blue">
                            <i className="fa fa-check-double" />
                          </span>
                          <small>
                            <FormattedMessage id="audits.create.audit" />
                          </small>
                        </Col>
                        <Col
                          className="shortcut-item cursor-pointer"
                          onClick={() => handleCreateDropdownItemButtonClick(SoaItemType.DisasterRecovery)}
                          xs="4">
                          <span className="shortcut-media avatar rounded-circle bg-gradient-red">
                            <i className="fa fa-exclamation-triangle" />
                          </span>
                          <small>
                            <FormattedMessage id="orders.create.disasterRecovery" />
                          </small>
                        </Col>
                      </Row>
                    </Authorize>}
                  </DropdownMenu>
                </Dropdown>
              </div>
            </Authorize>
          </Col>
        </Row>
      </Container>
      <Container fluid>
        <Row>
          <Col md="12">
            <OrderTable search={searchValue} />
            {soaItemType === SoaItemType.Audit && showModal && (
              <CreateAuditModal closeModal={() => setShowModal(false)} />
            )}
            {soaItemType === SoaItemType.Disconnect && showModal && (
              <DisconnectModal closeModal={() => setShowModal(false)} />
            )}
            {soaItemType === SoaItemType.Modify && showModal && (
              <ModifyModal closeModal={() => setShowModal(false)} />
            )}
          </Col>
        </Row>
      </Container>
    </>
  );
}

const validateSearchValue = (): string => {
  return "";
};
