import React, { useContext } from "react";
import classNames from "classnames";
import { AppContext } from "services/appContext/AppContext";

type Props = {
  white?: boolean;
  small?: boolean;
  className?: string;
};

export function LoadingIndicator(props: Props) {  
  const { appContext } = useContext(AppContext);
  const theme = appContext.theme;

  return (
    <div className={classNames("text-center p-4 flex flex-fill", props.className)}>
      <i
        className={classNames("fas fa-spinner fa-spin", {
          "lblue-color": theme === "light",
          "text-white": theme !== "light" && props.white,
          "fa-2x": !props.small
        })}
      />
    </div>
  );
}
