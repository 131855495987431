import React, { useMemo, useEffect, useState, useContext } from "react";
import { useIntl, IntlShape } from "react-intl";
import { NumberApi } from "services/apis/NumberApi";
import { NumberActivity } from "services/apis/types/number/NumberActivity";
import FormatDateTime from "components/framework/date/FormatDateTime";
import { getValueOrEmpty } from "services/util/StringUtil";
import { handleError } from "services/util/ApiUtil";
import { PhoneNumberType } from "services/apis/types/number/PhoneNumberType";
import ReactTable from "components/framework/table/ReactTable";
import SpIdCell from "components/common/SpIdCell";
import { getFormattedUtcDate } from "services/util/DateUtil";
import { AppContext } from "services/appContext/AppContext";

type Props = {
  telephoneNumber: string;
  phoneNumberType: keyof typeof PhoneNumberType;
};

export default function HistoryReportTable(props: Props) {
  const intl = useIntl();
  const [historyData, setHistoryData] = useState<NumberActivity[] | undefined>();
  const { appContext } = useContext(AppContext);
  const theme = appContext.theme;
  const columns = useMemo(
    () =>
      props.phoneNumberType !== PhoneNumberType.TollFree
        ? getWirelineColumns(intl, theme)
        : getTollfreeColumns(intl, theme),
    [intl, props.phoneNumberType, theme]
  );

  useEffect(() => {
    let isMounted = true;

    NumberApi.history(props.telephoneNumber)
      .then((result) => {
        if (isMounted) {
          setHistoryData(result);
        }
      })
      .catch((error) => handleError(error));

    return () => {
      isMounted = false;
    };
  }, [props.telephoneNumber]);

  return (
    <ReactTable
      title={intl.formatMessage({ id: "explore.historyReport.table.title" })}
      data={historyData}
      columns={columns}
      showExport={true}
      hideSearch={true}
      filterMapper={filterMapper}
    />
  );
}

const getWirelineColumns = (intl: IntlShape, theme: string) => [
  {
    Header: intl.formatMessage({ id: "explore.historyReport.table.dateTime" }),
    accessor: "activityDate",
    Cell: (cell: any) => <FormatDateTime utcDate={cell.cell.value} />
  },
  {
    Header: intl.formatMessage({ id: "explore.historyReport.table.spId" }),
    accessor: "spId",
    Cell: (cell: any) => <SpIdCell spId={cell.cell.row.original.spId} labelColor={`${theme === "light" ? "bg-lblue" : ""}`} />
  },
  {
    Header: intl.formatMessage({ id: "explore.historyReport.table.serviceProvider" }),
    accessor: "providerName",
    Cell: (cell: any) => getValueOrEmpty(cell.cell.value)
  },
  {
    Header: intl.formatMessage({ id: "explore.historyReport.table.activity" }),
    accessor: "activity",
    Cell: (cell: any) => getValueOrEmpty(cell.cell.value)
  },
  {
    Header: intl.formatMessage({ id: "explore.historyReport.table.svType" }),
    accessor: "svType",
    Cell: (cell: any) => getValueOrEmpty(cell.cell.value)
  },
  {
    Header: intl.formatMessage({ id: "explore.historyReport.table.lrn" }),
    accessor: "lrn",
    Cell: (cell: any) => getValueOrEmpty(cell.cell.value)
  }
];

const getTollfreeColumns = (intl: IntlShape, theme: string) => [
  {
    Header: intl.formatMessage({ id: "explore.historyReport.table.dateTime" }),
    accessor: "activityDate",
    Cell: (cell: any) => <FormatDateTime utcDate={cell.cell.value} />
  },
  {
    Header: intl.formatMessage({ id: "explore.historyReport.table.resporg" }),
    accessor: "spId",
    Cell: (cell: any) => <SpIdCell spId={cell.cell.row.original.spId} labelColor={`${theme === "light" ? "bg-lblue" : ""}`} />
  },
  {
    Header: intl.formatMessage({ id: "explore.historyReport.table.activity" }),
    accessor: "activity",
    Cell: (cell: any) => getValueOrEmpty(cell.cell.value)
  }
];

const filterMapper = (numberActivity: NumberActivity) => {
  return {
    ...numberActivity,
    activityDate: getFormattedUtcDate(numberActivity.activityDate)
  };
};
