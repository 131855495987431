import React from "react";
import { Link } from "react-router-dom";
import { NavbarBrand, Navbar, Container, Nav, DropdownToggle, UncontrolledDropdown } from "reactstrap";
import { EnvironmentUtil } from "services/util/EnvironmentUtil";

const isMixNetworkUrl = EnvironmentUtil.isMixNetwork

export default function LoginNavbar() {
  return (
    <Navbar className="navbar-horizontal navbar-main navbar-dark navbar-transparent" expand="lg" id="navbar-main">
      <Container fluid>
        <NavbarBrand to="/" tag={Link}>
          {!isMixNetworkUrl ? 
          <img alt="PortControl" src={require("assets/img/pc_color_no_tagline.svg")} /> :
          <img alt="MixNetwork" src={require("assets/img/MIXLogo_HighRes.png")} style={{ width: "100px", height: "auto" }} />}
        </NavbarBrand>
        <Nav className="align-items-center ml-md-auto" navbar>
          <UncontrolledDropdown nav>
            <DropdownToggle className="nav-link" color="" tag="a">
              <i className="hidden" />
            </DropdownToggle>
          </UncontrolledDropdown>
        </Nav>
      </Container>
    </Navbar>
  );
}
