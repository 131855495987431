import React, { useContext } from "react";
import SpIdCell from "components/common/SpIdCell";
import { FormattedMessage } from "react-intl";
import { AppContext } from "services/appContext/AppContext";

type Props = {
  failedSpList: string[];
};

export default function FailedSpListCell(props: Props) {
  const { appContext } = useContext(AppContext);
  const theme = appContext.theme;
  
  return (
    <>
      {props.failedSpList.length > 0 ? (
        props.failedSpList.map(x => <SpIdCell key={x} spId={x} labelColor={`${theme === "light" ? "bg-lblue" : ""}`} />)
      ) : (
        <FormattedMessage id="notAvailable" />
      )}
    </>
  );
}
